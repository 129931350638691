export const industries = [
  { value: 'Agriculture', label: 'Agriculture', code: 100001 },
  { value: 'Apparel', label: 'Apparel', code: 100002 },
  { value: 'Banking', label: 'Banking', code: 100003 },
  { value: 'Biotechnology', label: 'Biotechnology', code: 100004 },
  { value: 'Chemicals', label: 'Chemicals', code: 100005 },
  { value: 'Communications', label: 'Communications', code: 100006 },
  { value: 'Construction', label: 'Construction', code: 100007 },
  { value: 'Consulting', label: 'Consulting', code: 100008 },
  { value: 'Education', label: 'Education', code: 100009 },
  { value: 'Electronics', label: 'Electronics', code: 100010 },
  { value: 'Energy', label: 'Energy', code: 100011 },
  { value: 'Engineering', label: 'Engineering', code: 100012 },
  { value: 'Entertainment', label: 'Entertainment', code: 100013 },
  { value: 'Environmental', label: 'Environmental', code: 100014 },
  { value: 'Finance', label: 'Finance', code: 100015 },
  { value: 'Food & Beverage', label: 'Food & Beverage', code: 100016 },
  { value: 'Government', label: 'Government', code: 100017 },
  { value: 'Healthcare', label: 'Healthcare', code: 100018 },
  { value: 'Hospitality', label: 'Hospitality', code: 100019 },
  { value: 'Insurance', label: 'Insurance', code: 100020 },
  { value: 'Logistics', label: 'Logistics', code: 100021 },
  { value: 'Machinery', label: 'Machinery', code: 100022 },
  { value: 'Manufacturing', label: 'Manufacturing', code: 1000023 },
  { value: 'Media', label: 'Media', code: 100024 },
  { value: 'Medical Device', label: 'Medical Device', code: 100025 },
  { value: 'Not For Profit', label: 'Not For Profit', code: 100026 },
  { value: 'Recreation', label: 'Recreation', code: 100027 },
  { value: 'Retail', label: 'Retail', code: 100028 },
  { value: 'Shipping', label: 'Shipping', code: 100029 },
  { value: 'Technology', label: 'Technology', code: 100030 },
  { value: 'Telecommunications', label: 'Telecommunications', code: 100031 },
  { value: 'Transportation', label: 'Transportation', code: 100032 },
  { value: 'Utilities', label: 'Utilities', code: 100033 },
  { value: 'Other', label: 'Other', code: 100034 },
];

export const certifications = [
  {
    value: 'Serving It Right Certificate',
    label: 'Serving It Right Certificate',
    code: 200001,
    equivalent: [],
  },
  {
    value: 'Special Event Server (SES) Certified',
    label: 'Special Event Server (SES) Certified',
    code: 200002,
    equivalent: [],
  },
  {
    value: 'FOODSAFE Level 1',
    label: 'FOODSAFE Level 1',
    code: 200003,
    equivalent: [],
  },
  {
    value: 'FOODSAFE Level 2',
    label: 'FOODSAFE Level 2',
    code: 200004,
    equivalent: [200003],
  },
  {
    value: 'Aerial Work Platform (AWP) Certificate',
    label: 'Aerial Work Platform (AWP) Certificate',
    code: 200005,
    equivalent: [],
  },
  {
    value: 'Independent School Teaching Certificate (ISTC)',
    label: 'Independent School Teaching Certificate (ISTC)',
    code: 200006,
    equivalent: [],
  },
  {
    value: 'Teaching Certificate of Qualification (COQ)',
    label: 'Teaching Certificate of Qualification (COQ)',
    code: 200007,
    equivalent: [],
  },
  {
    value: 'First Nations Language Teacher Certificate',
    label: 'First Nations Language Teacher Certificate',
    code: 200008,
    equivalent: [],
  },
  {
    value: 'Early Childhood Educator (ECE)',
    label: 'Early Childhood Educator (ECE)',
    code: 200009,
    equivalent: [],
  },
  {
    value: 'SuperHost certified',
    label: 'SuperHost certified',
    code: 200010,
    equivalent: [],
  },
  {
    value: 'Microsoft Certified Desktop Support, Technician',
    label: 'Microsoft Certified Desktop Support, Technician',
    code: 200011,
    equivalent: [],
  },
  {
    value: 'CISCO Certified Network Associate',
    label: 'CISCO Certified Network Associate',
    code: 200012,
    equivalent: [],
  },
  {
    value: 'Google Ads Display Certification',
    label: 'Google Ads Display Certification',
    code: 200013,
    equivalent: [],
  },
  {
    value: 'Google Ads Search Certification',
    label: 'Google Ads Search Certification',
    code: 200014,
    equivalent: [],
  },
  {
    value: 'Google Ads - Measurement Certification',
    label: 'Google Ads - Measurement Certification',
    code: 200015,
    equivalent: [],
  },
  {
    value: 'Google Ads Video Certification',
    label: 'Google Ads Video Certification',
    code: 200016,
    equivalent: [],
  },
  {
    value: 'Shopping ads Certification',
    label: 'Shopping ads Certification',
    code: 200017,
    equivalent: [],
  },
  {
    value: 'S-100 Basic Fire Suppression',
    label: 'S-100 Basic Fire Suppression',
    code: 200018,
    equivalent: [],
  },
  {
    value: 'Occupational First Aid (OFA) Level 1',
    label: 'Occupational First Aid (OFA) Level 1',
    code: 200019,
    equivalent: [],
  },
  {
    value: 'St. John Ambulance - Emergency First Aid for Industry',
    label: 'St. John Ambulance - Emergency First Aid for Industry',
    code: 200020,
    equivalent: [200019],
  },
  {
    value:
      'St. John Ambulance - Emergency First Aid for Industry out of jurisdiction',
    label:
      'St. John Ambulance - Emergency First Aid for Industry out of jurisdiction',
    code: 200021,
    equivalent: [200019],
  },
  {
    value: 'St. John Ambulance - Standard First Aid for Industry',
    label: 'St. John Ambulance - Standard First Aid for Industry',
    code: 200022,
    equivalent: [200019],
  },
  {
    value:
      'St. John Ambulance - Standard First Aid for Mines including Industry',
    label:
      'St. John Ambulance - Standard First Aid for Mines including Industry',
    code: 200023,
    equivalent: [200019],
  },
  {
    value:
      'St. John Ambulance - Emergency First Aid for Industry out of jurisdiction',
    label:
      'St. John Ambulance - Emergency First Aid for Industry out of jurisdiction',
    code: 200024,
    equivalent: [200019],
  },
  {
    value: 'MedAire - (OFA Level 1 Equivalent)',
    label: 'MedAire - (OFA Level 1 Equivalent)',
    code: 200025,
    equivalent: [200019],
  },
  {
    value: 'Lifesaving Society - Emergency First Aid',
    label: 'Lifesaving Society - Emergency First Aid',
    code: 200026,
    equivalent: [200019],
  },
  {
    value: 'Lifesaving Society - Emergency First Aid Child & Residential Care',
    label: 'Lifesaving Society - Emergency First Aid Child & Residential Care',
    code: 200027,
    equivalent: [200019],
  },
  {
    value: 'Lifesaving Society - Emergency First Aid Out of Jurisdiction',
    label: 'Lifesaving Society - Emergency First Aid Out of Jurisdiction',
    code: 200028,
    equivalent: [200019],
  },
  {
    value: 'Lifesaving Society - Standard First Aid',
    label: 'Lifesaving Society - Standard First Aid',
    code: 200029,
    equivalent: [200019],
  },
  {
    value: 'Lifesaving Society - Emergency First Aid CPR A / AED',
    label: 'Lifesaving Society - Emergency First Aid CPR A / AED',
    code: 200030,
    equivalent: [200019],
  },
  {
    value: 'Lifesaving Society - Emergency First Aid CPR C / AED',
    label: 'Lifesaving Society - Emergency First Aid CPR C / AED',
    code: 200031,
    equivalent: [200019],
  },
  {
    value:
      'Lifesaving Society - Emergency First Aid Child & Residential Care CPR C / AED',
    label:
      'Lifesaving Society - Emergency First Aid Child & Residential Care CPR C / AED',
    code: 200032,
    equivalent: [200019],
  },
  {
    value: 'Lifesaving Society - Out of Jurisdiction',
    label: 'Lifesaving Society - Out of Jurisdiction',
    code: 200033,
    equivalent: [200019],
  },
  {
    value: 'Lifesaving Society - Standard First Aid CPR C / AED',
    label: 'Lifesaving Society - Standard First Aid CPR C / AED',
    code: 200034,
    equivalent: [200019],
  },
  {
    value: 'Canadian Red Cross - B.C. Workplace Emergency First Aid',
    label: 'Canadian Red Cross - B.C. Workplace Emergency First Aid',
    code: 200035,
    equivalent: [200019],
  },
  {
    value:
      'Canadian Red Cross - B.C. Workplace Emergency First Aid - Out of Jurisdiction',
    label:
      'Canadian Red Cross - B.C. Workplace Emergency First Aid - Out of Jurisdiction',
    code: 200036,
    equivalent: [200019],
  },
  {
    value:
      'Canadian Red Cross - B.C. Workplace Emergency First Aid (CPR/AED level A)',
    label:
      'Canadian Red Cross - B.C. Workplace Emergency First Aid (CPR/AED level A)',
    code: 200037,
    equivalent: [200019],
  },
  {
    value: 'Canadian Red Cross - Emergency First Aid & CPR/AED Level C',
    label: 'Canadian Red Cross - Emergency First Aid & CPR/AED Level C',
    code: 200038,
    equivalent: [200019],
  },
  {
    value: 'Canadian Red Cross - Standard First Aid & CPR/AED Level C',
    label: 'Canadian Red Cross - Standard First Aid & CPR/AED Level C',
    code: 200039,
    equivalent: [200019],
  },
  {
    value: 'Canadian Red Cross - Marine Basic First Aid & CPR/AED Level A',
    label: 'Canadian Red Cross - Marine Basic First Aid & CPR/AED Level A',
    code: 200040,
    equivalent: [200019],
  },
  {
    value: 'Canadian Red Cross - Marine Basic First Aid & CPR/AED Level C',
    label: 'Canadian Red Cross - Marine Basic First Aid & CPR/AED Level C',
    code: 200041,
    equivalent: [200019],
  },
  {
    value: 'Occupational First Aid (OFA) Level 2',
    label: 'Occupational First Aid (OFA) Level 2',
    code: 200042,
    equivalent: [200019],
  },
  {
    value: 'Occupational First Aid (OFA) Level 3',
    label: 'Occupational First Aid (OFA) Level 3',
    code: 200043,
    equivalent: [200019, 200042],
  },
  {
    value: 'Babysitter First Aid',
    label: 'Babysitter First Aid',
    code: 200044,
    equivalent: [],
  },
  {
    value: 'Emergency Child Care First Aid',
    label: 'Emergency Child Care First Aid',
    code: 200045,
    equivalent: [],
  },
  { value: 'CPR-A + AED', label: 'CPR-A + AED', code: 200046, equivalent: [] },
  { value: 'CPR-C + AED', label: 'CPR-C + AED', code: 200047, equivalent: [] },
  {
    value: 'Standard First Aid plus CPR-A/C and AED',
    label: 'Standard First Aid plus CPR-A/C and AED',
    code: 200048,
    equivalent: [],
  },
  {
    value: 'Wilderness & Remote First Aid - CPR-C AED',
    label: 'Wilderness & Remote First Aid - CPR-C AED',
    code: 200049,
    equivalent: [],
  },
  {
    value: 'Advanced Wilderness & Remote First Aid - CPR-C AED',
    label: 'Advanced Wilderness & Remote First Aid - CPR-C AED',
    code: 200050,
    equivalent: [],
  },
  {
    value: 'Marine Basic First Aid (CPR-C + AED)',
    label: 'Marine Basic First Aid (CPR-C + AED)',
    code: 200051,
    equivalent: [],
  },
  {
    value: 'Marine Advanced First Aid (CPR-HCP + AED)',
    label: 'Marine Advanced First Aid (CPR-HCP + AED)',
    code: 200052,
    equivalent: [],
  },
  {
    value: 'Mine Supervisor Certification',
    label: 'Mine Supervisor Certification',
    code: 200053,
    equivalent: [],
  },
  {
    value: 'Shiftboss Certification',
    label: 'Shiftboss Certification',
    code: 200054,
    equivalent: [],
  },
  {
    value: 'Fireboss Certification (Underground Coal Mine Manager)',
    label: 'Fireboss Certification (Underground Coal Mine Manager)',
    code: 200055,
    equivalent: [],
  },
  {
    value: 'Blasting Certification / Blaster certification',
    label: 'Blasting Certification / Blaster certification',
    code: 200056,
    equivalent: [],
  },
  {
    value: 'Mine Rescue Certification',
    label: 'Mine Rescue Certification',
    code: 200057,
    equivalent: [],
  },
  {
    value: 'Confined Space Entry',
    label: 'Confined Space Entry',
    code: 200058,
    equivalent: [],
  },
  {
    value: 'Confined Space Entry and Monitor',
    label: 'Confined Space Entry and Monitor',
    code: 200059,
    equivalent: [],
  },
  {
    value: 'Confined Space Rescue',
    label: 'Confined Space Rescue',
    code: 200060,
    equivalent: [],
  },
  {
    value: 'Awareness Construction Safety Training System',
    label: 'Awareness Construction Safety Training System',
    code: 200061,
    equivalent: [],
  },
  {
    value: 'Electrical Safety Awareness',
    label: 'Electrical Safety Awareness',
    code: 200062,
    equivalent: [],
  },
  {
    value: 'Emergency Medical Responder',
    label: 'Emergency Medical Responder',
    code: 200063,
    equivalent: [],
  },
  {
    value: 'Fall Protection',
    label: 'Fall Protection',
    code: 200064,
    equivalent: [],
  },
  {
    value: 'Fire Suppression',
    label: 'Fire Suppression',
    code: 200065,
    equivalent: [],
  },
  { value: 'Flag Person', label: 'Flag Person', code: 200066, equivalent: [] },
  {
    value: 'Forklift Operator',
    label: 'Forklift Operator',
    code: 200067,
    equivalent: [],
  },
  {
    value: 'Ground Disturbance',
    label: 'Ground Disturbance',
    code: 200068,
    equivalent: [],
  },
  { value: 'H2S Alive', label: 'H2S Alive', code: 200069, equivalent: [] },
  {
    value: 'Ladder Safety Training',
    label: 'Ladder Safety Training',
    code: 200070,
    equivalent: [],
  },
  {
    value: 'Petroleum Safety Training',
    label: 'Petroleum Safety Training',
    code: 200071,
    equivalent: [],
  },
  {
    value: 'Powder-actuated Fastening',
    label: 'Powder-actuated Fastening',
    code: 200072,
    equivalent: [],
  },
  {
    value: 'Skid steer operator training',
    label: 'Skid steer operator training',
    code: 200073,
    equivalent: [],
  },
  { value: 'Spray Safe', label: 'Spray Safe', code: 200074, equivalent: [] },
  {
    value: 'Traffic Control',
    label: 'Traffic Control',
    code: 200075,
    equivalent: [],
  },
  {
    value: 'Transportation of Dangerous Goods',
    label: 'Transportation of Dangerous Goods',
    code: 200076,
    equivalent: [],
  },
  { value: 'WHMIS', label: 'WHMIS', code: 200077, equivalent: [] },
  { value: 'World Host', label: 'World Host', code: 200078, equivalent: [] },
  {
    value: 'Crane operator certification',
    label: 'Crane operator certification',
    code: 200079,
    equivalent: [],
  },
  {
    value: 'Diver certification',
    label: 'Diver certification',
    code: 200080,
    equivalent: [],
  },
  {
    value: 'Electrofishing operator certification',
    label: 'Electrofishing operator certification',
    code: 200081,
    equivalent: [],
  },
  {
    value: 'Faller certification',
    label: 'Faller certification',
    code: 200082,
    equivalent: [],
  },
  {
    value: 'Industrial audiometric technician certification',
    label: 'Industrial audiometric technician certification',
    code: 200083,
    equivalent: [],
  },
  {
    value: 'Marine craft operator certification',
    label: 'Marine craft operator certification',
    code: 200084,
    equivalent: [],
  },
  {
    value: 'Rope access worker certification',
    label: 'Rope access worker certification',
    code: 200085,
    equivalent: [],
  },
  {
    value: 'Pressure welder certificate',
    label: 'Pressure welder certificate',
    code: 200086,
    equivalent: [],
  },
  {
    value: 'Electrical FSR certificate',
    label: 'Electrical FSR certificate',
    code: 200087,
    equivalent: [],
  },
  {
    value: 'Elevating Devices Mechanic Certification - Class A',
    label: 'Elevating Devices Mechanic Certification - Class A',
    code: 200088,
    equivalent: [],
  },
  {
    value: 'Elevating Devices Mechanic Certification - Class C',
    label: 'Elevating Devices Mechanic Certification - Class C',
    code: 200089,
    equivalent: [],
  },
  {
    value: 'Elevating Devices Mechanic Certification - Class H',
    label: 'Elevating Devices Mechanic Certification - Class H',
    code: 200090,
    equivalent: [],
  },
  {
    value: 'Elevating Devices Mechanic Certification - Class MR',
    label: 'Elevating Devices Mechanic Certification - Class MR',
    code: 200091,
    equivalent: [],
  },
  {
    value: 'Class A Gas Fitter certificate',
    label: 'Class A Gas Fitter certificate',
    code: 200092,
    equivalent: [],
  },
  {
    value: 'Class B Gas Fitter certificate (120 kW)',
    label: 'Class B Gas Fitter certificate (120 kW)',
    code: 200093,
    equivalent: [],
  },
  {
    value: 'Class B Gas Fitter certificate (220 kW)',
    label: 'Class B Gas Fitter certificate (220 kW)',
    code: 200094,
    equivalent: [],
  },
  {
    value: 'Gas Appliance Service Technician certificate',
    label: 'Gas Appliance Service Technician certificate',
    code: 200095,
    equivalent: [],
  },
  {
    value: 'Gas Piping certificate',
    label: 'Gas Piping certificate',
    code: 200096,
    equivalent: [],
  },
  {
    value: 'Gas Utility certificate',
    label: 'Gas Utility certificate',
    code: 200097,
    equivalent: [],
  },
  {
    value: 'Gas Venting certificate',
    label: 'Gas Venting certificate',
    code: 200098,
    equivalent: [],
  },
  {
    value: 'RV (Recreational Vehicle) Installation and Service certificate',
    label: 'RV (Recreational Vehicle) Installation and Service certificate',
    code: 200099,
    equivalent: [],
  },
  {
    value: 'Vehicle Conversion (CNG – Compressed Natural Gas) certificate',
    label: 'Vehicle Conversion (CNG – Compressed Natural Gas) certificate',
    code: 200100,
    equivalent: [],
  },
  {
    value:
      'Vehicle Conversion (CNG/LPG – Compressed Natural Gas / Liquefied Petroleum Gas) certificate',
    label:
      'Vehicle Conversion (CNG/LPG – Compressed Natural Gas / Liquefied Petroleum Gas) certificate',
    code: 200101,
    equivalent: [],
  },
  {
    value: 'Vehicle Conversion (LPG – Liquefied Petroleum Gas) certificate',
    label: 'Vehicle Conversion (LPG – Liquefied Petroleum Gas) certificate',
    code: 200102,
    equivalent: [],
  },
  {
    value: 'Special Purpose Certificate of Qualification (SPCQ)',
    label: 'Special Purpose Certificate of Qualification (SPCQ)',
    code: 200103,
    equivalent: [],
  },
  {
    value: 'Power Engineer First Class Certificate',
    label: 'Power Engineer First Class Certificate',
    code: 200104,
    equivalent: [],
  },
  {
    value: 'Power Engineer Second Class Certificate',
    label: 'Power Engineer Second Class Certificate',
    code: 200105,
    equivalent: [],
  },
  {
    value: 'Power Engineer Third Class Certificate',
    label: 'Power Engineer Third Class Certificate',
    code: 200106,
    equivalent: [],
  },
  {
    value: 'Power Engineer Fourth Class Certificate',
    label: 'Power Engineer Fourth Class Certificate ',
    code: 200107,
    equivalent: [],
  },
  {
    value: 'Power Engineer Fifth Class Certificate',
    label: 'Power Engineer Fifth Class Certificate ',
    code: 200108,
    equivalent: [],
  },
  {
    value: 'Boiler Safety Awareness Certificate',
    label: 'Boiler Safety Awareness Certificate',
    code: 200109,
    equivalent: [],
  },
  {
    value: 'Oil Well Boiler Operator Certificate',
    label: 'Oil Well Boiler Operator Certificate',
    code: 200110,
    equivalent: [],
  },
  {
    value: 'Refrigeration Safety Awareness Certificate',
    label: 'Refrigeration Safety Awareness Certificate',
    code: 200111,
    equivalent: [],
  },
  {
    value: 'Refrigeration Operator Certificate',
    label: 'Refrigeration Operator Certificate',
    code: 200112,
    equivalent: [],
  },
  {
    value: 'Ice Facility Operator Certificate',
    label: 'Ice Facility Operator Certificate',
    code: 200113,
    equivalent: [],
  },
  {
    value: 'Master Mariner',
    label: 'Master Mariner',
    code: 200114,
    equivalent: [],
  },
  {
    value: 'Master Intermediate Voyage',
    label: 'Master Intermediate Voyage',
    code: 200115,
    equivalent: [],
  },
  {
    value: 'Master Local Voyage',
    label: 'Master Local Voyage',
    code: 200116,
    equivalent: [],
  },
  {
    value: 'First Mate Intermediate Voyage',
    label: 'First Mate Intermediate Voyage',
    code: 200117,
    equivalent: [],
  },
  {
    value: 'First Mate, Local Voyage',
    label: 'First Mate, Local Voyage',
    code: 200118,
    equivalent: [],
  },
  {
    value: 'Watchkeeping Mate, ship',
    label: 'Watchkeeping Mate, ship',
    code: 200119,
    equivalent: [],
  },
  {
    value: 'Restricted Watchkeeping Mate, ship',
    label: 'Restricted Watchkeeping Mate, ship',
    code: 200120,
    equivalent: [],
  },
  {
    value: 'Watchkeeping Mate, MODU/Surface',
    label: 'Watchkeeping Mate, MODU/Surface',
    code: 200121,
    equivalent: [],
  },
  {
    value: 'Watchkeeping Mate, MODU/Self-elevating',
    label: 'Watchkeeping Mate, MODU/Self-elevating',
    code: 200122,
    equivalent: [],
  },
  {
    value: 'Watchkeeping Mate, MODU/Inland',
    label: 'Watchkeeping Mate, MODU/Inland',
    code: 200123,
    equivalent: [],
  },
  {
    value:
      'Master, Ship of not more than 350 tons, gross tonnage, or tug, local voyage',
    label:
      'Master, Ship of not more than 350 tons, gross tonnage, or tug, local voyage',
    code: 200124,
    equivalent: [],
  },
  {
    value: 'Master, Limited',
    label: 'Master, Limited',
    code: 200125,
    equivalent: [],
  },
  {
    value: 'First Mate, Limited',
    label: 'First Mate, Limited',
    code: 200126,
    equivalent: [],
  },
  {
    value: 'Fishing Master, First Class',
    label: 'Fishing Master, First Class',
    code: 200127,
    equivalent: [],
  },
  {
    value: 'Fishing Master, Second class',
    label: 'Fishing Master, Second class',
    code: 200128,
    equivalent: [],
  },
  {
    value: 'Fishing Master, Third Class',
    label: 'Fishing Master, Third Class',
    code: 200129,
    equivalent: [],
  },
  {
    value: 'Fishing Master, Fourth Class',
    label: 'Fishing Master, Fourth Class',
    code: 200130,
    equivalent: [],
  },
  {
    value:
      'Certificate of service as master of a ship of not more than 1600 tons, gross tonnage',
    label:
      'Certificate of service as master of a ship of not more than 1600 tons, gross tonnage',
    code: 200131,
    equivalent: [],
  },
  {
    value:
      'Certificate of service as master of a fishing vessel of not more than 100 tons, gross, tonnage',
    label:
      'Certificate of service as master of a fishing vessel of not more than 100 tons, gross, tonnage',
    code: 200132,
    equivalent: [],
  },
  {
    value: 'Bridge Watchman',
    label: 'Bridge Watchman',
    code: 200133,
    equivalent: [],
  },
  {
    value: 'Proficiency in Fast Rescue Boats',
    label: 'Proficiency in Fast Rescue Boats',
    code: 200134,
    equivalent: [],
  },
  {
    value: 'Master, limited for a pleasure yacht of more than 20 m in length',
    label: 'Master, limited for a pleasure yacht of more than 20 m in length',
    code: 200135,
    equivalent: [],
  },
  {
    value: 'Master, limited for a short run ferry',
    label: 'Master, limited for a short run ferry',
    code: 200136,
    equivalent: [],
  },
  {
    value: 'Master, limited for an intermediate run, ferry',
    label: 'Master, limited for an intermediate run, ferry',
    code: 200137,
    equivalent: [],
  },
  {
    value: 'First Mate, limited for a short run ferry',
    label: 'First Mate, limited for a short run ferry',
    code: 200138,
    equivalent: [],
  },
  {
    value: 'First Mate, limited for an intermediate run ferry',
    label: 'First Mate, limited for an intermediate run ferry',
    code: 200139,
    equivalent: [],
  },
  {
    value: 'Shipyard Labourer Occupational Certificate',
    label: 'Shipyard Labourer Occupational Certificate',
    code: 200140,
    equivalent: [],
  },
  {
    value: 'Military Trade Certification',
    label: 'Military Trade Certification',
    code: 200141,
    equivalent: [],
  },
  {
    value: 'Agricultural Equipment Technician Certification',
    label: 'Agricultural Equipment Technician Certification',
    code: 200142,
    equivalent: [],
  },
  {
    value:
      'Automotive Painter (Automotive Refinishing Technician) certification',
    label:
      'Automotive Painter (Automotive Refinishing Technician) certification',
    code: 200143,
    equivalent: [],
  },
  {
    value: 'Automotive Refinishing Prep Technician certification',
    label: 'Automotive Refinishing Prep Technician certification',
    code: 200144,
    equivalent: [],
  },
  {
    value: 'Automotive Service Technician Certification',
    label: 'Automotive Service Technician Certification',
    code: 200145,
    equivalent: [],
  },
  {
    value: 'Railway Car Technician Certification',
    label: 'Railway Car Technician Certification',
    code: 200146,
    equivalent: [],
  },
  {
    value: 'Water Well Driller Certificate of Qualification',
    label: 'Water Well Driller Certificate of Qualification',
    code: 200147,
    equivalent: [],
  },
  {
    value: 'Arborist Technician certification',
    label: 'Arborist Technician certification',
    code: 200148,
    equivalent: [],
  },
  {
    value: 'Professional Cook 1',
    label: 'Professional Cook 1',
    code: 200149,
    equivalent: [],
  },
  {
    value: 'Professional Cook 2',
    label: 'Professional Cook 2',
    code: 200150,
    equivalent: [],
  },
  {
    value: 'Professional Cook 3',
    label: 'Professional Cook 3',
    code: 200151,
    equivalent: [],
  },
  {
    value: 'Residential Steep Roofer Certificate of Qualification',
    label: 'Residential Steep Roofer Certificate of Qualification',
    code: 200152,
    equivalent: [],
  },
  {
    value: 'Security Alarm Installer Certificate of Qualification',
    label: 'Security Alarm Installer Certificate of Qualification',
    code: 200153,
    equivalent: [],
  },
  {
    value: 'Locksmith Certificate of Qualification',
    label: 'Locksmith Certificate of Qualification',
    code: 200154,
    equivalent: [],
  },
  {
    value:
      'Authorization to Carry Restricted Firearms and Prohibited Handguns certificate (ATC)',
    label:
      'Authorization to Carry Restricted Firearms and Prohibited Handguns certificate (ATC)',
    code: 200155,
    equivalent: [],
  },
  {
    value: 'Advanced Security Training Certificate',
    label: 'Advanced Security Training Certificate',
    code: 200156,
    equivalent: [],
  },
  {
    value: 'Dairy Production Technician 1',
    label: 'Dairy Production Technician 1',
    code: 200157,
    equivalent: [],
  },
  {
    value: 'Dairy Production Technician 2',
    label: 'Dairy Production Technician 2',
    code: 200158,
    equivalent: [],
  },
  { value: 'Partsperson', label: 'Partsperson', code: 200159, equivalent: [] },
  {
    value: 'Partsperson 2',
    label: 'Partsperson 2',
    code: 200160,
    equivalent: [],
  },
  {
    value: 'Partsperson 3',
    label: 'Partsperson 3',
    code: 200161,
    equivalent: [],
  },
  {
    value: 'Sun Certified Java Programmer (SCJP)',
    label: 'Sun Certified Java Programmer (SCJP)',
    code: 200162,
    equivalent: [],
  },
  {
    value: 'Sage 50 Accounting',
    label: 'Sage 50 Accounting',
    code: 200163,
    equivalent: [],
  },
  {
    value: 'ACL Certified Data Analyst (ACDA Intermediate)',
    label: 'ACL Certified Data Analyst (ACDA Intermediate)',
    code: 200164,
    equivalent: [],
  },
  {
    value: 'ACL Certified Data Analyst (ACDA Advanced)',
    label: 'ACL Certified Data Analyst (ACDA Advanced)',
    code: 200165,
    equivalent: [200164],
  },
  {
    value: 'ACP Projects Specialization',
    label: 'ACP Projects Specialization',
    code: 200166,
    equivalent: [],
  },
];

export const licences = [
  {
    value: "Class 1 Driver's Licence",
    label: "Class 1 Driver's Licence",
    code: 300001,
    equivalent: [],
  },
  {
    value: "Class 2 Driver's Licence",
    label: "Class 2 Driver's Licence",
    code: 300002,
    equivalent: [],
  },
  {
    value: "Class 3 Driver's Licence",
    label: "Class 3 Driver's Licence",
    code: 300003,
    equivalent: [],
  },
  {
    value: "Class 4 Driver's Licence",
    label: "Class 4 Driver's Licence",
    code: 300004,
    equivalent: [],
  },
  {
    value: "Class 4 (restricted) Driver's Licence",
    label: "Class 4 (restricted) Driver's Licence",
    code: 300005,
    equivalent: [],
  },
  {
    value: "Class 4 (unrestricted) Driver's Licence",
    label: "Class 4 (unrestricted) Driver's Licence",
    code: 300006,
    equivalent: [],
  },
  {
    value: "Class 5 Driver's Licence",
    label: "Class 5 Driver's Licence",
    code: 300007,
    equivalent: [],
  },
  {
    value: "Class 6 Driver's Licence",
    label: "Class 6 Driver's Licence",
    code: 300008,
    equivalent: [],
  },
  {
    value: "Class 7 Driver's Licence",
    label: "Class 7 Driver's Licence",
    code: 300009,
    equivalent: [],
  },
  {
    value: "Class 8 Driver's Licence",
    label: "Class 8 Driver's Licence",
    code: 300010,
    equivalent: [],
  },
  {
    value: 'Security Worker Licence',
    label: 'Security Worker Licence',
    code: 300011,
    equivalent: [],
  },
  { value: 'Gambling Worker', label: 'Gambling Worker', code: 300012 },
  {
    value: 'Assistant Angling Guide Licence',
    label: 'Assistant Angling Guide Licence',
    code: 300013,
    equivalent: [],
  },
  {
    value: 'Angling Guide Licence',
    label: 'Angling Guide Licence ',
    code: 300014,
    equivalent: [],
  },
  {
    value: 'Guide Outfitter Licence',
    label: 'Guide Outfitter Licence',
    code: 300015,
    equivalent: [],
  },
  { value: 'Fur Trader Licence', label: 'Fur Trader Licence ', code: 300016 },
  {
    value: 'Transporter Licence (Fish & Wildlife)',
    label: 'Transporter Licence (Fish & Wildlife)',
    code: 300017,
    equivalent: [],
  },
  {
    value: 'Trapping Licence (Fish & Wildlife)',
    label: 'Trapping Licence (Fish & Wildlife)',
    code: 300018,
    equivalent: [],
  },
  {
    value: "Electrical Contractor's Licence",
    label: "Electrical Contractor's Licence",
    code: 300019,
    equivalent: [],
  },
  {
    value: "Gas Contractor's Licence",
    label: "Gas Contractor's Licence",
    code: 300020,
    equivalent: [],
  },
  {
    value: "Boiler Contractor's licence",
    label: "Boiler Contractor's licence",
    code: 300021,
    equivalent: [],
  },
  {
    value: 'Elevating Device Licence',
    label: 'Elevating Device Licence',
    code: 300022,
    equivalent: [],
  },
  {
    value: 'Amusement Ride Contractor Licence',
    label: 'Amusement Ride Contractor Licence',
    code: 300023,
    equivalent: [],
  },
  {
    value: 'Passenger Ropeway Licence',
    label: 'Passenger Ropeway Licence',
    code: 300024,
    equivalent: [],
  },
  {
    value: 'Electrical Contractor Licence',
    label: 'Electrical Contractor Licence',
    code: 300025,
    equivalent: [],
  },
  {
    value: 'Private Investigator (under supervision) License',
    label: 'Private Investigator (under supervision) License',
    code: 300026,
    equivalent: [],
  },
  {
    value: 'Emergency Medical Responder (EMR) License',
    label: 'Emergency Medical Responder (EMR) License',
    code: 300027,
    equivalent: [],
  },
  {
    value: 'Primary Care Paramedic (PCP) License',
    label: 'Primary Care Paramedic (PCP) License',
    code: 300028,
    equivalent: [],
  },
  {
    value: 'Advanced Care Paramedic (ACP) License',
    label: 'Advanced Care Paramedic (ACP) License',
    code: 300029,
    equivalent: [],
  },
  {
    value: 'Critical Care Paramedic (CCP) License',
    label: 'Critical Care Paramedic (CCP) License',
    code: 300030,
    equivalent: [],
  },
];
