/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';

const ExpBlock = ({ item }) => {
  const [open, setOpen] = useState(false);
  return (
    <div css={{ marginBottom: 10 }}>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        css={{
          border: 'none',
          cursor: 'pointer',
          width: '100%',
          outline: 'none',
          backgroundColor: '#fff',
          p: {
            color: '#002E6D',
            transition: '0.2s',
            fontWeight: open ? 'bold' : 500,
            fontSize: open ? 18 : 16,
          },
          span: {
            fontStyle: 'italic',
            color: '#393939',
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            marginBottom: 8,
          }}
        >
          <p css={{ margin: 0 }}>
            {item.industry} ({item.exp.length})
          </p>

          <span css={{ marginLeft: 8, textAlign: 'right' }}>
            ({item.years} yrs)
          </span>
        </div>
      </button>
      {open ? (
        <div>
          {item.exp.map((exp, i) => (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: '#002E6D',
                borderRadius: '4px',
                padding: '14px 14px',
                color: '#FFFFFF!important',
                marginBottom: 8,
              }}
              key={i}
            >
              <p
                css={{
                  margin: '0 16px 0 0',
                  color: '#FFFFFF',
                  fontSize: 16,
                  strong: {
                    textDecoration: 'underline',
                  },
                }}
              >
                <strong>{exp.position}</strong> at{' '}
                <strong>{exp.company}</strong> for{' '}
                <strong>
                  {exp.years > 0 && `${exp.years}yr`}{' '}
                  {exp.months > 0 && `${exp.months}mo`}
                </strong>
              </p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default ExpBlock;
