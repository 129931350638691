import React, { useContext } from 'react';
import { withFirebase } from 'library/Firebase';
import { AuthUserContext } from 'library/Session';

import AuthNav from './AuthNav';
import NonAuthNav from './NonAuthNav';

const Nav = () => {
  const { authUser } = useContext(AuthUserContext);

  return <div>{authUser ? <AuthNav /> : <NonAuthNav />}</div>;
};

export default withFirebase(Nav);
