/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useRef, useEffect } from 'react';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  editButton,
  formInput,
  button,
  goBackButton,
  buttonContainer,
  popupContainer,
  customCheck,
  formLabel,
  addButton,
  blueBox,
  settingTitle,
} from 'theme/styleBlocks';
import Select from 'react-select';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { industries } from 'constants/lists';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { BodyScrollOptions } from 'body-scroll-lock';
import { withFirebase } from 'library/Firebase/';
import { bp, mq } from 'constants/mediaQueries';
import ExpBlock from 'components/ExpBlock/ExpBlock';
// import { Ring } from 'react-spinners-css';

const EditExp = ({ experiences, prevNoExp, firebase }) => {
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [changed, setChanged] = useState(false);
  const [expList, setExpList] = useState([]);
  const modalEl = useRef(null);
  const {
    getValues,
    setValue,
    register,
    setError,
    errors,
    clearErrors,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isSubmitted },
  } = useForm();
  const { id, type } = useParams();
  const noExp = watch('noExp');
  useEffect(() => {
    if (isSubmitted && !Object.keys(errors).length) reset();
  }, [isSubmitted, reset, errors]);

  useEffect(() => {
    setExpList([...experiences]);
    setValue('noExp', prevNoExp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (expList.length !== experiences.length) {
      return setChanged(true);
    }
    if (JSON.stringify(expList) !== JSON.stringify(experiences)) {
      return setChanged(true);
    }
    setChanged(false);
    if (
      !noExp &&
      expList.length &&
      expList !== experiences &&
      noExp !== prevNoExp
    ) {
      return setChanged(true);
    }
    if (noExp) {
      return setChanged(true);
    }
    if (!noExp && !expList.length) {
      return setChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noExp, expList.length]);

  const onEdit = () => {
    setEdit(true);
    const options: BodyScrollOptions = {
      reserveScrollBarGap: true,
    };
    disableBodyScroll(modalEl.current, options);
  };

  const onClose = () => {
    setEdit(false);
    enableBodyScroll(modalEl.current);
  };
  const yearsList = () => {
    const yearsArray = [];
    Array.from(new Array(70), (v, i) => {
      if (i === 1) {
        return yearsArray.push({ value: i, label: `${i} year` });
      }
      return yearsArray.push({ value: i, label: `${i} years` });
    });
    return yearsArray;
  };
  const monthsList = () => {
    const monthsArray = [];
    Array.from(new Array(12), (v, i) => {
      if (i === 1) {
        return monthsArray.push({ value: i, label: `${i} month` });
      }
      return monthsArray.push({ value: i, label: `${i} months` });
    });
    return monthsArray;
  };
  const checkExp = () => {
    clearErrors('months', 'years');
    const months = getValues('months');
    const years = getValues('years');
    if (!months && !years) return false;
    if (!months.value && !years.value) {
      setError([
        {
          name: 'years',
          message: 'Length of time must be at least 1 month',
          type: 'cantBeZero',
        },
        {
          name: 'months',
          message: 'Length of time must be at least 1 month',
          type: 'cantBeZero',
        },
      ]);
      return 'Length of time must be at least 1 month';
    }

    if (months.value || years.value) {
      clearErrors(['months', 'years']);
      return true;
    }
  };
  const save = (data) => {
    setSaving(true);
    if (noExp) {
      expList.length = 0;
    }
    if (expList.length && !noExp) {
      setExpList([...expList]);
    }
    if (!expList.length && !noExp) {
      expList.length = 0;
    }
    firebase.saveExp(expList, type, id).then(() => {
      setSaving(false);
      setEdit(false);
      enableBodyScroll(modalEl.current);
    });
  };
  const addExp = (data) => {
    const { position, industry, years, months, company } = data;
    const exp = {
      position,
      industry,
      years: years.value || 0,
      months: months.value || 0,
      company,
    };
    if (!expList.length) {
      setExpList([exp]);
    }
    if (expList.length) {
      setExpList((prev) => [...prev, exp]);
    }
    reset({ position: '', industry: '', years: '', months: '', company: '' });
  };
  const remove = (i) => {
    const list = [...expList];
    list.splice(i, 1);
    setExpList(list);
  };
  const formatedExpList = () => {
    const data = experiences.reduce((acc, item) => {
      const array = [...acc];
      const monthsDecimal = item.months / 12;
      const years = Math.floor((item.years + monthsDecimal) * 100) / 100;
      const index = array.findIndex(
        (exp) => exp.industry === item.industry.label
      );
      if (index < 0) {
        array.push({
          industry: item.industry.label,
          years,
          exp: [item],
        });
      }
      if (index >= 0) {
        const obj = {
          industry: item.industry.label,
          years: array[index].years + years,
          exp: [...array[index].exp, item],
        };
        array[index] = obj;
      }

      return array;
      // array.push();
      // console.log(years);
    }, []);
    return data;
  };
  return (
    <div
      css={{
        backgroundColor: edit && '#F8F8F8',
        padding: 30,
        position: 'relative',
      }}
    >
      <div
        ref={modalEl}
        css={{
          display: edit ? 'flex' : 'none',
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          background: 'rgba(0,0,0,0.5)',
          top: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99,
          overflowY: 'initial!important',
        }}
      >
        <div css={popupContainer}>
          <div css={{ marginBottom: 30 }}>
            <div css={formInput}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 30,
                }}
              >
                <button
                  type="button"
                  onClick={onClose}
                  css={{
                    border: 'none',
                    lineHeight: 1,
                    fontWeight: 500,
                  }}
                >
                  <Close css={{ height: 16 }} />
                </button>
              </div>
              <label htmlFor="position">Position</label>
              <input
                name="position"
                id="position"
                type="text"
                disabled={noExp || saving}
                placeholder="Position..."
                ref={register({ required: true })}
                css={{ borderColor: errors.position && 'red !important' }}
              />
              <FormFieldErrorMessage error={errors.position} />
            </div>
            <div css={{ marginBottom: 20 }}>
              <label
                css={{
                  display: 'block',
                  marginBottom: 4,
                  color: '#393939',
                  fontWeight: '500',
                  fontSize: 14,
                  [mq(bp.small)]: {
                    fontSize: 16,
                  },
                }}
              >
                Length of Time
              </label>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridColumnGap: 20,
                }}
              >
                <Controller
                  as={Select}
                  name="years"
                  control={control}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      '&:focus': {
                        border: '1px solid transparent',
                        outline: 'none',
                      },
                    }),
                    control: (provided) => ({
                      ...provided,
                      background: 'rgba(58, 177, 202, 0.1)',
                      border: '1px solid transparent',
                      boxShadow: 'none',
                      padding: 6,
                      borderColor: errors.years && 'red !important',
                      cursor: 'pointer',
                      '&:hover': {
                        border: '1px solid #002E6D',
                      },
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      cursor: 'pointer',
                    }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    valueContainer: (provided) => ({
                      ...provided,
                      fontSize: 14,
                    }),
                  }}
                  options={yearsList()}
                  placeholder="Year(s)"
                  defaultValue=""
                  isDisabled={noExp}
                  isSearchable={false}
                  rules={{ validate: () => checkExp() }}
                />
                <Controller
                  as={Select}
                  name="months"
                  control={control}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      '&:focus': {
                        border: '1px solid transparent',
                        outline: 'none',
                      },
                    }),
                    control: (provided) => ({
                      ...provided,
                      background: 'rgba(58, 177, 202, 0.1)',
                      border: '1px solid transparent',
                      boxShadow: 'none',
                      padding: 6,
                      borderColor: errors.months && 'red !important',
                      cursor: 'pointer',
                      '&:hover': {
                        border: '1px solid #002E6D',
                      },
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      cursor: 'pointer',
                    }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    valueContainer: (provided) => ({
                      ...provided,
                      fontSize: 14,
                    }),
                  }}
                  options={monthsList()}
                  placeholder="Month(s)"
                  defaultValue=""
                  isDisabled={noExp}
                  isSearchable={false}
                  rules={{ validate: () => checkExp() }}
                />
              </div>
              <FormFieldErrorMessage error={errors.months || errors.years} />
            </div>
            <div css={formInput}>
              <label htmlFor="company">Company</label>
              <input
                name="company"
                id="company"
                type="text"
                placeholder="Company..."
                disabled={noExp}
                ref={register({ required: true })}
                css={{ borderColor: errors.company && 'red !important' }}
              />
              <FormFieldErrorMessage error={errors.company} />
            </div>
            <div>
              <label htmlFor="industry" css={formLabel}>
                Industry
              </label>
              <Controller
                as={<Select />}
                name="industry"
                control={control}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    '&:focus': {
                      border: '1px solid transparent',
                      outline: 'none',
                    },
                  }),
                  control: (provided) => ({
                    ...provided,
                    background: 'rgba(58, 177, 202, 0.1)',
                    border: '1px solid transparent',
                    boxShadow: 'none',
                    padding: 6,
                    borderColor: errors.industry && 'red !important',
                    cursor: 'pointer',
                    '&:hover': {
                      border: '1px solid #002E6D',
                    },
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: 14,
                    cursor: 'pointer',
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: 14,
                  }),
                }}
                options={industries}
                placeholder="Industry"
                defaultValue=""
                isDisabled={noExp}
                isSearchable={false}
                rules={{ required: true }}
              />
              <FormFieldErrorMessage error={errors.industry} />
            </div>
            <div css={{ marginBottom: 40, marginTop: 20 }}>
              <button
                type="button"
                onClick={handleSubmit(addExp)}
                disabled={noExp}
                css={addButton}
              >
                + Add this experience
              </button>
            </div>
            {expList && !noExp ? (
              <div css={{ marginBottom: 40 }}>
                {expList.map((data, i) => (
                  <div key={i} css={blueBox}>
                    <p
                      css={{
                        strong: {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      <strong>{data.position}</strong> at{' '}
                      <strong>{data.company}</strong> for{' '}
                      <strong>
                        {data.years} {data.years > 1 ? 'years' : 'year'}{' '}
                        {data.months} {data.months === 1 ? 'month' : 'months'}
                      </strong>
                    </p>
                    <button
                      type="button"
                      onClick={() => remove(i)}
                      css={{
                        border: 'none',
                        background: 'none',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        fontSize: 18,
                        padding: 0,
                        display: 'flex',
                      }}
                    >
                      <Close css={{ height: 16 }} />
                    </button>
                  </div>
                ))}
              </div>
            ) : null}
            <div
              css={{
                marginBottom: 24,
              }}
            >
              <label htmlFor="noExp" css={customCheck}>
                <input type="checkbox" name="noExp" id="noExp" ref={register} />
                <div>
                  <span />
                </div>
                I do not have any experiences to add
              </label>
            </div>
          </div>
          <div css={buttonContainer}>
            <button type="button" onClick={onClose} css={goBackButton}>
              Cancel
            </button>
            <button
              type="button"
              onClick={save}
              disabled={!changed}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              {saving ? 'Saving' : 'Save & Finish'}
            </button>
          </div>
        </div>
      </div>
      <div css={{ position: 'absolute', top: 30, right: 30 }}>
        <button type="button" css={editButton} onClick={onEdit}>
          Edit
        </button>
      </div>
      <div
        css={{
          gridTemplateColumns: '2fr 2fr',
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
          },
        }}
      >
        <div css={{ justifySelf: 'start' }}>
          <h2 css={settingTitle}>Experience</h2>
        </div>
        {experiences.length ? (
          <div>
            {formatedExpList().map((item, i) => (
              <ExpBlock item={item} key={i} />
            ))}
          </div>
        ) : (
          <p
            css={{
              color: '#002E6D',
              fontWeight: 500,
            }}
          >
            None
          </p>
        )}
      </div>
    </div>
  );
};

export default withFirebase(EditExp);
