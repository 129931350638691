/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import {
  formInput,
  button,
  gigBox,
  buttonContainer,
  blueBox,
  stepTitle,
} from 'theme/styleBlocks';
import { useForm, Controller } from 'react-hook-form';
// import { ReactComponent as Close } from 'assets/icons/close.svg';
// import { ReactComponent as Verified } from 'assets/icons/verified.svg';
import { bp, mq } from 'constants/mediaQueries';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'bulletedList', 'numberedList'],
};

const Step1 = (props) => {
  const [certList, setCertList] = useState([]);
  const {
    errors,
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
  } = useForm();

  useEffect(() => {
    props.setStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setCertList(props.certs);
    setValue('position', props.position);
    setValue('vacancies', props.vacancies);
    setValue('description', props.description);
    setValue('additional', props.additional);
    setValue('verifiedReq', props.verifiedReq);
    setValue('attire', props.attire || '');
    setValue('additional', props.additional || '');
  }, []);

  useEffect(() => {
    register({ name: 'description' }, { required: true });
    register({ name: 'attire' }, { required: true });
    register({ name: 'additional' });
  }, []);

  useEffect(() => {
    if (props.startDraft) {
      setCertList(props.certs);
      setValue('position', props.position);
      setValue('vacancies', props.vacancies);
      setValue('description', props.description);
      setValue('additional', props.additional);
      setValue('verifiedReq', props.verifiedReq);
      setValue('attire', props.attire || '');
      setValue('additional', props.additional || '');
    }
  }, [props.startDraft]);

  const addCert = (data) => {
    const certToAdd = {
      ...data.cert,
      required: data.certReq,
    };
    const addCertList = [...certList, certToAdd];
    const cleanCertList = addCertList.filter((item, index, array) => {
      return array.findIndex((item2) => item.code === item2.code) === index;
    });
    setCertList(cleanCertList);
  };
  const removeCert = (index) => {
    const removedList = [...certList];
    removedList.splice(index, 1);
    setCertList(removedList);
  };
  const onSubmit = (data) => {
    props.setState((prev) => ({
      ...prev,
      ...data,
      certs: certList,
      vacancies: parseInt(data.vacancies, 10),
    }));
    props.nextStep(2);
  };
  const onVacancyChange = (value) => {
    const regex = RegExp('^[0-9]*$');
    const test = regex.test(value);
    if (!value) return false;
    if (test) return false;
    if (!test && value) return true;
    return false;
  };
  const onModeChange = (e) => {
    e.persist();
    props.setState((prev) => ({ ...prev, mode: e.target.value }));
  };
  return (
    <div
      css={(theme) => ({
        padding: '20px 15px',
        margin: '0px auto 65px',
        [mq(bp.small)]: {
          width: '90vw',
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: '60px 20px 60px 60px',
          margin: 0,
          width: '100%',
        },
      })}
    >
      <p
        css={{
          fontWeight: 500,
          fontSize: 16,
          color: '#8A8A8A',
          marginBottom: 0,
          [mq(bp.large)]: {
            display: 'none',
          },
        }}
      >
        STEP 1 OF 5
      </p>
      <h2 css={stepTitle}>Overview</h2>
      <div css={[gigBox, { marginBottom: 24 }]}>
        <h2>Gig Mode</h2>
        <p>Please choose how you want workers to interact with this gig.</p>
        <ul>
          <li>
            Claim mode: Workers will be able to claim the gig on a first come
            first serve basis.
          </li>
          <li>
            Apply mode: Workers will apply for the gig. You will then choose
            from the list of applicants.
          </li>
        </ul>
        <div>
          <div>
            <input
              type="radio"
              name="mode"
              id="claim"
              value="claim"
              css={{ marginRight: 8 }}
              onChange={onModeChange}
              checked={props.mode === 'claim'}
            />
            <label htmlFor="claim">Claim Mode</label>
          </div>
          <div>
            <input
              type="radio"
              name="mode"
              id="apply"
              value="apply"
              css={{ marginRight: 8 }}
              onChange={onModeChange}
              checked={props.mode === 'apply'}
            />
            <label htmlFor="apply">Apply Mode</label>
          </div>
        </div>
      </div>
      <div css={gigBox}>
        <div>
          <h2>Gig Description</h2>
          <p>
            Please indicate the position title, the number of vacancies per gig,
            as well as a description (responsibilities, expectations, and
            requirements) of the job that workers will be expected to perform.
          </p>
          <div
            css={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridColumnGap: 40,
              marginBottom: 40,
            }}
          >
            <div css={[formInput, { gridColumn: '1/3' }]}>
              <label htmlFor="title">Position Title</label>
              <input
                type="text"
                id="position"
                name="position"
                placeholder="Enter gig position/title"
                ref={register({ required: true })}
                css={{ borderColor: errors.position && 'red !important' }}
              />
            </div>
            <div css={[formInput, { gridColumn: '3/4' }]}>
              <label htmlFor="vacancies">Vacancies</label>
              <Controller
                render={({ onChange, value }) => (
                  <input
                    type="number"
                    onChange={(e) => {
                      if (onVacancyChange(e.target.value)) return;
                      onChange(e);
                    }}
                    value={value}
                    css={{ borderColor: errors.vacancies && 'red !important' }}
                  />
                )}
                control={control}
                name="vacancies"
                defaultValue=""
                rules={{
                  required: true,
                }}
              />
            </div>
            <div css={[formInput, { gridColumn: '1/4' }]}>
              <label htmlFor="description">Job Description</label>
              <div
                css={{
                  '.ck-content': {
                    width: '100%',
                    padding: 13,
                    display: 'block',
                    border: '1px solid transparent',
                    borderRadius: 5,
                    backgroundColor: 'rgba(58,177,202,0.1)',
                    borderColor: errors.description
                      ? 'red'
                      : 'rgba(58,177,202,0.1)',
                    fontSize: 16,
                    outline: 'none',
                    minHeight: 100,
                  },
                  '.ck-content p': {
                    margin: '0 !important',
                  },
                  '.ck-content ul, .ck-content ol': {
                    paddingLeft: 24,
                    margin: 0,
                  },
                  '.ck-content .ck-link_selected': {
                    background: 'skyblue',
                  },
                  '.ck-content a': {
                    color: 'cornflowerblue',
                  },
                }}
              >
                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: editorConfiguration.toolbar,
                    placeholder: `You will be working with other members in order to ensure the kitchen is as clean as reasonably possible at all times when not in use.`,
                  }}
                  data={props.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('description', data);
                  }}
                  onInit={() => {
                    setValue('description', props.description);
                  }}
                />
              </div>
            </div>
            <div
              css={[
                formInput,
                {
                  gridColumn: '1/4',
                },
              ]}
            >
              <label htmlFor="attire">Attire</label>
              <div
                css={{
                  '.ck-content': {
                    width: '100%',
                    padding: 13,
                    display: 'block',
                    border: '1px solid transparent',
                    borderRadius: 5,
                    backgroundColor: 'rgba(58,177,202,0.1)',
                    borderColor: errors.attire ? 'red' : 'rgba(58,177,202,0.1)',
                    fontSize: 16,
                    outline: 'none',
                    minHeight: 100,
                  },
                  '.ck-content p': {
                    margin: '0 !important',
                  },
                  '.ck-content ul, .ck-content ol': {
                    paddingLeft: 24,
                    margin: 0,
                  },
                  '.ck-content .ck-link_selected': {
                    background: 'skyblue',
                  },
                  '.ck-content a': {
                    color: 'cornflowerblue',
                  },
                }}
              >
                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: editorConfiguration.toolbar,
                    placeholder: 'Black pants',
                  }}
                  data={props.attire && props.attire}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('attire', data);
                  }}
                />
              </div>
            </div>
            <div css={[formInput, { gridColumn: '1/4' }]}>
              <label htmlFor="additional">Additional lnfo (Optional)</label>
              <div
                css={{
                  '.ck-content': {
                    width: '100%',
                    padding: 13,
                    display: 'block',
                    border: '1px solid transparent',
                    borderRadius: 5,
                    backgroundColor: 'rgba(58,177,202,0.1)',
                    borderColor: 'rgba(58,177,202,0.1)',
                    fontSize: 16,
                    outline: 'none',
                    minHeight: 100,
                  },
                  '.ck-content p': {
                    margin: '0 !important',
                  },
                  '.ck-content ul, .ck-content ol': {
                    paddingLeft: 24,
                    margin: 0,
                  },
                  '.ck-content .ck-link_selected': {
                    background: 'skyblue',
                  },
                  '.ck-content a': {
                    color: 'cornflowerblue',
                  },
                }}
              >
                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: editorConfiguration.toolbar,
                    placeholder: `Please use rear entrance and report to point of contact`,
                  }}
                  data={props.additional && props.additional}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue('additional', data);
                  }}
                />
              </div>
            </div>
          </div>
          <div css={buttonContainer}>
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
