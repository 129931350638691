/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import useScript from 'hooks/useScript';
import { withFirebase } from 'library/Firebase';
import { bp, mq } from 'constants/mediaQueries';
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import qs from 'query-string';
// import moment from 'moment';
import { formInput, button } from 'theme/styleBlocks';
import { googleApi } from 'constants/config';
import ChooseWorker from './ChooseWorker';

const Claim = ({ firebase }) => {
  const [searchBy, setSearchBy] = useState('businessName');
  const [search, setSearch] = useState('');
  const [gigs, setGigs] = useState([]);
  const [gigID, setGigID] = useState('');
  const [gigData, setGigData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [noneFound, setNoneFound] = useState(false);
  const location = useLocation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const parsed = qs.parse(location.search);

  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${googleApi()}&libraries=places`
  );

  useEffect(() => {
    if (
      parsed.search &&
      (parsed.searchBy === 'businessName' ||
        parsed.searchBy === 'gigID' ||
        parsed.searchBy === 'businessEmail' ||
        parsed.searchBy === 'position')
    ) {
      firebase.searchGig(parsed.searchBy, parsed.search).then((data) => {
        setGigs(data);
        setSearching(false);
        if (data.length <= 0) {
          setNoneFound(true);
        }
      });
      // .then((data) => {
      //   return data.reduce((acc, gig) => {
      //     const array = (acc[gig.formattedDate] =
      //       acc[gig.formattedDate] || []);
      //     array.push(gig);
      //     array.sort((a, b) => {
      //       return (
      //         moment(`${a.day} ${a.start}`) - moment(`${b.day} ${b.start}`)
      //       );
      //     });
      //     return acc;
      //   }, {});
      // })
    }
  }, [firebase, parsed.search, parsed.searchBy]);

  useEffect(() => {
    if (
      parsed.searchBy === 'businessName' ||
      parsed.searchBy === 'gigID' ||
      parsed.searchBy === 'businessEmail' ||
      parsed.searchBy === 'position'
    ) {
      setSearch(parsed.search || '');
      setSearchBy(parsed.searchBy);
    }
  }, [parsed.search, parsed.searchBy]);
  const searchGig = () => {
    setSearching(true);
    setNoneFound(false);
    history.push(`${path}?searchBy=${searchBy}&search=${search}`);
    if (searchBy === 'gigID') {
      setGigID(search);
    }
  };
  const selectGig = (id, data) => {
    setGigID(id);
    setGigData(data);
    history.push(`${path}/${id}`);
  };

  const cancelGig = (gigID) => {
    firebase
      .cancelGig(gigID)
      .then(() => {
        const modGigs = [...gigs];
        const foundIndex = modGigs.findIndex((gig) => gig.gigID === gigID);
        modGigs[foundIndex].cancelled = true;
        setGigs(modGigs);
      })
      .catch((error) => alert(error));
  };

  return (
    <Switch>
      <Route exact path={path}>
        <div
          css={{
            margin: '0 auto',
            padding: '0 20px',
            marginTop: 50,
            [mq(bp.large)]: {
              padding: 0,
              marginTop: 90,
              maxWidth: 1020,
            },
          }}
        >
          <h3>Search for Gig By</h3>
          <div
            css={{
              display: 'flex',
              marginBottom: 20,
              '&>div': { marginRight: 20 },
            }}
          >
            <div>
              <label htmlFor="searchByBusinessName">Business Name</label>
              <input
                type="radio"
                name="searchBy"
                id="searchByBusinessName"
                value="businessName"
                checked={searchBy === 'businessName'}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div>
            {/* <div>
              <label htmlFor="searchByEmail">Business Email</label>
              <input
                type="radio"
                name="searchBy"
                id="searchByEmail"
                value="businessEmail"
                checked={searchBy === 'businessEmail'}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div> */}
            {/* <div>
              <label htmlFor="searchByPosition">Position</label>
              <input
                type="radio"
                name="searchBy"
                id="searchByPosition"
                value="position"
                checked={searchBy === 'position'}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div> */}
            <div>
              <label htmlFor="searchByGigID">Gig ID</label>
              <input
                type="radio"
                name="searchBy"
                id="searchByGigID"
                value="gigID"
                checked={searchBy === 'gigID'}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div>
          </div>
          <div
            css={{
              marginTop: 50,
              [mq(bp.large)]: {
                display: 'grid',
                gridTemplateColumns: '2fr 1fr',
                columnGap: '40px',
                alignItems: 'center',
              },
            }}
          >
            <div css={[formInput, { marginBottom: '0' }]}>
              <input
                type="text"
                name="search"
                id="search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
            <button
              type="button"
              onClick={searchGig}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              Search
            </button>
          </div>
          {searching ? <p>Searching...</p> : null}
          {!searching && noneFound ? <p>No Gigs Found</p> : null}
          {!searching && !noneFound && gigs.length > 0 ? (
            <div>
              {gigs.map((gig, i) => {
                return (
                  <div
                    key={i}
                    css={{
                      marginTop: 50,
                      [mq(bp.large)]: {
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr',
                        columnGap: '40px',
                        alignItems: 'end',
                      },
                    }}
                  >
                    <div>
                      <p>Business Name: {gig.businessName}</p>
                      <p>Business Email: {gig.businessEmail}</p>
                      <p>Position: {gig.position}</p>
                      <p>Date: {gig.day}</p>
                      {parsed.searchBy === 'gigID' ? (
                        <p>Gig ID: {gigID}</p>
                      ) : (
                        <p>Gig ID: {gig.gigID}</p>
                      )}
                    </div>
                    {parsed.searchBy === 'gigID' ? (
                      <div
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => cancelGig(gig.gigID || gigID)}
                          css={(theme) => [
                            button(
                              theme.buttons.type_light,
                              theme.colors.util3,
                              theme.colors.util1
                            ),
                            {
                              marginBottom: 16,
                            },
                          ]}
                          disabled={gig.cancelled}
                        >
                          {gig.cancelled ? 'Cancelled' : 'Cancel Gig'}
                        </button>
                        <button
                          type="button"
                          onClick={() => selectGig(gigID, gig)}
                          css={(theme) =>
                            button(
                              theme.buttons.type_dark,
                              theme.buttons.bg2,
                              theme.buttons.hover2
                            )
                          }
                          disabled={gig.cancelled}
                        >
                          Select Gig
                        </button>
                      </div>
                    ) : (
                      <div
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => cancelGig(gig.gigID || gigID)}
                          css={(theme) => [
                            button(
                              theme.buttons.type_light,
                              theme.colors.util3,
                              theme.colors.util1
                            ),
                            {
                              marginBottom: 16,
                            },
                          ]}
                          disabled={gig.cancelled}
                        >
                          {gig.cancelled ? 'Cancelled' : 'Cancel Gig'}
                        </button>
                        <button
                          type="button"
                          onClick={() => selectGig(gig.gigID, gig)}
                          css={(theme) =>
                            button(
                              theme.buttons.type_dark,
                              theme.buttons.bg2,
                              theme.buttons.hover2
                            )
                          }
                          disabled={gig.cancelled}
                        >
                          Select Gig
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </Route>
      <Route
        path={`${path}/:gigID`}
        render={() => <ChooseWorker gigID={gigID} gigData={gigData} />}
      />
    </Switch>
  );
};

export default withFirebase(Claim);
