/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { withFirebase } from 'library/Firebase';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { bp, mq } from 'constants/mediaQueries';
import PayoutItem from './PayoutItem';
import Archived from './Archived';

const Payouts = ({ firebase }) => {
  const [loading, setLoading] = useState(true);
  const [gigs, setGigs] = useState([]);

  const { path } = useRouteMatch();

  useEffect(() => {
    setLoading(true);
    const unsub = firebase.getPayoutList().onSnapshot((querySnapshot) => {
      const sortedGigs = [];
      querySnapshot.forEach((doc) => {
        sortedGigs.push(doc.data());
      });
      setGigs(
        sortedGigs.sort((a, b) => {
          return a.endTimeStamp.seconds - b.endTimeStamp.seconds;
        })
      );
      setLoading(false);
    });
    return () => {
      unsub();
    };
  }, [firebase]);

  return (
    <Switch>
      <Route exact path={path}>
        <div
          css={{
            margin: '0 auto',
            padding: '0 20px',
            marginTop: 50,
            [mq(bp.large)]: {
              padding: 0,
              marginTop: 90,
              maxWidth: 1020,
            },
          }}
        >
          <Link to={`${path}/archived`}>Archived</Link>
          {!loading && gigs.length > 0 ? (
            <div>
              {gigs.map((gig) => (
                <PayoutItem
                  gigData={gig}
                  archived={gig.adminArchived}
                  invoicing={gig.invoicingOption}
                />
              ))}
            </div>
          ) : null}
          {loading && <p>Loading...</p>}
          {gigs.length === 0 && !loading && (
            <p>All workers have been paid :)</p>
          )}
        </div>
      </Route>
      <Route path={`${path}/archived`}>
        <Archived />
      </Route>
    </Switch>
  );
};

export default withFirebase(Payouts);
