/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { withFirebase } from 'library/Firebase';
import { Link } from 'react-router-dom';
import PayoutItem from './PayoutItem';

const Archived = ({ firebase }) => {
  const [loading, setLoading] = useState(true);
  const [gigs, setGigs] = useState([]);

  useEffect(() => {
    setLoading(true);
    const unsub = firebase
      .getArchivedPayoutList()
      .onSnapshot((querySnapshot) => {
        const sortedGigs = [];
        querySnapshot.forEach((doc) => {
          sortedGigs.push(doc.data());
        });
        setGigs(
          sortedGigs.sort((a, b) => {
            return b.endTimeStamp.seconds - a.endTimeStamp.seconds;
          })
        );
        setLoading(false);
      });
    return () => {
      unsub();
    };
  }, [firebase]);
  return (
    <div>
      <Link to="/payouts">Go to worker payouts</Link>
      {!loading && gigs.length > 0 ? (
        <div>
          {gigs.map((gig) => (
            <PayoutItem gigData={gig} archived={gig.adminArchived} />
          ))}
        </div>
      ) : null}
      {loading && <p>Loading...</p>}
      {gigs.length === 0 && !loading && <p>Nothing Archived</p>}
    </div>
  );
};

export default withFirebase(Archived);
