/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { theme } from 'theme/globalTheme';
import { withFirebase } from 'library/Firebase';
import { Link } from 'react-router-dom';
import { bp, mq } from 'constants/mediaQueries';
import { navButton } from 'theme/styleBlocks';

const AuthNav = ({ firebase }) => {
  const logout = () => {
    firebase.logout();
  };
  return (
    <ThemeProvider theme={theme}>
      <div
        css={(theme) => ({
          padding: '13px 20px',
          backgroundColor: '#002E6D',
          width: '100%',
          zIndex: 99,
          [mq(bp.large)]: {
            padding: '10px 20px',
            backgroundColor: '#F3F3F3',
            position: 'unset',
          },
        })}
      >
        <ul
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 auto',
            padding: '0 20px',
            listStyle: 'none',
            [mq(bp.large)]: {
              padding: 0,
              maxWidth: 1280,
            },
            '&>li': {
              width: '100%',
              cursor: 'pointer',
              transition: '0',
              display: 'flex',
            },
            '&>li:hover a': {
              opacity: '.7!important',
            },
            'li a': {
              margin: 'auto',
              alignItems: 'center',
              padding: '12px 25px',
              display: 'block',
              opacity: '1!important',
              transition: '0!important',
              color: '#002E6D',
              textDecoration: 'none',
            },
          }}
        >
          <li
            css={{
              maxWidth: 100,
              width: 80,
              [mq(bp.large)]: {
                width: 100,
              },
            }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/wb-platform-prod.appspot.com/o/assets%2Fwb-logo.svg?alt=media&token=340a89f5-cce5-4778-9d0b-9e59daa354d7"
              alt=""
              css={{
                width: '100%',
              }}
            />
          </li>
          {/* <Link to="/invoice">Invoice</Link> */}
          <li>
            <Link to="/add-admin">Add Admin</Link>
          </li>
          <li>
            <Link to="/user-search">User Search</Link>
          </li>
          <li>
            <Link to="/payouts">Payouts</Link>
          </li>
          <li>
            <Link to="/mods">Mods</Link>
          </li>
          <li>
            <Link to="/create-gig">Create a Gig</Link>
          </li>
          <li>
            <Link to="/claim">Gig Search + CLaim</Link>
          </li>
          <li>
            <Link to="/checkid">Check ID</Link>
          </li>
          <button
            type="button"
            onClick={logout}
            css={(theme) =>
              navButton(
                theme.buttons.type_light,
                theme.buttons.bg1,
                theme.buttons.hover1
              )
            }
          >
            Logout
          </button>
        </ul>
      </div>
    </ThemeProvider>
  );
};

export default withFirebase(AuthNav);
