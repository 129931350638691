/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cropperjs from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import ExifReader from 'exifreader';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { BodyScrollOptions } from 'body-scroll-lock';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Rotate } from 'assets/icons/rotate.svg';
import {
  editButton,
  uploadButton,
  settingTitle,
  completeText,
} from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import { withFirebase } from 'library/Firebase';

// import { Ring } from 'react-spinners-css';

const EditImg = ({ profileImage, original, firebase }) => {
  const [image, setImage] = useState(null);
  const [originalBlob, setOriginalBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [range, setRange] = useState(0.5);
  const [saving, setSaving] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [newImage, setNewImage] = useState(false);

  const imageRef = useRef(null);
  const cropperRef = useRef(null);
  const modalEl = useRef(null);
  const { id, type } = useParams();
  useEffect(() => {
    cropperRef.current = new Cropperjs(imageRef.current, {
      aspectRatio: 1 / 1,
      viewMode: 0,
      background: false,
      dragMode: 'move',
      rotatable: true,
      scalable: true,
      cropBoxResizable: true,
      cropBoxMovable: true,
      guides: false,
      checkOrientation: true,
      ready() {
        const container = cropperRef.current.getContainerData();
        cropperRef.current.setCropBoxData({
          width: 150,
          height: 150,
          left: container.width / 2 - 75,
          top: container.height / 2 - 75,
        });
      },
    });
    return () => {
      // if there is an image ref, destroy cropper
      if (cropperRef.current) {
        cropperRef.current.destroy();
      }
    };
  }, []);
  useEffect(() => {
    cropperRef.current.reset().clear().replace(image);
  }, [image]);
  const onImageUpload = (event) => {
    event.persist();
    // setFileField(event.target.value);
    setNewImage(true);
    setLoading(true);
    if (event.target.files.length) {
      if (event.target.files[0].type.match('image.*')) {
        const { files } = event.target;
        const reader = new FileReader();
        let orientation;

        reader.onload = (readerEvent) => {
          const tags = ExifReader.load(readerEvent.target.result);
          if (tags.Orientation) {
            orientation = tags.Orientation.value;
          }
          if (!tags.Orientation) {
            orientation = 1;
          }
          const { type } = event.target.files[0];
          const imageEl = new Image();

          imageEl.src = URL.createObjectURL(event.target.files[0]);
          imageEl.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const maxSize = 350;
            let { width } = imageEl;
            let { height } = imageEl;
            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;
            // ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // switch (orientation) {
            //   case 2:
            //     // horizontal flip
            //     ctx.translate(canvas.width, 0);
            //     ctx.scale(-1, 1);
            //     break;
            //   case 3:
            //     // 180° rotate left
            //     ctx.translate(canvas.width, canvas.height);
            //     ctx.rotate((Math.PI / 180) * -180);
            //     break;
            //   case 4:
            //     // vertical flip
            //     ctx.translate(0, canvas.height);
            //     ctx.scale(1, -1);
            //     break;
            //   case 5:
            //     // vertical flip + 90 rotate right
            //     canvas.width = height;
            //     canvas.height = width;
            //     ctx.rotate((Math.PI / 180) * 90);
            //     ctx.scale(1, -1);
            //     break;
            //   case 6:
            //     // 90° rotate right
            //     canvas.width = height;
            //     canvas.height = width;
            //     ctx.rotate((Math.PI / 180) * 90);
            //     ctx.translate(0, -canvas.width);
            //     break;
            //   case 7:
            //     // horizontal flip + 90 rotate right
            //     canvas.width = height;
            //     canvas.height = width;
            //     ctx.rotate((Math.PI / 180) * 90);
            //     ctx.scale(-1, 1);
            //     ctx.translate(-canvas.height, -canvas.width);
            //     break;
            //   case 8:
            //     // 90° rotate left
            //     canvas.width = height;
            //     canvas.height = width;
            //     ctx.rotate((Math.PI / 180) * -90);
            //     ctx.translate(-canvas.height, 0);
            //     break;
            //   default:
            // }

            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(imageEl, 0, 0, width, height);

            canvas.toBlob((data) => {
              setOriginalBlob(data);
              setImage(URL.createObjectURL(data));
              setLoading(false);
              setModal(true);
              setUpdated(false);
            }, type);
          };
        };
        reader.readAsArrayBuffer(files[0]);
      } else {
        cropperRef.current.clear();
        setImage(null);
        setOriginalBlob(null);
        setLoading(false);
      }
    }
    if (!event.target.files.length) {
      cropperRef.current.clear();
      setImage(null);
      setOriginalBlob(null);
      setLoading(false);
    }
  };
  const onSlide = (e) => {
    cropperRef.current.zoomTo(1 * e.target.value);
    setRange(e.target.value);
  };
  const onRotate = () => {
    cropperRef.current.rotate(90);
  };
  const closeModal = () => {
    setModal(false);
    enableBodyScroll(modalEl.current);
    setRange(2);
    // setFileField('');
    setImage(null);
  };
  const openModal = () => {
    cropperRef.current.replace(profileImage);
    const options: BodyScrollOptions = {
      reserveScrollBarGap: true,
    };
    setModal(true);
    setNewImage(false);
    setUpdated(false);
    disableBodyScroll(modalEl.current, options);
  };
  const onSave = () => {
    enableBodyScroll(modalEl.current);
    const croppedCanvas = cropperRef.current.getCroppedCanvas({
      width: 180,
      height: 180,
      imageSmoothingQuality: 'high',
      fillColor: '#fff',
    });
    croppedCanvas.toBlob((croppedBlob) => {
      if (newImage) {
        return firebase
          .saveProfileImage(originalBlob, croppedBlob, type, id)
          .then(() => {
            setModal(false);
            setSaving(false);
          });
      }
      return firebase.saveProfileImage(null, croppedBlob, type, id).then(() => {
        setTimeout(
          () => [setModal(false), setImage(null), setSaving(false)],
          1500
        );
      });
    }, 'image/jpeg');
    setUpdated(true);
  };
  return (
    <div
      css={{
        gridTemplateColumns: '1fr 1fr 1fr',
        padding: 30,
        position: 'relative',
        [mq(bp.large)]: {
          display: 'grid',
          gridTemplateColumns: '1fr 2fr',
        },
      }}
    >
      <div
        css={{
          display: modal ? 'flex' : 'none',
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          background: 'rgba(0,0,0,.5)',
          top: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99,
        }}
        ref={modalEl}
      >
        <div
          css={{
            padding: '8px 14px 20px',
            background: '#fff',
            borderRadius: 3,
            width: '88vw',
            [mq(bp.small)]: {
              width: 'auto',
            },
          }}
        >
          <div css={{ textAlign: 'right' }}>
            <button
              type="button"
              css={{
                border: 'none',
                background: 'none',
                fontWeight: 'bold',
                fontSize: 18,
                padding: 0,
                path: {
                  fill: '#002E6D',
                },
              }}
              onClick={closeModal}
            >
              <Close css={{ height: 16 }} />
            </button>
          </div>
          <div
            css={{
              display: updated ? 'block' : 'none',
              textAlign: 'center',
            }}
          >
            <GreenCheck />
            <p
              css={[
                completeText,
                {
                  display: updated ? 'block' : 'none',
                  padding: '0 20px',
                },
              ]}
            >
              Profile picture updated!
            </p>
          </div>
          <div css={{ padding: '0 20px', display: updated ? 'none' : 'block' }}>
            <p
              css={{
                margin: ' 0 0 16px 0',
                fontWeight: 500,
                color: '#393939',
              }}
            >
              Upload Image
            </p>
            <div
              css={{
                width: '100%',
                maxWidth: 200,
                height: 200,
                margin: '0 auto 8px',
                textAlign: 'center',
                display: 'flex',
                [mq(bp.large)]: {
                  width: 400,
                  maxWidth: 400,
                  height: 400,
                },
                '.cropper-view-box, .cropper-face': {
                  outline: 'none',
                  borderRadius: '50%',
                },
                '.cropper-container': {
                  display: 'block',
                },
                '.cropper-modal': {
                  borderRadius: 5,
                },
              }}
            >
              <img
                id="image"
                src={original}
                alt=""
                ref={imageRef}
                css={{
                  maxWidth: '100%',
                  height: 150,
                  width: 150,
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  objectPosition: 'center',
                  objectFit: 'cover',
                  border: '2px solid lightgray',
                  margin: 'auto',
                }}
              />
            </div>
            <div
              css={{
                marginBottom: 20,
                display: newImage ? 'flex' : 'none',
                justifyContent: 'space-between',
                padding: '0 25px',
              }}
            >
              <div css={{ width: '60%' }}>
                <label htmlFor="radius">Zoom</label>
                <div css={{ width: '100%' }}>
                  <input
                    type="range"
                    min={0.5}
                    max={4}
                    value={range}
                    onChange={onSlide}
                    step={0.05}
                    css={{
                      WebkitAppearance: 'none',
                      width: '100%',
                      height: 8,
                      background: '#d3d3d3',
                      outline: 'none',
                      margin: '16px 0',
                      borderRadius: '10px',
                      '&::-webkit-slider-thumb': {
                        appearance: 'none',
                        width: 20,
                        height: 20,
                        background: '#F9D753',
                        cursor: 'pointer',
                        borderRadius: '50%',
                      },
                      '&::-moz-range-thumb': {
                        width: 20,
                        height: 20,
                        background: '#F9D753',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        border: 'none',
                      },
                    }}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="">Rotate</label>
                <button
                  type="button"
                  onClick={onRotate}
                  css={{
                    border: 'none',
                    background: 'none',
                    fontWeight: 'bold',
                    fontSize: 18,
                    padding: 0,
                    display: 'flex',
                    margin: '7px auto 0',
                  }}
                >
                  <Rotate
                    css={{
                      height: 20,
                      path: { fill: '#002E6D' },
                    }}
                  />
                </button>
              </div>
            </div>
            <div
              css={{
                position: 'relative',
                overflow: 'hidden',
                display: 'inline-block',
                width: '100%',
                'input:hover ~ button': {
                  backgroundColor: '#1A4887',
                },
              }}
            >
              <input
                type="file"
                name="image"
                id=""
                // onClick={clearFileField}
                onChange={(e) => onImageUpload(e)}
                accept="image/*"
                disabled={saving}
                css={{
                  height: '100%',
                  width: '100%',
                  fontSize: '100px',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  opacity: 0,
                }}
              />
              <button
                type="button"
                css={(theme) => [
                  uploadButton(
                    theme.buttons.type_light,
                    theme.buttons.bg1,
                    theme.buttons.hover1
                  ),
                  { width: '100%', marginBottom: 8, display: 'block' },
                ]}
                disabled={saving}
              >
                Change Photo
              </button>
            </div>
            <div>
              <button
                type="button"
                css={(theme) => [
                  uploadButton(
                    theme.buttons.type_dark,
                    theme.buttons.bg2,
                    theme.buttons.hover2
                  ),
                  { width: '100%', display: 'block' },
                ]}
                onClick={onSave}
                disabled={saving}
              >
                {saving
                  ? 'Saving'
                  : // <Ring
                    //   color="#002E6D"
                    //   size="22"
                    //   css={{
                    //     width: 22,
                    //     height: 22,
                    //     display: 'flex',
                    //     margin: 'auto',
                    //   }}
                    // />
                    'Save Photo'}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div css={{ justifySelf: 'start' }}>
        <h2 css={settingTitle}>Profile Image</h2>
      </div>
      <div
        css={{
          marginTop: 10,
          [mq(bp.small)]: { margin: '10px 0px 0px 40px' },
        }}
      >
        <div
          css={{
            width: 150,
            height: 150,
            borderRadius: '50%',
            overflow: 'hidden',
            border: '1px solid lightgrey',
            background: '#ECF0F4',
            margin: 'auto',
            [mq(bp.large)]: {
              margin: 0,
            },
          }}
        >
          <img
            src={profileImage || ''}
            alt=""
            css={{
              objectFit: 'contain',
              height: '100%',
              width: '100%',
            }}
          />
        </div>
      </div>
      <div css={{ position: 'absolute', top: 30, right: 30 }}>
        <button type="button" css={editButton} onClick={openModal}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default withFirebase(EditImg);
