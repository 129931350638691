/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect, useContext } from 'react';
import {
  useParams,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { withFirebase } from 'library/Firebase';
import { bp, mq } from 'constants/mediaQueries';
import { AuthUserContext } from 'library/Session';

import qs from 'query-string';
import { formInput, button } from 'theme/styleBlocks';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import GigItem from './GigItem';

const ChooseWorker = (props) => {
  const { refetch } = useContext(AuthUserContext);
  const { gigID } = useParams();
  const [searchBy, setSearchBy] = useState('name');
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [gigsClaimed, setGigsClaimed] = useState([]);
  const [gigData, setGigData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [searching, setSearching] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [noneFound, setNoneFound] = useState(false);
  const [type, setType] = useState('workers');
  const location = useLocation();
  const { path } = useRouteMatch();
  const history = useHistory();

  const parsed = qs.parse(location.search);
  useEffect(() => {
    if (
      parsed.search &&
      (parsed.searchBy === 'name' ||
        parsed.searchBy === 'id' ||
        parsed.searchBy === 'email')
    ) {
      props.firebase
        .searchUser(type, parsed.searchBy, parsed.search)
        .then((data) => {
          setUsers(data);
          setSearching(false);
          if (data.length <= 0) {
            setNoneFound(true);
          }
        });
    }
  }, [props.firebase, parsed.search, parsed.searchBy]);

  useEffect(() => {
    if (props.firebase) {
      props.firebase.getGigData(gigID).onSnapshot((snapshot) => {
        const data = snapshot.data();
        setGigData(data);
      });
    }
  }, []);

  useEffect(() => {
    if (
      parsed.searchBy === 'name' ||
      parsed.searchBy === 'id' ||
      parsed.searchBy === 'email'
    ) {
      setSearchBy(parsed.searchBy);
    }
    setSearch(parsed.search || '');
  }, [parsed.search, parsed.searchBy]);

  const searchUser = () => {
    setSearching(true);
    setNoneFound(false);
    history.push(`?searchBy=${searchBy}&search=${search}`);
  };

  const onExpand = (data) => {
    setUserData(data);
    setExpanded(!expanded);
  };
  const onApply = (selectedGigs, gigData) => {
    return props.firebase
      .applyGigs(selectedGigs, userData, gigData, userData.uid)
      .then((result) => {
        if (result.success) {
          setGigsClaimed(result.gigsApplied);
          refetch();
          return true;
        }
      });
  };
  const onClaim = (siblings, original, owner) => {
    const claimedGigs = [...siblings];
    claimedGigs.push(original);
    return props.firebase
      .claimGigs(claimedGigs, userData, owner, userData.uid)
      .then((result) => {
        if (result.success) {
          setGigsClaimed(result.gigsClaimed);
          refetch();
          return true;
        }
      });
  };
  const onClaimClose = () => {
    setGigsClaimed([]);
  };
  // const gigsPopulated = () => {
  //   gigsClaimed.length > 0
  //     ? gigsClaimed.some((gig) => gig.gigID === gigData.gigID)
  //     : false;
  // };

  return (
    <div
      css={{
        margin: '0 auto',
        padding: '0 20px',
        marginTop: 50,
        [mq(bp.large)]: {
          padding: 0,
          marginTop: 90,
          maxWidth: 1020,
        },
      }}
    >
      <h3 css={{ marginTop: 30 }}>Search By</h3>
      <div
        css={{
          display: 'flex',
          marginBottom: 20,
          '&>div': { marginRight: 20 },
        }}
      >
        <div>
          <label htmlFor="searchByName">Name (First Name)</label>
          <input
            type="radio"
            name="searchBy"
            id="searchByName"
            value="name"
            checked={searchBy === 'name'}
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="searchByID">ID</label>
          <input
            type="radio"
            name="searchBy"
            id="searchByID"
            value="id"
            checked={searchBy === 'id'}
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="searchByEmail">Email</label>
          <input
            type="radio"
            name="searchBy"
            id="searchByEmail"
            value="email"
            checked={searchBy === 'email'}
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </div>
      </div>
      <div
        css={{
          marginTop: 50,
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            columnGap: '40px',
            alignItems: 'center',
          },
        }}
      >
        <div css={[formInput, { marginBottom: '0' }]}>
          <input
            type="text"
            name="search"
            id="search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        <button
          type="button"
          css={(theme) =>
            button(
              theme.buttons.type_dark,
              theme.buttons.bg2,
              theme.buttons.hover2
            )
          }
          onClick={() => searchUser()}
        >
          Search
        </button>
      </div>
      {searching ? <p>Searching...</p> : null}
      {!searching && noneFound ? <p>No Users Found</p> : null}
      {!searching && !noneFound && users.length > 0 ? (
        <div>
          {users.map((user, i) => (
            <div>
              <div
                css={{
                  marginTop: 50,
                  [mq(bp.large)]: {
                    display: 'grid',
                    gridTemplateColumns: '2fr 1fr',
                    columnGap: '40px',
                    alignItems: 'end',
                  },
                }}
              >
                <div>
                  <p>
                    Name: {user.firstName} {user.lastName}
                  </p>
                  <p>Email: {user.email}</p>
                  <p>ID: {user.uid}</p>
                </div>
                <div css={{ marginBottom: 20 }}>
                  <button
                    type="button"
                    onClick={() => onExpand(user)}
                    css={(theme) =>
                      button(
                        theme.buttons.type_dark,
                        theme.buttons.bg2,
                        theme.buttons.hover2
                      )
                    }
                  >
                    Claim
                  </button>
                </div>
              </div>
              {expanded ? (
                <GigItem
                  page="active"
                  gigID={gigID}
                  gigData={gigData}
                  uid={user.uid}
                  userData={user}
                  scriptLoaded={scriptLoaded}
                  index={i}
                  gigsClaimed={gigsClaimed}
                  onClaimClose={onClaimClose}
                  claimedGigs={user.claimedGigs ? [...user.claimedGigs] : []}
                  appliedGigs={user.appliedGigs ? [...user.appliedGigs] : []}
                  onClaim={onClaim}
                  onApply={onApply}
                />
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default withFirebase(ChooseWorker);
