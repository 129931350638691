import React, { useContext, useEffect } from 'react';
import { ThemeProvider } from 'emotion-theming';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';
import version from 'constants/ver';
import { theme } from 'theme/globalTheme';
// import Invoice from 'pages/Invoice';
import AddAdmin from 'pages/AddAdmin';
import UserSearch from 'pages/UserSearch';
import Payouts from 'pages/Payouts';
import Mods from 'pages/Mods';
import CreateGig from 'pages/CreateGig';
import Claim from 'pages/Claim';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { AuthUserContext, withAuthentication } from 'library/Session';
import Login from 'pages/Login';
import Nav from 'components/Nav';
import ReactGA from 'react-ga';
import CheckID from 'pages/CheckID';

let stripePromise;
if (
  (process.env.NODE_ENV === 'development' &&
    window.location.hostname === 'localhost') ||
  (process.env.NODE_ENV === 'production' &&
    window.location.hostname === 'admin-wb-staging.web.app')
) {
  stripePromise = loadStripe('pk_test_Xw2ORVezTOeyWtNzhjndUqS900DmuUtU9y');
}
if (
  process.env.NODE_ENV === 'production' &&
  window.location.hostname !== 'admin-wb-staging.web.app' &&
  window.location.hostname === 'admin.workbriefly.com'
) {
  stripePromise = loadStripe('pk_live_efnD3LSEXZT9vstiVIkoo5nm00dnUOTA3C');
  ReactGA.initialize('UA-177263831-1');
}

const AuthRoutes = () => {
  return (
    <Switch>
      {/* <Route path="/invoice" component={Invoice} /> */}
      <Route path="/add-admin" component={AddAdmin} />
      <Route path="/user-search" component={UserSearch} />
      <Route path="/payouts" component={Payouts} />
      <Route path="/mods" component={Mods} />
      <Route path="/create-gig" component={CreateGig} />
      <Route path="/claim" component={Claim} />
      <Route path="/checkid" component={CheckID} />
    </Switch>
  );
};

const App = () => {
  const { authUser } = useContext(AuthUserContext);
  useEffect(() => {
    document.title = `${version} - WB Business Platform`;
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <Router>
          <Nav />
          <Switch>
            {/* {authUser ? <AuthRoutes /> : <Redirect to={{ pathname: '/login' }} />} */}
            <Route
              path={[
                '/add-admin',
                '/user-search',
                '/payouts',
                '/mods',
                '/create-gig',
                '/claim',
                '/checkid',
              ]}
              render={({ location }) =>
                authUser ? (
                  <AuthRoutes />
                ) : (
                  <Redirect
                    to={{ pathname: '/login', state: { from: location } }}
                  />
                )
              }
            />
            <Route
              path="/login"
              render={({ location }) =>
                authUser ? (
                  <Redirect
                    to={{ pathname: '/payouts', state: { from: location } }}
                  />
                ) : (
                  <Login />
                )
              }
            />
            {/* <Route
              render={({ location }) =>
                authUser ? (
                  <Redirect
                    to={{ pathname: '/payouts', state: { from: location } }}
                  />
                ) : (
                  <Redirect
                    to={{ pathname: '/login', state: { from: location } }}
                  />
                )
              }
            /> */}
          </Switch>
        </Router>
      </Elements>
      <div>{version}</div>
    </ThemeProvider>
  );
};

export default withAuthentication(App);
