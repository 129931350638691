/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  formInput,
  button,
  gigBox,
  buttonContainer,
  goBackButton,
  stepTitle,
} from 'theme/styleBlocks';
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { bp, mq } from 'constants/mediaQueries';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { useHistory } from 'react-router-dom';

const Step5 = (props) => {
  const {
    errors,
    clearErrors,
    handleSubmit,
    control,
    setValue,
    register,
  } = useForm();
  const history = useHistory();
  useEffect(() => {
    setValue('firstName', props.firstName);
    setValue('lastName', props.lastName);
    setValue('title', props.title);
    setValue('contactNumber', props.contactNumber);
  }, []);
  useEffect(() => {
    if (props.step !== 5) history.push('/create-gig');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = (data) => {
    props.setState((prev) => ({ ...prev, ...data }));
    props.nextStep(6);
  };
  return (
    <div
      css={(theme) => ({
        padding: '20px 15px',
        margin: '0px auto 65px',
        [mq(bp.small)]: {
          width: '90vw',
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: '60px 20px 60px 60px',
          margin: 0,
          width: '100%',
        },
      })}
    >
      <p
        css={{
          fontWeight: 500,
          fontSize: 16,
          color: '#8A8A8A',
          marginBottom: 0,
          [mq(bp.large)]: {
            display: 'none',
          },
        }}
      >
        STEP 5 OF 5
      </p>
      <h2 css={stepTitle}>Point of Contact</h2>
      <div css={gigBox}>
        <p>
          Point of Contact refers to the person who will be responding to worker
          inquiries, checking workers in and out, as well as briefing workers
          upon their arrival for the gig. This may be yourself or someone in the
          company e.g. a site manager or the shift manager.
        </p>
        <form onSubmit={() => props.nextStep(6)} css={{ width: '100%' }}>
          <div
            css={{
              width: '100%',
            }}
          >
            <div
              css={[
                formInput,
                { gridColumn: '1/4', [mq(bp.large)]: { gridColumn: '1/3' } },
              ]}
            >
              <label htmlFor="name">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First name of contact"
                ref={register({ required: true })}
                css={{ borderColor: errors.firstName && 'red !important' }}
              />
            </div>
            <div
              css={[
                formInput,
                { gridColumn: '1/4', [mq(bp.large)]: { gridColumn: '1/3' } },
              ]}
            >
              <label htmlFor="name">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last name of contact"
                ref={register({ required: true })}
                css={{ borderColor: errors.lastName && 'red !important' }}
              />
            </div>
            <div
              css={[
                formInput,
                { gridColumn: '1/4', [mq(bp.large)]: { gridColumn: '1/3' } },
              ]}
            >
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                ref={register({ required: true })}
                placeholder="E.g. Receptionist, Team Leader, Manager"
                css={{ borderColor: errors.title && 'red !important' }}
              />
            </div>
            <div
              css={[
                formInput,
                { gridColumn: '1/4', [mq(bp.large)]: { gridColumn: '1/3' } },
              ]}
            >
              <label htmlFor="contactNumber">Contact Number</label>
              <div
                css={{
                  marginBottom: 20,
                  '.form-control': {
                    width: '100%',
                    backgroundColor: 'rgba(58, 177, 202, 0.1)',
                    padding: '10px 0 10px 50px',
                    borderColor: errors.contactNumber && 'red !important',
                    display: 'block',
                    borderRadius: '4px',
                    fontSize: 14,
                    outline: 'none',
                    height: 'auto',
                    border: '1px solid transparent',
                    '&:focus': {
                      border: '1px solid #002E6D',
                    },
                    [mq(bp.small)]: {
                      fontSize: 16,
                    },
                  },
                  '.flag-dropdown': {
                    border: 'none',
                    backgroundColor: 'unset',
                    '&:hover .selected-flag': {
                      background: 'rgba(58, 177, 202, 0.1)',
                    },
                    '&.open .selected-flag': {
                      background: 'rgba(58, 177, 202, 0.1)',
                    },
                  },
                  '.selected-flag': {
                    background: 'none',
                    border: 'none',
                    padding: '0 0 0 11px',
                    width: '46px',
                    '&:focus': {
                      background: 'rgba(58, 177, 202, 0.1)',
                    },
                    '&:hover': {
                      background: 'rgba(58, 177, 202, 0.1)',
                    },
                  },
                }}
              >
                <Controller
                  as={PhoneInput}
                  name="contactNumber"
                  placeholder="Enter phone number"
                  defaultValue=""
                  inputmode="numeric"
                  onChange={(phone) => {
                    clearErrors('contactNumber');
                    return phone;
                  }}
                  country="ca"
                  disableDropdown
                  control={control}
                  inputClass="phoneInput"
                  rules={{
                    required: true,
                    pattern: {
                      value: /1(1[.-])?(\(\d{3}\)[.-]|(\d{3}[.-]?)){2}\d{4}/g,
                      message: 'Invalid Phone Number',
                    },
                  }}
                  regions={['north-america', 'carribean']}
                />
                <FormFieldErrorMessage error={errors.contactNumber} />
              </div>
            </div>
          </div>
          <div css={buttonContainer}>
            <button
              type="button"
              onClick={() => props.prevStep(4)}
              css={goBackButton}
            >
              Back
            </button>
            <button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Step5;
