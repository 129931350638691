import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import moment from 'moment';
import Geohash from 'ngeohash';
import GeohashDistance from 'geohash-distance';
import proximityhash from 'proximityhash';

import { loadConfig } from 'constants/config';

class Firebase {
  constructor() {
    const config = loadConfig();
    let env;
    let deeplink;
    // const authApp = app.initializeApp(config.auth, 'auth');
    app.initializeApp(config);

    if (window.location.hostname === 'localhost') {
      app.functions().useFunctionsEmulator('http://localhost:5001');
    }
    if (
      (process.env.NODE_ENV === 'development' &&
        window.location.hostname === 'localhost') ||
      (process.env.NODE_ENV === 'production' &&
        window.location.hostname === 'admin-wb-staging.web.app')
    ) {
      env = 'dev';
      deeplink = 'https://worker-wb-staging.web.app';
    }
    if (
      process.env.NODE_ENV === 'production' &&
      window.location.hostname !== 'admin-wb-staging.web.app' &&
      window.location.hostname === 'admin.workbriefly.com'
    ) {
      env = 'prod';
      deeplink = 'https://worker.workbriefly.com';
    }

    this.fieldValue = app.firestore.FieldValue;

    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();

    this.env = env;
    this.deeplink = deeplink;
  }

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        const combinedData = {
          uid: authUser.uid,
          email: authUser.email,
          providerData: authUser.providerData,
        };
        next(combinedData);
      }
      if (!authUser) {
        fallback();
      }
    });

  sendNotif = (bizID, url, message) => {
    const bizRef = this.db.collection('businesses').doc(bizID);
    const notifRef = bizRef.collection('notifications');
    const notifID = notifRef.doc().id;
    bizRef.update({ notificationsRead: false }).then(() => {
      return notifRef.doc(notifID).set({
        uid: notifID,
        message,
        url,
        read: false,
        new: true,
        time: new Date(),
      });
    });
  };

  login = (email, password) => {
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .then(async (data) => {
        const token = await data.user.getIdTokenResult();
        if (token.claims.admin) {
          return { success: true };
        }
        return this.auth.signOut();
      })
      .catch((err) => {
        return { success: false, code: err.code, message: err.message };
      });
  };

  logout = () => {
    return this.auth.signOut();
  };

  addAdmin = (email) => {
    if (
      email.indexOf(
        '@workbriefly.com',
        email.length - '@workbriefly.com'.length
      ) !== -1
    ) {
      const addAdmin = this.functions.httpsCallable('addAdmin');
      return addAdmin(email).then((result) => result.data);
    }
    return Promise.resolve({
      success: false,
      message: 'User is not authorized to become admin',
    });
  };

  getInvoicingBusinesses = async () => {
    const bizRef = await this.db
      .collection('businesses')
      .where('invoicingOption', '==', true)
      .get();
    return bizRef.docs.map((doc) => {
      return doc.data();
    });
  };

  getWorkerGigs = (page, isVerified) => {
    const limit = page * 5;
    let gigsToGet;

    if (isVerified) {
      gigsToGet = this.db
        .collection('gigs')
        .where('status', '==', 1)
        .where('public', '==', true)
        .orderBy('startTimeStamp')
        .limit(limit + 1);
    }
    if (!isVerified) {
      gigsToGet = this.db
        .collection('gigs')
        .where('status', '==', 1)
        .where('public', '==', true)
        .where('verifiedReq', '==', false)
        .orderBy('startTimeStamp')
        .limit(limit + 1);
    }

    return gigsToGet.get().then((snapshot) => {
      const gigs = [];
      let showNext = false;
      let showPrev = false;
      let lastVisible;
      let firstVisible = snapshot.docs[0];
      snapshot.docs.map((doc, i) => {
        if (i > limit - 6 && i < limit) return gigs.push(doc.data());
      });
      if (page !== 1) {
        showPrev = true;
        if (snapshot.docs.length === limit)
          firstVisible = snapshot.docs[snapshot.docs.length - 6];
        if (snapshot.docs.length < limit)
          firstVisible = snapshot.docs[limit - 5];
      }
      if (snapshot.docs.length > limit) {
        showNext = true;
        lastVisible = snapshot.docs[snapshot.docs.length - 2];
      }
      return {
        gigs,
        showPrev,
        showNext,
        lastVisible,
        firstVisible,
      };
    });
  };

  gigRef = (gigID) => this.db.collection('gigs').doc(gigID);

  settleBusiness = (value, gigID) => {
    return this.db
      .collection('gigs')
      .doc(gigID)
      .update({ hasBusinessPaid: value });
  };

  settleWorker = (value, gigID, workerID) => {
    const gigRef = this.db.collection('gigs').doc(gigID);

    return this.db.runTransaction((transaction) => {
      return transaction.get(gigRef).then((doc) => {
        const updatedBookedWorkers = [...doc.data().bookedWorkers];
        const index = updatedBookedWorkers.findIndex(
          (workers) => workers.uid === workerID
        );
        let allWorkersPaid = false;
        updatedBookedWorkers[index].hasBeenPaid = value;
        if (
          !updatedBookedWorkers.some((worker) => worker.hasBeenPaid !== true)
        ) {
          allWorkersPaid = true;
        }
        transaction.update(gigRef, {
          bookedWorkers: updatedBookedWorkers,
          haveWorkersBeenPaid: allWorkersPaid,
        });
      });
    });
  };

  searchUser = async (type, searchBy, query) => {
    const ref = this.db.collection(type);
    const users = [];
    if (searchBy === 'name') {
      return ref
        .where('firstName', '==', query)
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => {
            return users.push(doc.data());
          });
        })
        .then(() => {
          return users;
        });
    }
    if (searchBy === 'id') {
      return ref
        .where('uid', '==', query)
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => {
            return users.push({ ...doc.data(), userID: doc.id });
          });
        })
        .then(() => {
          return users;
        });
    }
    if (searchBy === 'email') {
      // const emailSearch = await
      return ref
        .where('email', '==', query)
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => {
            return users.push({ ...doc.data(), userID: doc.id });
          });
        })
        .then(() => {
          return users;
        });
      // return Promise.all(emailSearch).then(() => {
      //   return users;
      // });
    }
  };

  getGigs = (gigs, type) => {
    return new Promise((resolve) => {
      // const uid = this.auth.currentUser.uid;
      const userRef = this.db.collection('gigs');
      const gigsArray = [];
      return Promise.all(
        gigs.map((gig) => {
          return userRef
            .doc(gig.gigID)
            .get()
            .then((snapshot) => {
              const data = snapshot.data();
              if (type === 'active') {
                if (data.active) {
                  return Promise.resolve(gigsArray.push(snapshot.data()));
                }
                if (!data.active) {
                  return Promise.resolve();
                }
              }
              if (type === 'completed') {
                return Promise.resolve(gigsArray.push(snapshot.data()));
              }
            });
        })
      ).then(() => {
        resolve(gigsArray);
      });
    });
  };

  searchGig = async (searchBy, query) => {
    const ref = this.db.collection('gigs');
    const gigs = [];
    if (searchBy === 'businessName') {
      return (
        ref
          .where('businessName', '==', query)
          // .where('status', '==', 1)
          .get()
          .then((snapshot) => {
            return snapshot.docs.map((doc) => {
              return gigs.push(doc.data());
            });
          })
          .then(() => {
            return gigs;
            // console.log(this.getGigs(gigs, 'active'));
            // return this.getGigs(gigs, 'active');
          })
      );
    }
    if (searchBy === 'businessEmail') {
      // const emailSearch = await
      return ref
        .where('businessEmail', '==', query)
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => {
            return gigs.push({ ...doc.data(), gigID: doc.gigID });
          });
        })
        .then(() => {
          return this.getGigs(gigs, 'active');
        });
    }
    if (searchBy === 'position') {
      // const emailSearch = await
      return ref
        .where('position', '==', query)
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => {
            return gigs.push({ ...doc.data(), gigID: doc.gigID });
          });
        })
        .then(() => {
          return gigs;
        });
    }
    if (searchBy === 'gigID') {
      return ref
        .where('gigID', '==', query)
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => {
            return gigs.push({ ...doc.data(), gigID: doc.gigID });
          });
        })
        .then(() => {
          return gigs;
        });
    }
  };

  getUserData = (id, type) => {
    return this.db.collection(type).doc(id);
  };

  editSuspension = async (suspend, date, type, id) => {
    const userRef = await this.db.collection(type).doc(id);
    if (!suspend) {
      return userRef.update({ suspended: false });
    }
    if (suspend) {
      const until = await moment(date).toDate();
      return userRef.update({ suspended: true, suspendedUntil: until });
    }
  };

  getGigData = (gigID) => {
    return this.db.collection('gigs').doc(gigID);
  };

  getPayoutList = () => {
    return (
      this.db
        .collection('gigs')
        // .where('hasBusinessPaid', '==', true)
        .where('allWorkersCheckedOut', '==', true)
        .where('hasPayouts', '==', true)
        // .where('haveWorkersBeenPaid', '==', false)
        .where('adminArchived', '==', false)
    );
  };

  getArchivedPayoutList = () => {
    return this.db
      .collection('gigs')
      .where('hasPayouts', '==', true)
      .where('adminArchived', '==', true);
  };

  archiveGig = (gigID, gigSet) => {
    this.db.collection('gigs').doc(gigID).update({ adminArchived: true });
    if (gigSet.length > 0) {
      gigSet.map((id) => {
        return this.db
          .collection('gigs')
          .doc(id)
          .update({ adminArchived: true });
      });
    }
  };

  unarchiveGig = (gigID, gigSet) => {
    this.db.collection('gigs').doc(gigID).update({ adminArchived: false });
    if (gigSet.length > 0) {
      gigSet.map((id) => {
        return this.db
          .collection('gigs')
          .doc(id)
          .update({ adminArchived: false });
      });
    }
  };

  addParam = async (option, name, type, value) => {
    let convertedValue = value;
    if (type === 'number') {
      convertedValue = parseFloat(value);
    }
    if (type === 'boolean' && value === 'true') {
      convertedValue = true;
    }
    if (type === 'boolean' && value === 'false') {
      convertedValue = false;
    }
    if (type === 'array') {
      convertedValue = [];
    }

    if (option === 'all') {
      const gigsCollection = await this.db.collection('gigs').get();
      const workerCollection = await this.db.collection('workers').get();
      const businessCollection = await this.db.collection('businesses').get();
      return Promise.all([
        gigsCollection.docs.map((doc) => {
          return doc.ref.update({ [name]: convertedValue });
        }),
        workerCollection.docs.map((doc) => {
          return doc.ref.update({ [name]: convertedValue });
        }),
        businessCollection.docs.map((doc) => {
          return doc.ref.update({ [name]: convertedValue });
        }),
      ])
        .then(() => {
          return { message: 'Success', success: true };
        })
        .catch(() => {
          return { message: 'Error', success: false };
        });
    }
    const collection = await this.db.collection(option).get();
    return Promise.all(
      collection.docs.map((doc) => {
        return doc.ref.update({ [name]: convertedValue });
      })
    )
      .then(() => {
        return { message: 'Success', success: true };
      })
      .catch(() => {
        return { message: 'Error', success: false };
      });
  };

  // test function
  sendPush = async () => {
    const sendUserPushNotif = this.functions.httpsCallable('sendUserPushNotif');
    sendUserPushNotif({
      workerID: 'DqHOyhI2I9fylfnBd0C5TNG7vCB3',
      link: `${this.deeplink}/gigs?gigType=Completed&gigToShow=zLfngKGchO4RBMweaerw`,
      linkTo: 'Gigs',
      type: 'worker1hReminder',
    });
  };

  updateEmail = async (email, type, id) => {
    const userRef = await this.db.collection(type).doc(id);
    return userRef.update({ email });
  };

  updateName = async (firstName, lastName, type, id) => {
    const userRef = await this.db.collection(type).doc(id);
    return userRef.update({ firstName, lastName });
  };

  updateBusinessName = async (businessName, type, id) => {
    const userRef = await this.db.collection(type).doc(id);
    return userRef.update({ businessName });
  };

  updatePhoneNumber = async (phoneNumber, type, id) => {
    const userRef = await this.db.collection(type).doc(id);
    return userRef.update({ phoneNumber });
  };

  withdrawFromGig = (gigIDs, uid, businessID, suspend36, suspend24) => {
    const userRef = this.db.collection('workers').doc(uid);
    const businessRef = this.db.collection('businesses').doc(businessID);
    let businessEmail;
    let businessName;
    let workerFirstName;
    let workerEmail;
    // temp until MDR are put back in
    let gigPosition;
    let gigTime;

    return this.db
      .runTransaction((transaction) => {
        const workerTask = transaction.get(userRef).then((doc) => {
          const workerData = doc.data();
          const claimedGigs = [...workerData.claimedGigs];
          workerFirstName = `${workerData.firstName} ${workerData.lastName}`;
          workerEmail = workerData.email;
          const filteredGigs = claimedGigs.filter(
            (gig) => !gigIDs.includes(gig.gigID)
          );

          return transaction.update(userRef, { claimedGigs: filteredGigs });
        });
        const businessTask = transaction.get(businessRef).then((doc) => {
          const bizData = doc.data();
          businessEmail = bizData.email;
          businessName = bizData.businessName;
          const activeGigs = [...bizData.activeGigs];
          const modifiedGigs = activeGigs.map((gig) => {
            if (gigIDs.includes(gig.gigID)) {
              const modifiedGig = { ...gig };
              if (modifiedGig.workers > 0) modifiedGig.workers -= 1;
              return modifiedGig;
            }
            return gig;
          });
          return transaction.update(businessRef, { activeGigs: modifiedGigs });
        });
        const gigTasks = gigIDs.map((gigID) => {
          const gigRef = this.db.collection('gigs').doc(gigID);
          return transaction.get(gigRef).then((doc) => {
            const data = doc.data();
            // currently no MDR gigs so gigIDs should only contain 1 gigID
            gigPosition = data.position;
            gigTime = `${data.day} ${data.start}`;
            const removedWorkers = data.bookedWorkers.filter(
              (worker) => worker.uid !== uid
            );

            if (data.workersThatSaved.length > 0) {
              data.workersThatSaved.map((workerID) => {
                const workerRef = this.db.collection('workers').doc(workerID);
                workerRef.get().then((doc) => {
                  const workerData = doc.data();
                  const saved = [...workerData.savedGigs];
                  const index = saved.findIndex(
                    (gig) => gig.gigID === data.gigID
                  );
                  console.log(index);
                  saved[index].show = true;
                  return workerRef.set({ savedGigs: saved }, { merge: true });
                });
              });
            }
            if (data.siblings.length > 0) {
              data.siblings.map((sibs) => {
                const sibRef = this.db.collection('gigs').doc(sibs.gigID);
                return sibRef.get().then((doc) => {
                  const sibData = doc.data();
                  const siblings = [...sibData.siblings];
                  const index = siblings.findIndex(
                    (sibling) => sibling.gigID === data.gigID
                  );
                  siblings[index].status = 1;
                  return sibRef.set({ siblings }, { merge: true });
                });
              });
            }

            return transaction.update(gigRef, {
              bookedWorkers: removedWorkers,
              status: 1,
            });
          });
        });
        return Promise.all([...gigTasks, workerTask, businessTask]);
      })
      .then(() => {
        return this.sendEmail({
          businessName,
          toEmail: businessEmail,
          gigID: gigIDs[0],
          type: 'workerWithdraw',
        });
      })
      .then(() => {
        if (!suspend24 && !suspend36) {
          return this.sendEmail({
            gigPosition,
            workerFirstName,
            businessName,
            gigTime,
            toEmail: workerEmail,
            type: 'workerWithdrawSuccess',
          });
        }
        if (suspend24 || suspend36) {
          let weeks;
          let hours;
          if (suspend36) {
            weeks = 2;
            hours = 36;
          }
          if (suspend24) {
            weeks = 4;
            hours = 24;
          }
          return this.sendEmail({
            gigIDs,
            weeks,
            hours,
            workerID: uid,
            toEmail: 'info@workbriefly.com',
            type: 'workerWithdrawSuspend',
          });
        }
      })
      .catch((err) => console.log(err));
  };

  // *** Twilio ***
  twilioCreateVerification = (method, phoneNumber) => {
    const twilioCreate = this.functions.httpsCallable('twilioCreate');

    return twilioCreate({ method, phoneNumber }).then((result) => {
      if (result.data.success === true) {
        return;
      }
      if (result.data.success === false) {
        const error = {
          code: result.data.code,
          message: 'Please check your number and try again.',
        };
        throw error;
      }
    });
  };

  twilioCheckVerification = (phoneNumber, code) => {
    const twilioVerify = this.functions.httpsCallable('twilioVerify');
    return twilioVerify({ phoneNumber, code }).then((result) => {
      let data = {};
      if (
        !result.data.success ||
        result.data.status === 'pending' ||
        result.data.code === 60200
      ) {
        data = {
          message: 'Invalid phone verification code',
          phoneVerified: false,
        };
      }
      if (result.data.status === 'approved') {
        data = {
          message: 'Phone Number Verified',
          phoneVerified: true,
        };
      }
      return data;
    });
  };

  sendEmail = (emailData) => {
    const sendEmail = this.functions.httpsCallable('sendEmail');
    return sendEmail({ ...emailData, env: this.env });
  };

  checkSetupStatus = async (type, id) => {
    const userRef = await this.db.collection(type).doc(id);
    const check = await userRef.get().then((doc) => {
      const { setup, firstName, email } = doc.data();
      if (
        setup.cert &&
        setup.experience &&
        setup.govID &&
        setup.profileImage &&
        setup.stripe
      ) {
        doc.ref
          .update({ setupComplete: true, setupCompleteTime: new Date() })
          .then(() => {
            this.sendEmail({
              workerName: firstName,
              toEmail: email,
              type: 'workerOnboardingComplete',
            });
          });
      }
    });
    return check;
  };

  saveGig = (gigID) => {
    const { uid } = this.auth.currentUser;
    const userRef = this.user(uid);
    const gigRef = this.db.collection('gigs').doc(gigID);
    return Promise.all([
      userRef.update({
        savedGigs: this.fieldValue.arrayUnion({ gigID, show: true }),
      }),
      gigRef.update({ workersThatSaved: this.fieldValue.arrayUnion(uid) }),
    ]).then(() => {
      return { success: true };
    });
  };

  unsaveGig = (gigID) => {
    const { uid } = this.auth.currentUser;
    const userRef = this.user(uid);
    const gigRef = this.db.collection('gigs').doc(gigID);

    return Promise.all([
      userRef.update({
        savedGigs: this.fieldValue.arrayRemove({ gigID, show: true }),
      }),
      gigRef.update({ workersThatSaved: this.fieldValue.arrayRemove(uid) }),
    ]).then(() => {
      console.log('asdf');
      return { success: true };
    });
  };

  saveExp = async (expList, type, id) => {
    const userRef = await this.db.collection(type).doc(id);
    const update = await userRef
      .update({
        'setup.experience': true,
        exp: expList,
      })
      .then(() => this.checkSetupStatus(type, id));
    return update;
  };

  saveCert = (certList, id, type) => {
    const userRef = this.db.collection(type).doc(id);
    const certCodeList = certList.reduce((acc, cert) => {
      acc[cert.code] = true;
      if (cert.equivalent.length > 0) {
        cert.equivalent.map((equivalentCert) => {
          acc[equivalentCert] = true;
        });
      }
      // acc.push(cert.code, ...cert.equivalent);
      return acc;
    }, {});
    const update = userRef
      .update({
        'setup.cert': true,
        certList,
        certCodeList,
      })
      .then(() => {
        this.checkSetupStatus(type, id);
      });
    return update;
  };

  saveProfileImage = (originalImage, croppedImage, type, id) => {
    console.log(originalImage, croppedImage, type, id);
    const storageRef = this.storage.ref();
    const userRef = this.db.collection(type).doc(id);

    let originalImageRef;
    if (originalImage) {
      const originalType = originalImage.type.replace('image/', '');
      originalImageRef = storageRef.child(
        `workers/${id}/profileImage/original-image.${originalType}`
      );
    }

    const croppedImageRef = storageRef.child(
      `workers/${id}/profileImage/profile-image.jpeg`
    );

    const uploadCropped = new Promise((resolve, reject) => {
      croppedImageRef.put(croppedImage).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          userRef
            .update({
              'setup.profileImage': true,
              profileImage: url,
            })
            .then(() => {
              this.checkSetupStatus(type, id);
              resolve();
            });
        });
      });
    });
    console.log(originalImage);
    if (originalImage) {
      const uploadOriginal = new Promise((resolve, reject) => {
        originalImageRef
          .put(originalImage)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              userRef
                .update({
                  originalProfileImage: url,
                })
                .then(() => {
                  resolve();
                });
            });
          })
          .catch((error) => console.log(error));
      });
      return Promise.all([uploadOriginal, uploadCropped]).then(() => {});
    }
    console.log(originalImage);
    return Promise.all([uploadCropped]).then(() => {});
  };

  checkLocationIndustry = (type, id) => {
    const userRef = this.db.collection(type).doc(id);
    return userRef.get().then((doc) => {
      const data = doc.data();
      if (
        data.preferences.industries.length === 0 ||
        !data.preferences.address
      ) {
        return userRef.update({ 'setup.preferences': false });
      }
      if (data.preferences.industries.length > 0 && data.preferences.address) {
        return userRef.update({ 'setup.preferences': true });
      }
    });
  };

  saveLocation = (preferences, location, id, type) => {
    const { address, range } = preferences;
    const userRef = this.db.collection(type).doc(id);

    const options = {
      latitude: location.lat, // required
      longitude: location.lng, // required
      radius: preferences.range, // in mts, required
      precision: 5, // geohash precision level , required
      georaptorFlag: true, // set true to compress hashes using georaptor
      minlevel: 1, // minimum geohash level, default value: 1
      maxlevel: 12, // maximum geohash level, default value: 12
      approxHashCount: true, // set to true to round off if the hashes count is greater than 27
    };

    const geohashes = proximityhash.createGeohashes(options);

    const workerGeohash = Geohash.encode(location.lat, location.lng, 5);

    return userRef
      .update({
        'preferences.address': address,
        'preferences.range': range,
        location: {
          ...location,
          geohashes,
          workerGeohash,
        },
      })
      .then(() => {
        this.checkLocationIndustry(type, id);
      });
  };

  saveIndustries = (preferences, type, id) => {
    const { industries } = preferences;
    const userRef = this.db.collection(type).doc(id);
    return userRef
      .update({
        'preferences.industries': industries,
      })
      .then(() => {
        this.checkLocationIndustry(type, id);
      });
  };

  businessSnapshot = (id) => this.db.collection('businesses').doc(id);

  gigSnapshot = (gigID) => this.db.collection('gigs').doc(gigID);

  workerSnapshot = (id) => this.db.collection('workers').doc(id);

  getSavedCards = (id) => {
    const getSavedCards = this.functions.httpsCallable('getSavedCards');
    return getSavedCards(id).then((cardsData) => cardsData.data);
  };

  saveCard = (paymentMethod, customerID, setDefault) => {
    const saveCard = this.functions.httpsCallable('saveCards');
    return saveCard({ paymentMethod, customerID });
    // .then((result) => {
    //   if (setDefault) {
    //     const uid = this.auth.currentUser.uid;
    //     const userRef = this.user(uid);
    //     return userRef.update({
    //       defaultPaymentCard: result.data.id,
    //     });
    //   }
    // });
  };

  removePaymentMethod = (card) => {
    const removeCard = this.functions.httpsCallable('removeCard');
    return removeCard(card).catch((err) => console.log(err));
  };

  getClientSecret = (customerID) => {
    const getClientSecret = this.functions.httpsCallable('getClientSecret');
    return getClientSecret(customerID).then((data) => data.data.client_secret);
  };

  getTaxPercentage = (province) => {
    const camelProvince = province
      .split(' ')
      .map((word, i) => {
        if (i === 0) {
          return word[0].toLowerCase() + word.substring(1);
        }
        if (i !== 0) {
          return word[0].toUpperCase() + word.substring(1);
        }
      })
      .join('');
    const taxDoc = this.db.collection('settings').doc('tax');
    return taxDoc.get().then((doc) => {
      const data = doc.data();
      return data.canada[camelProvince];
    });
  };

  getRecommendedWorkers = (gigID) => {
    const gigRef = this.db.collection('gigs').doc(gigID);
    return gigRef.get().then((doc) => {
      const gigData = doc.data();

      const { gigGeohash } = gigData;
      const certReqs = Object.keys(gigData.reqCertCodes);
      // const verified = gigData.verifiedReq;
      let query = this.db.collection('workers').where('suspended', '==', false);

      if (certReqs.length > 0) {
        certReqs.forEach((cert) => {
          query = query.where(`certCodeList.${cert}`, '==', true);
          // .where(`preferences.range`, "<=", "2000");
        });
        query = query.where(`location.geohashes`, 'array-contains', gigGeohash);

        return query.get().then((data) => {
          return Promise.all(
            data.docs.map((worker) => {
              const workerData = worker.data();

              const { workerGeohash } = workerData.location;

              const returnData = {
                workerID: workerData.uid,
                ratings: workerData.ratings,
                img: workerData.profileImage,
                name: `${workerData.firstName} ${workerData.lastName}`,
                location: workerData.location,
                range: workerData.preferences.range,
                requestSent: false,
                geohashes: workerData.location.geohashes,
                distanceToGig: Math.round(
                  GeohashDistance.inKm(workerGeohash, gigGeohash) * 1000
                ),
              };
              return returnData;
            })
          );
        });
      }

      query = query.where(`location.geohashes`, 'array-contains', gigGeohash);

      return query.get().then((data) => {
        return Promise.all(
          data.docs.map((worker) => {
            const workerData = worker.data();

            const { workerGeohash } = workerData.location;

            const returnData = {
              workerID: workerData.uid,
              ratings: workerData.ratings,
              img: workerData.profileImage,
              name: `${workerData.firstName} ${workerData.lastName}`,
              location: workerData.location,
              range: workerData.preferences.range,
              requestSent: false,
              geohashes: workerData.location.geohashes,
              distanceToGig: Math.round(
                GeohashDistance.inKm(workerGeohash, gigGeohash) * 1000
              ),
            };
            return returnData;
          })
        );
      });
    });
  };

  applyGigs = (selectedGigs, authUser, gigData, uid) => {
    const { firstName, lastName, profileImage } = authUser;
    const { owner, businessName, businessEmail } = gigData;
    const userRef = this.db.collection('workers').doc(uid);
    const gigsRef = this.db.collection('gigs');
    const gigsApplied = [];
    return Promise.all(
      selectedGigs.map((id) => {
        const gigDocRef = gigsRef.doc(id);
        return this.db
          .runTransaction((transaction) => {
            return transaction.get(gigDocRef).then((gigDoc) => {
              const gigData = gigDoc.data();
              if (gigData.status === 3) return { claimed: false, gigData };
              if (gigData.status === 1) {
                const status = 1;
                if (
                  gigData.workersThatSaved &&
                  gigData.workersThatSaved.includes(uid)
                ) {
                  userRef.get().then((doc) => {
                    const userData = doc.data();
                    const userSaved = [...userData.savedGigs];
                    const index = userSaved.findIndex(
                      (gig) => gig.gigID === gigData.gigID
                    );
                    userSaved[index].show = false;
                    userRef.set({ savedGigs: userSaved }, { merge: true });
                  });
                }
                transaction.update(gigDocRef, { status });
                return { claimed: true, gigData };
              }
            });
          })
          .then((result) => {
            if (result.claimed) {
              const start = moment(
                `${result.gigData.start} ${result.gigData.formattedDate}`,
                'h:mm A MMMM D,YYYY'
              );
              let end = moment(
                `${result.gigData.end} ${result.gigData.formattedDate}`,
                'h:mm A MMMM D,YYYY'
              );
              if (result.gigData.nextDay) {
                end = end.add(1, 'day');
              }

              return Promise.all([
                gigsApplied.push({
                  success: true,
                  gigID: result.gigData.gigID,
                  day: result.gigData.formattedDate,
                  start: result.gigData.start,
                  end: result.gigData.end,
                  nextDay: result.gigData.nextDay,
                }),
                gigDocRef.update({
                  appliedWorkers: this.fieldValue.arrayUnion({
                    uid,
                    firstName: firstName || null,
                    lastName: lastName || null,
                    profileImage: profileImage || '',
                    hasBeenDeclined: false,
                    available: true,
                  }),
                }),
                userRef.update({
                  appliedGigs: this.fieldValue.arrayUnion({
                    gigID: id,
                    day: result.gigData.formattedDate,
                    start: start.toDate(),
                    end: end.toDate(),
                    hasBeenDeclined: false,
                  }),
                  requests: this.fieldValue.arrayRemove(id),
                }),
              ]).then((data) => {
                return Promise.all([
                  this.sendNotif(
                    owner,
                    `/gigs/active?gig=${id}`,
                    'A worker has applied for your gig.'
                  ),
                  this.sendEmail({
                    businessName,
                    toEmail: businessEmail,
                    gigID: selectedGigs[0],
                    type: 'workerGigApply',
                  }),
                ]);
              });
            }
            if (!result.claimed) {
              return gigsApplied.push({
                success: false,
                gigID: result.gigData.gigID,
                day: result.gigData.formattedDate,
                start: result.gigData.start,
                end: result.gigData.end,
                nextDay: result.gigData.nextDay,
              });
            }
          });
      })
    )
      .then(() => {
        return { success: true, gigsApplied };
      })
      .catch((error) => {
        return { success: false, error };
      });
  };

  claimGigs = (gigIDs, workerInfo, businessID, userID) => {
    // const { uid } = this.auth.currentUser;
    const userRef = this.db.collection('workers').doc(userID);
    const gigsRef = this.db.collection('gigs');
    const businessRef = this.db.collection('businesses').doc(businessID);
    const gigsClaimed = [];
    return Promise.all(
      gigIDs.map((id) => {
        const gigDocRef = gigsRef.doc(id);
        return this.db
          .runTransaction((transaction) => {
            return transaction.get(gigDocRef).then((gigDoc) => {
              const gigData = gigDoc.data();
              if (gigData.status === 3) return { claimed: false, gigData };
              if (gigData.status === 1) {
                let status = 1;
                if (
                  gigData.workersThatSaved &&
                  gigData.workersThatSaved.includes(userID)
                ) {
                  transaction.get(userRef).then((userDoc) => {
                    const userData = userDoc.data();
                    const userSaved = [...userData.savedGigs];
                    const index = userSaved.findIndex(
                      (gig) => gig.gigID === gigData.gigID
                    );
                    userSaved[index].show = false;
                    userRef.set({ savedGigs: userSaved }, { merge: true });
                  });
                }

                const appliedGigs = [...workerInfo.appliedGigs];
                const claimedGigRange = moment.range(
                  moment(
                    `${gigData.start} ${gigData.day}`,
                    'h:mm A MMM D,YYYY'
                  ),
                  moment(`${gigData.end} ${gigData.day}`, 'h:mm A MMM D,YYYY')
                );

                appliedGigs.forEach((appliedGig) => {
                  const appliedGigRange = moment.range(
                    moment(new Date(appliedGig.start.seconds * 1000)),
                    moment(new Date(appliedGig.end.seconds * 1000))
                  );
                  if (claimedGigRange.overlaps(appliedGigRange)) {
                    const overlapGigRef = this.db
                      .collection('gigs')
                      .doc(appliedGig.gigID);
                    transaction.get(overlapGigRef).then((doc) => {
                      const overlapGigData = doc.data();
                      const appliedWorkers = [...overlapGigData.appliedWorkers];
                      const workerIndex = appliedWorkers.findIndex(
                        (worker) => worker.uid === userID
                      );
                      appliedWorkers[workerIndex].available = false;
                      overlapGigRef.set({ appliedWorkers }, { merge: true });
                    });
                  }
                });

                if (gigData.bookedWorkers.length + 1 === gigData.vacancies) {
                  status = 3;
                  if (
                    gigData.workersThatSaved &&
                    gigData.workersThatSaved.length > 0
                  ) {
                    gigData.workersThatSaved.map((workerID) => {
                      const workerRef = this.db
                        .collection('workers')
                        .doc(workerID);
                      workerRef.get().then((doc) => {
                        const workerData = doc.data();
                        const saved = [...workerData.savedGigs];
                        const index = saved.findIndex(
                          (gig) => gig.gigID === gigData.gigID
                        );
                        saved[index].show = false;
                        return workerRef.set(
                          { savedGigs: saved },
                          { merge: true }
                        );
                      });
                    });
                  }
                  if (gigData.siblings.length > 0) {
                    gigData.siblings.map((sibs) => {
                      const sibRef = this.db.collection('gigs').doc(sibs.gigID);
                      return sibRef.get().then((doc) => {
                        const sibData = doc.data();
                        const siblings = [...sibData.siblings];
                        const index = siblings.findIndex(
                          (sibling) => sibling.gigID === gigData.gigID
                        );
                        siblings[index].status = 3;
                        return sibRef.set({ siblings }, { merge: true });
                      });
                    });
                  }
                }
                transaction.update(gigDocRef, { status });
                return { claimed: true, gigData };
              }
            });
          })
          .then((result) => {
            if (result.claimed) {
              const start = moment(
                `${result.gigData.start} ${result.gigData.formattedDate}`,
                'h:mm A MMMM D,YYYY'
              );
              let end = moment(
                `${result.gigData.end} ${result.gigData.formattedDate}`,
                'h:mm A MMMM D,YYYY'
              );
              if (result.gigData.nextDay) {
                end = end.add(1, 'day');
              }
              return Promise.all([
                gigsClaimed.push({
                  success: true,
                  gigID: result.gigData.gigID,
                  day: result.gigData.formattedDate,
                  start: result.gigData.start,
                  end: result.gigData.end,
                  nextDay: result.gigData.nextDay,
                }),
                gigDocRef.update({
                  bookedWorkers: this.fieldValue.arrayUnion({
                    uid: userID,
                    firstName: workerInfo.firstName || null,
                    lastName: workerInfo.lastName || null,
                    phoneNumber: workerInfo.phoneNumber || null,
                    email: workerInfo.email || null,
                    profileImage: workerInfo.profileImage || '',
                    status: 0,
                    stripeConnectId: workerInfo.stripeConnectId,
                    amountToBePaid: 0,
                    hasBeenPaid: false,
                    hoursLogged: 0,
                    workerCheckin: false,
                    workerCheckout: false,
                    businessCheckin: false,
                    businessCheckout: false,
                  }),
                }),
                this.db.runTransaction((transaction) => {
                  return transaction.get(businessRef).then((businessDoc) => {
                    const businessData = businessDoc.data();
                    const activeGigs = [...businessData.activeGigs];
                    const index = activeGigs.findIndex(
                      (gigs) => gigs.gigID === id
                    );
                    activeGigs[index].workers += 1;
                    transaction.update(businessRef, { activeGigs });
                    return businessData;
                  });
                }),
                userRef.update({
                  claimedGigs: this.fieldValue.arrayUnion({
                    gigID: id,
                    status: 1,
                    day: result.gigData.formattedDate,
                    start: start.toDate(),
                    end: end.toDate(),
                  }),
                  requests: this.fieldValue.arrayRemove(id),
                }),
              ]).then((data) => {
                return Promise.all([
                  this.sendNotif(
                    businessID,
                    `/gigs/active?gig=${id}`,
                    'A worker has claimed your gig.'
                  ),
                  this.sendEmail({
                    businessName: data[2].businessName,
                    toEmail: data[2].email,
                    gigID: gigIDs[0],
                    type: 'workerGigClaim',
                  }),
                ]);
              });
            }
            if (!result.claimed) {
              return gigsClaimed.push({
                success: false,
                gigID: result.gigData.gigID,
                day: result.gigData.formattedDate,
                start: result.gigData.start,
                end: result.gigData.end,
                nextDay: result.gigData.nextDay,
              });
            }
          });
      })
    )
      .then(() => {
        return { success: true, gigsClaimed };
      })
      .catch((error) => {
        return { success: false, error };
      });
  };

  createGig = async (
    paymentMethodID,
    gigData,
    businessName,
    invoicingOption,
    checkoutMethod,
    id
  ) => {
    const userRef = this.db.collection('businesses').doc(id);
    const userData = await userRef.get().then((snapshot) => snapshot.data());

    const reqCertCodes = gigData.certs.reduce((acc, curr) => {
      if (curr.required) {
        acc[curr.code] = true;
      }
      return acc;
    }, {});
    const certReqNum = gigData.certs.filter(
      (cert) => cert.required === true
    ).length;

    const gigGeohash = Geohash.encode(
      gigData.location.lat,
      gigData.location.lng,
      5
    );

    const getGigNumber = (num) => {
      if (num < 10) {
        return `00${num}`;
      }
      if (num < 100) {
        return `0${num}`;
      }
      return num;
    };

    if (gigData.dates.length === 1) {
      const gigID = this.db.collection('gigs').doc().id;

      const totalForTheDay = () => {
        if (!gigData.dates[0].paidBreak) {
          const breakHours = moment
            .duration(gigData.dates[0].breakTime * 60000)
            .asHours();
          return (
            Math.trunc(
              (gigData.dates[0].hours - breakHours) * gigData.payPerHour * 100
            ) / 100
          );
        }
        return (
          Math.trunc(gigData.dates[0].hours * gigData.payPerHour * 100) / 100
        );
      };

      return (
        this.db
          .collection('gigs')
          .doc(gigID)
          .set({
            gigSummaryGroup: [],
            lastGigOfTheWeek: false,
            lastGigInMDR: false,
            requestedWorkers: [],
            public: true,
            creationDate: new Date(),
            active: true,
            gigID,
            sharedID: null,
            owner: id,
            stripeCustomerId: userData.stripeCustomerId,
            status: 1,
            reqCertCodes,
            certReqNum,
            gigGeohash,
            paymentMethod: paymentMethodID,
            businessName: userData.businessName,
            businessEmail: userData.email,
            businessPhone: userData.phoneNumber,
            reminderSent: false,
            oneDayReminderSent: false,
            oneHourReminderSent: false,
            past5minReminderSent: false,
            ...gigData,
            day: gigData.dates[0].day,
            start: gigData.dates[0].start,
            end: gigData.dates[0].end,
            nextDay: gigData.dates[0].nextDay,
            hours: gigData.dates[0].hours,
            breakTime: gigData.dates[0].breakTime,
            paidBreak: gigData.dates[0].paidBreak,
            checkoutReminderSent: false,
            allWorkersCheckedOut: false,
            authAttempted: false,
            totalForThisDay: totalForTheDay(),
            formattedDate: moment(gigData.dates[0].day).format('MMMM D, YYYY'),
            siblings: [],
            bookedWorkers: [],
            checkin: [],
            profileImage: userData.profileImage,
            paymentAuthorized: false,
            startTimeStamp: new Date(
              `${gigData.dates[0].day} ${gigData.dates[0].start}`
            ),
            endTimeStamp: new Date(
              `${gigData.dates[0].day} ${gigData.dates[0].end}`
            ),
            hasPayouts: false,
            adminArchived: false,
            invoicingOption,
            checkoutMethod,
            workersThatSaved: [],
            appliedWorkers: [],
            gigReferenceNumber: `${moment(gigData.dates[0].day).format(
              'YYMMDD'
            )}-${userData.businessNumber}-${getGigNumber(
              (userData.gigCount || 0) + 1
            )}`,
          })
          .then(() => {
            return userRef.update({
              activeGigs: this.fieldValue.arrayUnion({
                gigID,
                workers: 0,
                startTimeStamp: new Date(
                  `${gigData.dates[0].day} ${gigData.dates[0].start}`
                ),
                endTimeStamp: new Date(
                  `${gigData.dates[0].day} ${gigData.dates[0].end}`
                ),
              }),
              gigCount: this.fieldValue.increment(1),
            });
          })
          // .then(() => {
          //   if (!invoicingOption) {
          //     const { day, end } = gigData.dates[0];
          //     const date = moment(`${end} ${day}`, 'h:mm A MMM D, YYYY').subtract(
          //       5,
          //       'days'
          //     );
          //     const now = moment();
          //     if (now.isAfter(date)) {
          //       const authPayment = this.functions.httpsCallable('authPayment');
          //       return authPayment(gigID).then(() => {
          //         return true;
          //       });
          //     }
          //   }
          //   console.log('invoicing');
          // })
          .then(() => {
            this.sendEmail({
              businessName: userData.businessName,
              gigID,
              type: 'businessCreateGig',
            });
            return { success: true, gigIDs: [gigID] };
          })
          .catch((error) => {
            return {
              success: false,
              error,
            };
          })
      );
    }
    if (gigData.dates.length > 1) {
      const batch = this.db.batch();
      const gigsRef = this.db.collection('gigs');
      const sharedID = gigsRef.doc().id;
      const siblings = [];
      const gigIDs = [];
      let tempGigSummaryGroup = [];
      let gigNum = userData.gigCount || 0;

      const sortedDates = gigData.dates.sort((a, b) => {
        const first = a.nextDay ? moment(a.day).add(1, 'day') : moment(a.day);
        const second = b.nextDay ? moment(b.day).add(1, 'day') : moment(b.day);
        return first.diff(second);
      });

      const totalForTheDay = (date) => {
        if (!date.paidBreak) {
          const breakHours = moment.duration(date.breakTime * 60000).asHours();
          return (
            Math.trunc((date.hours - breakHours) * gigData.payPerHour * 100) /
            100
          );
        }
        return Math.trunc(date.hours * gigData.payPerHour * 100) / 100;
      };

      sortedDates.map((date) => {
        const gigID = this.db.collection('gigs').doc().id;
        gigIDs.push(gigID);

        return siblings.push({
          gigID,
          day: date.day,
          start: date.start,
          end: date.end,
          nextDay: date.nextDay,
          pay: totalForTheDay(date),
          status: 1,
        });
      });

      sortedDates.map((date, i) => {
        gigNum += 1;
        let lastGigOfTheWeek = false;
        let lastGigInMDR = false;
        let gigSummaryGroup = [];

        if (i + 1 === sortedDates.length) {
          gigSummaryGroup = tempGigSummaryGroup;
          lastGigOfTheWeek = true;
          tempGigSummaryGroup = [];
          lastGigInMDR = true;
        }
        if (gigData.sameWorker && i + 1 < sortedDates.length) {
          const gigDate = date.nextDay
            ? moment(date.day).add(1, 'day')
            : moment(date.day);
          const nextGigDate = sortedDates[i + 1].nextDay
            ? moment(sortedDates[i + 1].day).add(1, 'day')
            : moment(sortedDates[i + 1].day);
          if (
            gigDate.day() !== 5 &&
            nextGigDate.diff(gigDate, 'days') <= 6 &&
            nextGigDate.day() <= 5 &&
            nextGigDate.diff(gigDate, 'days') <=
              (5 - gigDate.day() > 0
                ? 5 - gigDate.day()
                : 5 - gigDate.day() + 7)
          ) {
            lastGigOfTheWeek = false;
            tempGigSummaryGroup.push(siblings[i].gigID);
          }
          if (
            gigDate.day() === 5 || // if friday = pay
            nextGigDate.diff(gigDate, 'days') > 6 || // if gap between 2 dates > 6 = pay
            nextGigDate.day() > 5 || // if next gig day is past friday = pay
            nextGigDate.diff(gigDate, 'days') >
              (5 - gigDate.day() > 0
                ? 5 - gigDate.day()
                : 5 - gigDate.day() + 7)
          ) {
            gigSummaryGroup = tempGigSummaryGroup;
            lastGigOfTheWeek = true;
            tempGigSummaryGroup = [];
          }
        }
        const gig = {
          gigSummaryGroup,
          lastGigOfTheWeek,
          lastGigInMDR,
          requestedWorkers: [],
          public: true,
          creationDate: new Date(),
          active: true,
          gigID: siblings[i].gigID,
          sharedID,
          owner: id,
          stripeCustomerId: userData.stripeCustomerId,
          status: 1,
          reqCertCodes,
          certReqNum,
          gigGeohash,
          paymentMethod: paymentMethodID,
          businessName: userData.businessName,
          businessEmail: userData.email,
          businessPhone: userData.phoneNumber,
          reminderSent: false,
          oneDayReminderSent: false,
          oneHourReminderSent: false,
          past5minReminderSent: false,
          ...gigData,
          day: date.day,
          start: date.start,
          end: date.end,
          nextDay: date.nextDay,
          hours: date.hours,
          breakTime: date.breakTime,
          paidBreak: date.paidBreak,
          checkoutReminderSent: false,
          allWorkersCheckedOut: false,
          authAttempted: false,
          totalForThisDay: totalForTheDay(date),
          formattedDate: moment(date.day).format('MMMM D, YYYY'),
          siblings: siblings.filter(
            (sibling) => sibling.gigID !== siblings[i].gigID
          ),
          bookedWorkers: [],
          checkin: [],
          profileImage: userData.profileImage,
          paymentAuthorized: false,
          startTimeStamp: new Date(`${date.day} ${date.start}`),
          endTimeStamp: new Date(`${date.day} ${date.end}`),
          hasPayouts: false,
          adminArchived: false,
          invoicingOption,
          checkoutMethod,
          workersThatSaved: [],
          appliedWorkers: [],
          gigReferenceNumber: `${moment(date.day).format('YYMMDD')}-${
            userData.businessNumber
          }-${getGigNumber(gigNum)}`,
        };
        batch.update(userRef, {
          activeGigs: this.fieldValue.arrayUnion({
            gigID: siblings[i].gigID,
            workers: 0,
            startTimeStamp: new Date(`${siblings[i].day} ${siblings[i].start}`),
            endTimeStamp: new Date(`${siblings[i].day} ${siblings[i].end}`),
          }),
          gigCount: this.fieldValue.increment(1),
        });
        return batch.set(gigsRef.doc(siblings[i].gigID), gig);
        // eslint-disable-next-line array-callback-return
      });
      return (
        batch
          .commit()
          // .then(() => {
          //   if (!invoicingOption) {
          //     sortedDates.map((date, i) => {
          //       const { day, end } = date;
          //       const gigDate = moment(
          //         `${end} ${day}`,
          //         'h:mm A MMM D, YYYY'
          //       ).subtract(5, 'days');
          //       const now = moment();
          //       if (now.isAfter(gigDate)) {
          //         const authPayment = this.functions.httpsCallable('authPayment');
          //         return authPayment(siblings[i].gigID).then(() => {
          //           return true;
          //         });
          //       }
          //     });
          //   }
          //   console.log('invoicing');
          // })
          .then(() => {
            this.sendEmail({
              businessName: userData.businessName,
              gigID: gigIDs[0],
              type: 'businessCreateGig',
            });
            return { success: true, gigIDs };
          })
          .catch((error) => {
            return { success: false, error };
          })
      );
    }
  };

  checkout = (gigID) => {
    const { uid } = this.auth.currentUser;
    const gigRef = this.db.collection('gigs').doc(gigID);
    const userRef = this.db.collection('workers').doc(uid);
    return this.db.runTransaction((transaction) => {
      return transaction
        .get(gigRef)
        .then((gigDoc) => {
          const bookedWorkers = [...gigDoc.data().bookedWorkers];
          const index = bookedWorkers.findIndex((worker) => worker.uid === uid);
          bookedWorkers[index].status = 3;
          return bookedWorkers;
        })
        .then((bookedWorkers) => {
          return transaction.get(userRef).then((worker) => {
            const claimedGigs = [...worker.data().claimedGigs];
            const index = claimedGigs.findIndex((gig) => gig.gigID === gigID);
            claimedGigs[index].status = 3;
            return Promise.resolve(
              Promise.all([
                transaction.update(gigRef, { bookedWorkers }),
                transaction.update(userRef, { claimedGigs }),
              ])
            );
          });
        })
        .then(() => {
          const bizCheckoutReminder = this.functions.httpsCallable(
            'bizCheckoutReminder'
          );
          bizCheckoutReminder({
            gigID,
            env: this.env,
          });
          return true;
        });
    });
  };

  checkin = (gigID) => {
    const { uid } = this.auth.currentUser;
    const userRef = this.db.collection('workers').doc(uid);
    const gigRef = this.db.collection('gigs').doc(gigID);
    let businessID;
    return this.db.runTransaction((transaction) => {
      return transaction
        .get(gigRef)
        .then((gigDoc) => {
          const data = gigDoc.data();
          businessID = data.owner;
          const bookedWorkers = [...data.bookedWorkers];
          const index = bookedWorkers.findIndex((worker) => worker.uid === uid);
          bookedWorkers[index].status = 1;
          return bookedWorkers;
        })
        .then((bookedWorkers) => {
          return transaction.get(userRef).then((worker) => {
            const claimedGigs = [...worker.data().claimedGigs];
            const index = claimedGigs.findIndex((gig) => gig.gigID === gigID);
            claimedGigs[index].status = 1;
            return Promise.all([
              transaction.update(gigRef, { bookedWorkers }),
              transaction.update(userRef, { claimedGigs }),
            ]);
          });
        })
        .then(() => {
          const bizCheckinReminder =
            this.functions.httpsCallable('bizCheckinReminder');
          bizCheckinReminder({
            gigID,
            env: this.env,
          });
          return true;
        });
    });
  };

  // test function
  // addBizNumber = () => {
  //   const bizCollection = this.db.collection('businesses');
  //   const settingsRef = this.db.collection('settings').doc('meta');
  //   let bizNum = 0;
  //   bizCollection.get().then((bizDocs) => {
  //     bizDocs.docs.map((bizDoc) => {
  //       bizNum += 1;
  //       settingsRef.update({ businessCount: this.fieldValue.increment(1) });
  //       if (bizNum < 10) {
  //         return bizDoc.ref.update({ businessNumber: `00${bizNum}` });
  //       }
  //       if (bizNum < 100) {
  //         return bizDoc.ref.update({ businessNumber: `0${bizNum}` });
  //       }
  //       return bizDoc.ref.update({ businessNumber: bizNum });
  //     });
  //   });
  // };

  // addWorkerStuff = () => {
  //   const workerCollection = this.db.collection('workers');
  //   workerCollection.get().then((workerDocs) => {
  //     workerDocs.docs.map((workerDoc) => {
  //       return workerDoc.ref.update({
  //         appliedGigs: [],
  //         appliedHistory: [],
  //       });
  //     });
  //   });
  // };

  // addGigStuff = () => {
  //   const gigsCollection = this.db.collection('gigs');
  //   gigsCollection.get().then((gigDocs) => {
  //     gigDocs.docs.map((gigDoc) => {
  //       const gigData = gigDoc.data();
  //       const gigDate = moment(
  //         `${gigData.day} ${gigData.start}`,
  //         'MMM D, YYYY h:mm A'
  //       );

  //       if (moment().isBefore(gigDate)) {
  //         const bookedWorkers = [...gigData.bookedWorkers];
  //         const dates = [...gigData.dates];

  //         bookedWorkers.map((bookedWorker) => {
  //           const worker = bookedWorker;
  //           worker.hoursLogged = 0;
  //           worker.businessCheckin = false;
  //           worker.businessCheckout = false;
  //           worker.workerCheckin = false;
  //           worker.workerCheckout = false;
  //           return worker;
  //         });

  //         dates.map((date) => {
  //           const moddedDate = date;
  //           moddedDate.breakTime = 0;
  //           moddedDate.paidBreak = true;
  //           return moddedDate;
  //         });

  //         return gigDoc.ref.update({
  //           mode: 'claim',
  //           appliedWorkers: [],
  //           bookedWorkers,
  //           breakTime: 0,
  //           paidBreak: true,
  //           dates,
  //         });
  //       }
  //     });
  //   });
  // };
  cancelGig = async (gigID) => {
    const gigRef = this.db.collection('gigs').doc(gigID);
    gigRef
      .get()
      .then((doc) => {
        const data = doc.data();
        console.log(data.bookedWorkers, data.appliedWorkers);
        if (data.bookedWorkers.length > 0) {
          data.bookedWorkers.forEach((worker) => {
            this.db.runTransaction((transaction) => {
              const workerRef = this.db.collection('workers').doc(worker.uid);
              return transaction.get(workerRef).then((doc) => {
                const { claimedGigs } = doc.data();
                const filteredGigs = claimedGigs.filter(
                  (gig) => gig.gigID !== gigID
                );
                return transaction.update(workerRef, {
                  claimedGigs: filteredGigs,
                });
              });
            });
          });
        }
        if (data.appliedWorkers.length > 0) {
          data.appliedWorkers.forEach((worker) => {
            this.db.runTransaction((transaction) => {
              const workerRef = this.db.collection('workers').doc(worker.uid);
              return transaction.get(workerRef).then((doc) => {
                const { appliedGigs } = doc.data();
                const filteredGigs = appliedGigs.filter(
                  (gig) => gig.gigID !== gigID
                );
                return transaction.update(workerRef, {
                  appliedGigs: filteredGigs,
                });
              });
            });
          });
        }
        return gigRef.update({
          active: false,
          status: 3,
          cancelled: true,
          bookedWorkers: [],
          appliedWorkers: [],
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  // approveDem = async () => {
  //   const docs = await (
  //     await this.db
  //       .collection('workers')
  //       .where('setup.govID', '==', true)
  //       .where('setup.govIDStatus', '==', 2)
  //       .where('setupComplete', '==', false)
  //       .get()
  //   ).docs;
  //   console.log(docs.length);
  //   docs.forEach((doc) => {
  //     doc.ref.update({ setupComplete: true });
  //   });
  //   return true;
  // };

  findWorkersThatNeedIDCheck = () => {
    return this.db
      .collection('workers')
      .where('setup.govID', '==', true)
      .where('setup.govIDStatus', '==', 1)
      .where('setupComplete', '==', false);
  };

  getGovID = async ({ id }) => {
    const ref = this.storage.ref();
    const govID = await (
      await ref.child(`workers/${id}/govID`).list()
    ).items[0].getDownloadURL();
    return govID;
  };

  approveID = async (id) => {
    return this.db
      .collection('workers')
      .doc(id)
      .update({ 'setup.govIDStatus': 2, setupComplete: true });
  };
}

export default Firebase;
