/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useRef, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  button,
  customCheck,
  buttonContainer,
  tipBox,
} from 'theme/styleBlocks';
import ParkingIndicator from 'components/ParkingIndicator';
import TransitIndicator from 'components/TransitIndicator';
import { ReactComponent as Arrow } from 'assets/icons/topbarArrow.svg';
import { withFirebase } from 'library/Firebase';
import { bp, mq } from 'constants/mediaQueries';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';
import { ReactComponent as Warning } from 'assets/icons/warningIcon.svg';
import { ReactComponent as Exclamation } from 'assets/icons/exclamation.svg';
import { ReactComponent as Fail } from 'assets/icons/fail.svg';
import { ReactComponent as RedFlag } from 'assets/icons/redFlag.svg';
import { ReactComponent as Save } from 'assets/icons/like.svg';
import { ReactComponent as Saved } from 'assets/icons/liked.svg';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Ring } from 'react-spinners-css';

const moment = extendMoment(Moment);

const GigItem = ({
  gigData,
  uid,
  scriptLoaded,
  firebase,
  onClaim,
  onApply,
  claimedGigs,
  appliedGigs,
  gigsClaimed,
  onClaimClose,
  confirmPage,
  onCheckin,
  setRefetchGigs,
  page,
  //   opened,
  //   completed,
}) => {
  const mapRef = useRef(null);
  // const { gigID } = useParams();
  const [termsCheckboxs, setTermsCheckboxs] = useState({
    noshow: false,
    instructions: false,
    checkin: false,
    proof: false,
    covid: false,
  });
  const [claiming, setClaiming] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [selectedSiblings, setSelectedSiblings] = useState([]);
  const [hasClaimed, setHasClaimed] = useState(false);
  const [claimStep, setClaimStep] = useState(1);
  const [hasFailedClaims, setHasFailedClaims] = useState(false);
  const [checkinStatus, setCheckinStatus] = useState(0);
  const [checking, setChecking] = useState(false);
  const [qualified, setQualified] = useState(false);
  const [siblingOverlap, setSiblingOverlap] = useState(false);
  const [canWithdraw, setCanWithdraw] = useState(false);
  const [siblings, setSiblings] = useState([]);
  const [declined, setDeclined] = useState(false);
  const [saved, setSaved] = useState(false);
  const [suspend36, setSuspend36] = useState(false);
  const [suspend24, setSuspend24] = useState(false);
  const [userData, setUserData] = useState(false);
  const history = useHistory();
  //   const { pathname } = useLocation();

  useEffect(() => {
    if (firebase) {
      firebase.getUserData(uid, 'workers').onSnapshot((snapshot) => {
        const data = snapshot.data();
        setUserData(data);
      });
    }
  }, [firebase]);

  useEffect(() => {
    const sibs = [];
    gigData.siblings.map((sib) => {
      const date = moment(`${sib.start} ${sib.day}`, 'h:mm A MMM D,YYYY');
      const now = moment();
      if (date.isAfter(now)) {
        sibs.push(sib);
      }
    });
    setSiblings(sibs);
  }, [gigData]);
  useEffect(() => {
    const keys = Object.keys(gigData.reqCertCodes);
    if (keys.length > 0 && userData) {
      const workerCerts = Object.keys(userData.certCodeList);
      const check = keys.every((reqCert) => workerCerts.includes(reqCert));
      return setQualified(check);
    }
    setQualified(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (gigsClaimed) {
      setHasFailedClaims(gigsClaimed.some((gigs) => gigs.success === false));
    }
  }, [gigsClaimed]);
  useEffect(() => {
    // fix here for saved gigs
    if (userData && gigData.mode === 'claim') {
      if (claimedGigs.some((gig) => gig.gigID === gigData.gigID)) {
        setHasClaimed(true);
      }
      if (!claimedGigs.some((gig) => gig.gigID === gigData.gigID)) {
        setHasClaimed(false);
      }
    }
    if (userData && gigData.mode === 'apply') {
      if (appliedGigs.some((gig) => gig.gigID === gigData.gigID)) {
        setHasClaimed(true);
      }
      if (!appliedGigs.some((gig) => gig.gigID === gigData.gigID)) {
        setHasClaimed(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimedGigs]);
  useEffect(() => {
    if (scriptLoaded && mapRef.current && expanded) {
      const map = new window.google.maps.Map(
        document.getElementById(`${gigData.gigID}-map`),
        {
          center: { lat: 49.231312, lng: -122.911052 },
          zoom: 10,
          disableDefaultUI: true,
        }
      );

      const marker = new window.google.maps.Marker({
        map,
        anchorPoint: new window.google.maps.Point(0, -29),
      });

      if (gigData.location.locationID) {
        const latlng = new window.google.maps.LatLng(
          gigData.location.lat,
          gigData.location.lng
        );
        map.setCenter(latlng);
        map.setZoom(14);
        marker.setPosition(latlng);
        marker.setVisible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded, mapRef.current, expanded]);
  useEffect(() => {
    if (!gigData.sameWorker) return;
    if (siblings.length > 0) {
      const rangeList = [];
      siblings.map((date) => {
        // const newList = dateList.filter((date, index) => index !== i);
        const start = moment(`${date.start} ${date.day}`);
        let end = moment(`${date.end} ${date.day}`);
        if (date.nextDay) {
          end = end.add(1, 'day');
        }
        const range = moment.range(start, end);
        return rangeList.push(range);
      });
      let { length } = rangeList;
      let overlap = false;
      while (length--) {
        const lastItem = rangeList[length];
        rangeList.splice(length, 1);
        rangeList.map((range) => {
          if (range.overlaps(lastItem)) {
            overlap = true;
          }
        });
      }
      setSiblingOverlap(overlap);
    }
  }, []);
  useEffect(() => {
    const now = moment();
    const diff = moment.duration(
      moment(`${gigData.start} ${gigData.formattedDate}`).diff(now)
    );
    const hoursDiff = diff.asHours();
    const minutesDiff = diff.asMinutes();

    let workerCanWithdraw = false;
    let workerSuspend36 = false;
    let workerSuspend24 = false;

    if (minutesDiff > 0 && !gigData.sameWorker) {
      workerCanWithdraw = true;
    }
    if (hoursDiff < 36 && !gigData.sameWorker) {
      workerSuspend36 = true;
    }
    if (hoursDiff < 24 && !gigData.sameWorker) {
      workerSuspend24 = true;
    }

    if (gigData.sameWorker && siblings.length > 0) {
      const datesArray = [...gigData.dates];
      const sortedDates = datesArray.sort((a, b) =>
        moment(`${a.start} ${a.day}`, 'h:mm A MMM D,YYYY').diff(
          moment(`${a.start} ${a.day}`, 'h:mm A MMM D,YYYY')
        )
      );
      const diff2 = moment.duration(
        moment(
          `${sortedDates[0].start} ${sortedDates[0].day}`,
          'h:mm A MMM D,YYYY'
        ).diff(now)
      );
      const minutesDiff2 = diff2.asMinutes();
      const hoursDiff2 = diff2.asHours();

      if (minutesDiff2 > 0) {
        workerCanWithdraw = true;
      }
      if (hoursDiff2 < 36) {
        workerSuspend36 = true;
      }
      if (hoursDiff2 < 24) {
        workerSuspend24 = true;
      }
    }
    setSuspend36(workerSuspend36);
    setSuspend24(workerSuspend24);
    setCanWithdraw(workerCanWithdraw);
  }, []);
  //   useEffect(() => {
  //     if (pathname === '/gigs/completed' && opened && !completed) {
  //       setClaimStep(1);
  //       setExpanded(true);
  //     }
  //     if (pathname === '/gigs/completed' && opened && completed) {
  //       setClaimStep(8);
  //       setExpanded(true);
  //     }
  //     if (pathname !== '/gigs/completed' && opened) {
  //       setExpanded(true);
  //     }
  //   }, [opened, completed]);

  useEffect(() => {
    if (userData.savedGigs) {
      const CheckSaved = userData.savedGigs.some(
        (gigs) => gigs.gigID === gigData.gigID
      );
      if (CheckSaved) {
        setSaved(true);
      }
      if (!CheckSaved) {
        setSaved(false);
      }
    }
  }, []);
  useEffect(() => {
    if (page === 'confirmed' && userData) {
      console.log(userData.uid);
      const {
        workerCheckin,
        workerCheckout,
        businessCheckin,
        businessCheckout,
      } = gigData.bookedWorkers.find((worker) => worker.uid === userData.uid);
      if (businessCheckout || workerCheckout) {
        return setCheckinStatus(3);
      }
      if (
        (businessCheckin || workerCheckin) &&
        (!businessCheckout || !workerCheckout)
      ) {
        return setCheckinStatus(2);
      }
      if (!businessCheckin && !workerCheckin) {
        return setCheckinStatus(0);
      }
    }
  }, [gigData]);

  const onExpand = () => {
    setExpanded(!expanded);
  };
  const onCheckboxChange = (e) => {
    e.persist();
    setTermsCheckboxs((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };
  const onSiblingSelect = (e) => {
    e.persist();
    if (e.target.checked) {
      setSelectedSiblings([...selectedSiblings, e.target.name]);
    }
    if (!e.target.checked) {
      const sibs = [...selectedSiblings];
      const position = selectedSiblings.indexOf(e.target.name);
      sibs.splice(position, 1);

      setSelectedSiblings(sibs);
    }
  };
  const handleClaim = (siblingIDs, gigID, owner) => {
    let siblings = siblingIDs;
    if (gigData.sameWorker) {
      siblings = gigData.siblings.map((sibling) => {
        return sibling.gigID;
      });
    }
    setClaiming(true);
    onClaim(siblings, gigID, owner).then((result) => {
      if (result) {
        setClaiming(false);
        setClaimStep(3);
        setSelectedSiblings([]);
      }
    });
  };
  const handleApply = (siblingIDs, gigID) => {
    let siblings = siblingIDs;
    if (gigData.sameWorker) {
      siblings = gigData.siblings.map((sibling) => {
        return sibling.gigID;
      });
    }
    setClaiming(true);
    onApply([...siblings, gigID], gigData).then((result) => {
      if (result) {
        setClaiming(false);
        setClaimStep(3);
        setSelectedSiblings([]);
      }
    });
  };
  const closeButton = () => {
    setClaiming(false);
    setClaimStep(1);
    setExpanded(false);
    onClaimClose();
  };
  const handleCheckin = () => {
    setChecking(true);
    firebase.checkin(gigData.gigID).then(() => {
      setChecking(false);
      setRefetchGigs(true);
      setClaimStep(4);
      setCheckinStatus(2);
    });
  };

  const saveGig = () => {
    firebase.saveGig(gigData.gigID).then((result) => {
      if (result.success) {
        setSaved(true);
      }
    });
  };

  const unsaveGig = () => {
    firebase.unsaveGig(gigData.gigID).then((result) => {
      if (result.success) {
        setSaved(false);
      }
    });
  };
  const handleCheckOut = () => {
    setChecking(true);
    firebase.checkout(gigData.gigID).then(() => {
      setChecking(false);
      setRefetchGigs(true);
      setClaimStep(7);
      setCheckinStatus(3);
    });
  };
  const checkinText = () => {
    switch (checkinStatus) {
      case 0:
        return 'Check In';
      case 1:
        return 'Check In Pending';
      default:
        break;
    }
  };
  const checkoutText = () => {
    if (checkinStatus === 2) {
      return 'Mark Gig as Complete';
    }
    if (checkinStatus === 3) {
      return 'Marked as Complete';
    }
  };
  const displayEditorData = (content) => {
    return { __html: gigData[content] };
  };
  const isTimeToCheckin = () => {
    if (claimStep === 1 && checkinStatus === 0) {
      const timeNow = moment();
      const gigTime = moment(`${gigData.day} ${gigData.start}`).subtract(
        15,
        'm'
      );
      return timeNow.isBefore(gigTime);
    }
    return false;
  };
  const checkOverlap = () => {
    if (claimStep === 1 && !hasClaimed) {
      const start = moment(
        `${gigData.start} ${gigData.formattedDate}`,
        'h:mm A MMMM D,YYYY'
      );
      let end = moment(
        `${gigData.end} ${gigData.formattedDate}`,
        'h:mm A MMMM D,YYYY'
      );
      if (gigData.nextDay) {
        end = end.add(1, 'day');
      }
      const gigRange = moment.range(start, end);
      let overlap = false;
      if (userData.claimedGigs) {
        userData.claimedGigs.map((gigs) => {
          if (overlap) return;
          const claimedRange = moment.range(
            moment(new Date(gigs.start.seconds * 1000)),
            moment(new Date(gigs.end.seconds * 1000))
          );

          if (gigRange.overlaps(claimedRange)) {
            return (overlap = true);
          }
        });
      }
      return overlap;
    }
  };
  const checkSiblingOverlap = (data) => {
    if (claimStep === 1 && !hasClaimed) {
      const start = moment(`${data.start} ${data.day}`, 'h:mm A MMM D,YYYY');
      let end = moment(`${data.end} ${data.day}`, 'h:mm A MMMM D,YYYY');
      if (data.nextDat) {
        end = end.add(1, 'day');
      }
      const gigRange = moment.range(start, end);
      let overlap = false;
      if (userData.claimedGigs) {
        userData.claimedGigs.map((gigs) => {
          if (overlap) return;
          const claimedRange = moment.range(
            moment(new Date(gigs.start.seconds * 1000)),
            moment(new Date(gigs.end.seconds * 1000))
          );
          if (gigRange.overlaps(claimedRange)) {
            return (overlap = true);
          }
        });
      }
      return overlap;
    }
  };
  const withdrawGig = () => {
    const gigIDs = [gigData.gigID];
    if (siblings.length > 0 && gigData.sameWorker) {
      siblings.map((sib) => gigIDs.push(sib.gigID));
    }
    firebase.withdrawFromGig(
      gigIDs,
      userData.uid,
      gigData.owner,
      suspend36,
      suspend24
    );
    setClaimStep(10);
  };
  // const withdrawable = () => {
  //   const eligibleTime = moment(`${gigData.start} ${gigData.day}`).subtract(
  //     3,
  //     'hours'
  //   );
  //   const now = moment();

  //   let cantWithdraw = true;
  //   if (now.isBefore(eligibleTime)) {
  //     cantWithdraw = false;
  //   }
  //   if (gigData.sameWorker && siblings.length > 0) {
  //     siblings.map((sib) => {});
  //   }

  //   return cantWithdraw;
  // };
  const checkNotFull = () => {
    if (gigData.bookedWorkers.length >= gigData.vacancies) return true;
    return false;
  };
  const claimStatus = () => {
    if (!hasFailedClaims) {
      return (
        <React.Fragment>
          <div
            css={{
              display: 'flex',
              margin: '20px auto',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              [mq(bp.large)]: {
                flexDirection: 'row',
                width: '65%',
              },
            }}
          >
            <GreenCheck
              css={{
                width: 60,
                height: 60,
                [mq(bp.large)]: { width: 50, height: 50 },
              }}
            />
            <h2
              css={(theme) => ({
                fontSize: 24,
                color: theme.colors.dark,
                [mq(bp.large)]: {
                  margin: '0 0 0 10px',
                  fontSize: 30,
                },
              })}
            >
              {gigData.mode === 'claim' &&
                'You have been confirmed for the gig'}
              {gigData.mode === 'apply' && 'You have applied for the gig'}
            </h2>
          </div>
          {gigData.mode === 'claim' && (
            <p>
              Please review the instructions carefully provided by the business.
            </p>
          )}
        </React.Fragment>
      );
    }
    if (hasFailedClaims) {
      if (
        gigsClaimed.length === 1 ||
        gigData.sameWorker ||
        !gigsClaimed.some((gig) => gig.success === true)
      ) {
        return (
          <React.Fragment>
            <div
              css={{
                display: 'flex',
                margin: '20px auto',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                [mq(bp.large)]: {
                  flexDirection: 'row',
                  width: '65%',
                },
              }}
            >
              <Fail
                css={{
                  width: 60,
                  height: 60,
                  [mq(bp.large)]: { width: 50, height: 50 },
                }}
              />
              <h2
                css={(theme) => ({
                  fontSize: 24,
                  color: theme.colors.dark,
                  [mq(bp.large)]: {
                    margin: '0 0 0 10px',
                    fontSize: 30,
                  },
                })}
              >
                This gig is no longer available
              </h2>
            </div>
            <p>
              Unfortunately, this gig is no longer available. Please browse for
              other gigs.
            </p>
          </React.Fragment>
        );
      }
      if (gigsClaimed.length > 1) {
        return (
          <React.Fragment>
            <div
              css={{
                display: 'flex',
                margin: '20px auto',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                [mq(bp.large)]: {
                  flexDirection: 'row',
                  width: '65%',
                },
              }}
            >
              <Warning
                css={{
                  width: 60,
                  height: 60,
                  [mq(bp.large)]: { width: 50, height: 50 },
                }}
              />
              <h2
                css={(theme) => ({
                  fontSize: 24,
                  color: theme.colors.dark,
                  [mq(bp.large)]: {
                    margin: '0 0 0 10px',
                    fontSize: 30,
                  },
                })}
              >
                {gigData.mode === 'claim' &&
                  'You have claimed some of the gigs'}
                {gigData.mode === 'apply' &&
                  'You have applied for some of the gigs'}
              </h2>
            </div>
            {gigData.mode === 'claim' && (
              <p>
                You were only able to claim some of the gigs selected. Make sure
                to double-check which gigs you managed to claim succesfully.
              </p>
            )}
            {gigData.mode === 'apply' && (
              <p>
                You were only able to apply for some of the gigs selected. Make
                sure to double-check which gigs you managed to apply for
                succesfully.
              </p>
            )}
          </React.Fragment>
        );
      }
    }
  };

  // const checkExpired = (sib) => {
  //   const date = moment(`${sib.start} ${sib.day}`, 'h:mm A MMM D,YYYY');
  //   const now = moment();
  //   return now.isAfter(date);
  // };
  const declineRequest = () => {
    firebase.declineRequest(gigData.gigID).then(() => {
      setDeclined(true);
    });
  };

  const viewDetails = () => {
    setClaimStep(1);
    history.push(`/gigs/completed?gig=${gigData.gigID}`);
  };

  const expandedComponents = () => {
    switch (claimStep) {
      case 1:
        return (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              [mq(bp.large)]: {
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
            }}
          >
            <div
              css={{
                [mq(bp.large)]: { width: '55%' },
                h2: {
                  fontSize: 20,
                  [mq(bp.large)]: {
                    fontSize: 24,
                  },
                },
              }}
            >
              <div>
                <h2>Description</h2>
                <div
                  dangerouslySetInnerHTML={displayEditorData('description')}
                  css={{
                    width: '71vw',
                    margin: '0 auto',
                    overflowWrap: 'break-word',
                    [mq(bp.large)]: {
                      width: '100%',
                    },
                    a: {
                      color: 'cornflowerblue',
                    },
                    'ul,ol': {
                      paddingLeft: 24,
                      margin: 0,
                    },
                    p: { margin: 0 },
                  }}
                />
              </div>
              <div
                css={{
                  marginBottom: 15,
                  h3: {
                    fontSize: 16,
                    margin: '0 0 5px 0',
                  },
                }}
              >
                <h2>Instructions</h2>
                <div
                  css={{
                    marginBottom: 30,
                  }}
                >
                  <h3>Attire</h3>
                  <div
                    dangerouslySetInnerHTML={displayEditorData('attire')}
                    css={{
                      width: '71vw',
                      margin: '0 auto',
                      overflowWrap: 'break-word',
                      [mq(bp.large)]: {
                        width: '100%',
                      },
                      a: {
                        color: 'cornflowerblue',
                      },
                      'ul,ol': {
                        paddingLeft: 24,
                        margin: 0,
                      },
                      p: { margin: 0 },
                    }}
                  />
                </div>
                {gigData.additional ? (
                  <div>
                    <h3>Additional Infomation</h3>
                    <div
                      dangerouslySetInnerHTML={displayEditorData('additional')}
                      css={{
                        width: '71vw',
                        margin: '0 auto',
                        overflowWrap: 'break-word',
                        [mq(bp.large)]: {
                          width: '100%',
                        },
                        a: {
                          color: 'cornflowerblue',
                        },
                        'ul,ol': {
                          paddingLeft: 24,
                          margin: 0,
                        },
                        p: { margin: 0 },
                      }}
                    />
                  </div>
                ) : null}
                {gigData.certs.length > 0 ? (
                  <div
                    css={{
                      marginBottom: 20,
                      [mq(bp.large)]: {
                        marginBottom: 0,
                      },
                    }}
                  >
                    <h2>Certifications & Licenses</h2>
                    {gigData.certs.map((cert, i) => (
                      <div key={i}>
                        <p>
                          {cert.label}
                          {cert.required ? (
                            <span css={{ color: 'red' }}> (Required)</span>
                          ) : (
                            <span css={{ color: 'red' }}> (Preferred)</span>
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              {gigData.needCerts ? (
                <div>
                  <h2>Certifications & Licenses</h2>
                  {gigData.certs.map((cert, i) => (
                    <p key={i}>- {cert}</p>
                  ))}
                </div>
              ) : null}
            </div>
            <div css={{ [mq(bp.large)]: { width: 350 } }}>
              <div
                css={{
                  marginBottom: 20,
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  id={`${gigData.gigID}-map`}
                  css={{ height: 300, width: '100%', maxWidth: 350, margin: 0 }}
                  ref={mapRef}
                />
                <div
                  css={{
                    marginBottom: 8,
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    maxWidth: 350,
                  }}
                >
                  <p css={{ marginBottom: 0 }}>
                    {gigData.location.address2
                      ? `${gigData.location.address2} - ${gigData.location.formattedAddress}`
                      : `${gigData.location.formattedAddress}`}
                  </p>
                </div>

                <a
                  href={`https://www.google.com/maps/dir//${gigData.location.formattedAddress}/@${gigData.location.lat},${gigData.location.lng},15z`}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={{ color: '#002E6D', fontWeight: 500 }}
                >
                  Get Directions to here
                </a>
              </div>
              <div
                css={{
                  marginBottom: 40,
                }}
              >
                {gigData.parking[0] && <ParkingIndicator num={1} />}
                {gigData.parking[1] && <ParkingIndicator num={2} />}
                {gigData.parking[2] && <ParkingIndicator num={3} />}
                <TransitIndicator num={gigData.transit} />
              </div>
              {hasClaimed ? (
                <div>
                  <div
                    css={{
                      textAlign: 'center',
                      marginBottom: 15,
                      [mq(bp.large)]: { marginBottom: 40 },
                    }}
                  >
                    <h2>Point of Contact</h2>
                    <p
                      css={{
                        fontWeight: 'bold',
                        fontSize: 18,
                        marginBottom: 0,
                      }}
                    >
                      {gigData.firstName} {gigData.lastName}
                    </p>
                    <p css={{ marginTop: 0 }}>{gigData.title}</p>
                    <a
                      href={`tel:${gigData.contactNumber}`}
                      css={(theme) =>
                        button(
                          theme.buttons.type_light,
                          theme.buttons.bg1,
                          theme.buttons.hover1
                        )
                      }
                    >
                      Contact {gigData.firstName}
                    </a>
                  </div>
                  <div css={{ textAlign: 'center' }}>
                    {checkinStatus > 1 ? (
                      <button
                        type="button"
                        css={(theme) => [
                          button(
                            theme.buttons.type_dark,
                            theme.buttons.bg2,
                            theme.buttons.hover2
                          ),
                          {
                            marginBottom: 20,
                          },
                        ]}
                        onClick={handleCheckOut}
                        disabled={checkinStatus !== 2 || checking}
                      >
                        {checking && checkinStatus === 2
                          ? 'Checking Out'
                          : checkoutText()}
                      </button>
                    ) : (
                      <button
                        type="button"
                        css={(theme) => [
                          button(
                            theme.buttons.type_dark,
                            theme.buttons.bg2,
                            theme.buttons.hover2
                          ),
                          {
                            marginBottom: 20,
                          },
                        ]}
                        onClick={handleCheckin}
                        disabled={
                          checkinStatus !== 0 || checking || isTimeToCheckin()
                        }
                      >
                        {checking && checkinStatus === 0
                          ? 'Checking In'
                          : checkinText()}
                      </button>
                      // </div>
                    )}
                  </div>
                  {gigData.mode === 'claim' ? (
                    <div css={{ textAlign: 'center' }}>
                      <button
                        type="button"
                        css={{
                          margin: 0,
                          border: 'none',
                          fontSize: 16,
                          color: '#D72F2F',
                          cursor: 'pointer',
                          '&:focus': { outline: 'none' },
                        }}
                        onClick={() => setClaimStep(9)}
                      >
                        Withdraw from Gig
                      </button>
                    </div>
                  ) : null}
                  <a
                    href="https://www.workbriefly.com/contact-us/"
                    target="_blank"
                    css={{
                      marginTop: 30,
                      color: '#D72F2F',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    rel="noreferrer"
                  >
                    <RedFlag css={{ marginRight: 10 }} />
                    Report an Issue
                  </a>
                </div>
              ) : (
                <div>
                  {!gigData.sameWorker && siblings.length > 0 ? (
                    <p
                      css={{
                        display: siblings.length > 0 ? 'block' : 'none',
                        color: '#393939',
                      }}
                    >
                      This gig is available on multiple days which you may apply
                      to if you are available.
                    </p>
                  ) : null}
                  {gigData.sameWorker && siblings.length > 0 ? (
                    <p css={{ color: '#393939' }}>
                      This is a multi-day gig requiring work on all the
                      following days:
                    </p>
                  ) : null}
                  <div
                    css={{
                      borderTop:
                        siblings.length > 0 ? '1px solid #979797' : 'none',
                    }}
                  >
                    <div
                      css={{
                        display: siblings.length > 0 ? 'flex' : 'none',
                        position: 'relative',
                        cursor: 'pointer',
                        alignItems: 'center',
                        input: {
                          marginRight: 8,
                        },
                        'p:not(:last-child)': {
                          marginRight: 10,
                        },
                      }}
                    >
                      <div css={customCheck}>
                        <input type="checkbox" disabled />
                        <span />
                      </div>
                      <p>{moment(gigData.day).format('MMM D')}</p>
                      <p>
                        {gigData.start} - {gigData.end}
                      </p>
                      <p>${gigData.totalForThisDay}</p>
                    </div>
                    {siblings.length > 0
                      ? siblings.map((sibling, i) => {
                          return (
                            <div key={i}>
                              <label
                                key={i}
                                css={{
                                  display: 'flex',
                                  position: 'relative',
                                  cursor: 'pointer',
                                  alignItems: 'center',
                                  input: {
                                    marginRight: 8,
                                  },
                                  'p:not(:last-child)': {
                                    marginRight: 10,
                                  },
                                }}
                              >
                                {gigData.sameWorker &&
                                !checkSiblingOverlap(sibling) &&
                                sibling.status === 1 ? (
                                  <div css={customCheck}>
                                    <input type="checkbox" disabled />
                                    <span />
                                  </div>
                                ) : null}
                                {gigData.sameWorker &&
                                (checkSiblingOverlap(sibling) ||
                                  sibling.status === 3) ? (
                                  <div
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginRight: 12,
                                    }}
                                  >
                                    <Exclamation
                                      css={{
                                        width: 24,
                                        height: 24,
                                        '&:hover + div': { display: 'block' },
                                      }}
                                    />
                                    <div
                                      className="sibToolTip"
                                      css={{
                                        position: 'absolute',
                                        display: 'none',
                                        padding: 16,
                                        left: 28,
                                        zIndex: 9,
                                      }}
                                    >
                                      <div css={tipBox}>
                                        <p css={{ margin: 0, fontSize: 12 }}>
                                          You are unable to select this gig as
                                          it either overlaps with another gig
                                          that you have already claimed or there
                                          are no more vacancies.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {!gigData.sameWorker &&
                                (checkSiblingOverlap(sibling) ||
                                  sibling.status === 3) ? (
                                  <div
                                    css={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginRight: 12,
                                    }}
                                  >
                                    <Exclamation
                                      css={{
                                        width: 24,
                                        height: 24,
                                        '&:hover + div': { display: 'block' },
                                      }}
                                    />
                                    <div
                                      className="sibToolTip"
                                      css={{
                                        position: 'absolute',
                                        display: 'none',
                                        padding: 16,
                                        left: 28,
                                        zIndex: 9,
                                      }}
                                    >
                                      <div css={tipBox}>
                                        <p css={{ margin: 0, fontSize: 12 }}>
                                          You are unable to select this gig as
                                          it either overlaps with another gig
                                          that you have already claimed or there
                                          are no more vacancies.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {userData.claimedGigs &&
                                !gigData.sameWorker &&
                                !checkSiblingOverlap(sibling) &&
                                sibling.status === 1 ? (
                                  <div css={customCheck()}>
                                    <input
                                      type="checkbox"
                                      name={sibling.gigID}
                                      id={sibling.gigID}
                                      onChange={(e) => onSiblingSelect(e)}
                                      checked={
                                        userData.claimedGigs.find(
                                          (gig) => gig.gigID === sibling.gigID
                                        )
                                          ? true
                                          : selectedSiblings.includes(
                                              sibling.gigID
                                            )
                                      }
                                      disabled={
                                        userData.claimedGigs.find(
                                          (gig) => gig.gigID === sibling.gigID
                                        ) || checkSiblingOverlap(sibling)
                                      }
                                    />
                                    <span />
                                  </div>
                                ) : null}
                                <div
                                  css={{
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {checkSiblingOverlap(sibling) ||
                                  sibling.status === 3 ? (
                                    <hr
                                      css={{
                                        position: 'absolute',
                                        borderColor: '#828282',
                                        margin: 0,
                                        width: '100%',
                                      }}
                                    />
                                  ) : null}
                                  <p>{moment(sibling.day).format('MMM D')}</p>
                                  <p>
                                    {sibling.start} - {sibling.end}
                                  </p>
                                  <p>${sibling.pay.toFixed(2)}</p>
                                </div>
                              </label>
                            </div>
                          );
                        })
                      : null}

                    <div
                      css={{
                        textAlign: 'center',
                      }}
                    >
                      {userData && !userData.setupComplete ? (
                        <button
                          type="button"
                          css={(theme) =>
                            button(
                              theme.buttons.type_dark,
                              theme.buttons.bg2,
                              theme.buttons.hover2
                            )
                          }
                          disabled
                        >
                          {/* <a href="/setup"> */}
                          Complete Onboarding
                          {/* </a> */}
                        </button>
                      ) : null}
                      {userData &&
                      userData.setupComplete &&
                      qualified &&
                      page !== 'complete' &&
                      userData.setup.govIDStatus === 2 &&
                      !userData.suspended ? (
                        <React.Fragment>
                          {(checkOverlap() && !hasClaimed) ||
                          (siblingOverlap && !hasClaimed) ? (
                            <p>This gig overlaps an already claimed gig</p>
                          ) : null}
                          {hasClaimed ? (
                            <p css={{ color: 'red' }}>
                              You've claimed this gig.
                              {/* To view details or
                              check-in, please go to your{' '}
                              <a href="/gigs/confirmed" css={{ color: 'blue' }}>
                                Confirmed gigs
                              </a>{' '}
                              list */}
                            </p>
                          ) : null}
                          <div css={{ marginBottom: 20 }}>
                            <button
                              type="button"
                              css={(theme) =>
                                button(
                                  theme.buttons.type_dark,
                                  theme.buttons.bg2,
                                  theme.buttons.hover2
                                )
                              }
                              onClick={() => setClaimStep(2)}
                              disabled={
                                hasClaimed ||
                                claiming ||
                                checkOverlap() ||
                                checkNotFull() ||
                                siblingOverlap ||
                                declined
                              }
                            >
                              {claiming ? (
                                <Ring
                                  color="#002E6D"
                                  size="22"
                                  css={{
                                    width: 22,
                                    height: 22,
                                    display: 'flex',
                                    margin: 'auto',
                                  }}
                                />
                              ) : null}
                              {gigData.mode === 'claim' &&
                              hasClaimed &&
                              !claiming
                                ? 'Claimed'
                                : null}
                              {gigData.mode === 'claim' &&
                              !hasClaimed &&
                              !claiming &&
                              page !== 'requests'
                                ? 'Claim'
                                : null}
                              {gigData.mode === 'apply' &&
                              hasClaimed &&
                              !claiming
                                ? 'Applied'
                                : null}
                              {gigData.mode === 'apply' &&
                              !hasClaimed &&
                              !claiming &&
                              page !== 'requests'
                                ? 'Apply'
                                : null}
                              {!declined &&
                              !hasClaimed &&
                              !claiming &&
                              page === 'requests'
                                ? 'Accept Request'
                                : null}
                              {declined ? 'Declined' : null}
                            </button>
                          </div>
                          {!declined &&
                          !hasClaimed &&
                          !claiming &&
                          page === 'requests' ? (
                            <div>
                              <button
                                type="button"
                                css={{
                                  border: 'none',
                                  cursor: 'pointer',
                                  color: '#13327C',
                                  fontSize: 16,
                                }}
                                onClick={() => declineRequest()}
                              >
                                Decline Request
                              </button>
                            </div>
                          ) : null}
                        </React.Fragment>
                      ) : null}
                      {userData &&
                      userData.setupComplete &&
                      !qualified &&
                      page !== 'complete' &&
                      !userData.suspended ? (
                        <p>Not Qualified</p>
                      ) : null}
                    </div>
                  </div>
                  {userData && !userData.suspended && (
                    <a
                      href="https://www.workbriefly.com/contact-us/"
                      target="_blank"
                      css={{
                        marginTop: 30,
                        color: '#D72F2F',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      rel="noreferrer"
                    >
                      <RedFlag css={{ marginRight: 10 }} />
                      Report an Issue
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div
              css={{
                margin: 'auto',
                [mq(bp.large)]: { width: '65%' },
              }}
            >
              <h3 css={{ fontSize: 24, color: '#393939' }}>
                To Accept{' '}
                {selectedSiblings.length || gigData.sameWorker > 1
                  ? 'these gigs'
                  : 'this gig'}
                , please accept the following terms:
              </h3>
              <div
                css={{
                  '&>div': {
                    marginBottom: 8,
                  },
                  input: {
                    marginRight: 8,
                  },
                }}
              >
                <div>
                  <label htmlFor="checkin" css={customCheck}>
                    <input
                      type="checkbox"
                      name="checkin"
                      id="checkin"
                      onChange={(e) => onCheckboxChange(e)}
                      checked={termsCheckboxs.checkin}
                    />
                    <span />
                    <p css={{ margin: 0 }}>
                      I will check in on time for the gig with the point of
                      contact.
                    </p>
                  </label>
                </div>
                <div>
                  <label htmlFor="noshow" css={customCheck}>
                    <input
                      type="checkbox"
                      name="noshow"
                      id="noshow"
                      onChange={(e) => onCheckboxChange(e)}
                      checked={termsCheckboxs.noshow}
                    />
                    <span />
                    <p css={{ margin: 0 }}>
                      I understand that WorkBriefly has zero tolerance for no
                      shows.
                    </p>
                  </label>
                </div>
                <div>
                  <label htmlFor="proof" css={customCheck}>
                    <input
                      type="checkbox"
                      name="proof"
                      id="proof"
                      onChange={(e) => onCheckboxChange(e)}
                      checked={termsCheckboxs.proof}
                    />
                    <span />
                    <p css={{ margin: 0 }}>
                      I understand that I will need to provide evidence of
                      licenses and certifications if requested on day of the
                      gig.
                    </p>
                  </label>
                </div>
                <div>
                  <label htmlFor="instructions" css={customCheck}>
                    <input
                      type="checkbox"
                      name="instructions"
                      id="instructions"
                      onChange={(e) => onCheckboxChange(e)}
                      checked={termsCheckboxs.instructions}
                    />
                    <span />
                    <p css={{ margin: 0 }}>
                      I understand and will abide by the instructions provided.
                    </p>
                  </label>
                </div>
                <div>
                  <label htmlFor="covid" css={customCheck}>
                    <input
                      type="checkbox"
                      name="covid"
                      id="covid"
                      onChange={(e) => onCheckboxChange(e)}
                      checked={termsCheckboxs.covid}
                    />
                    <span />
                    <p css={{ margin: 0 }}>
                      I have completed the{' '}
                      <a
                        href="https://bc.thrive.health/covid19/en"
                        target="_blank"
                        rel="noopener noreferrer"
                        css={{ color: '#002E6D' }}
                      >
                        COVID self-assessment check
                      </a>{' '}
                      and confirm that I do not present symptoms of COVID.
                    </p>
                  </label>
                </div>
              </div>
              <div
                css={[
                  buttonContainer(),
                  { justifyContent: 'flex-start!important', marginTop: 15 },
                ]}
              >
                <button
                  type="button"
                  onClick={() => {
                    setClaimStep(1);
                  }}
                  css={{
                    border: 'none',
                    color: '#002E6D',
                    fontSize: 16,
                    marginRight: 16,
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                  onClick={() =>
                    gigData.mode === 'claim'
                      ? handleClaim(
                          selectedSiblings,
                          gigData.gigID,
                          gigData.owner
                        )
                      : handleApply(
                          selectedSiblings,
                          gigData.gigID,
                          gigData.owner
                        )
                  }
                  disabled={
                    !termsCheckboxs.noshow ||
                    !termsCheckboxs.checkin ||
                    !termsCheckboxs.instructions ||
                    !termsCheckboxs.proof ||
                    !termsCheckboxs.covid ||
                    hasClaimed ||
                    claiming
                  }
                >
                  {claiming && (
                    <Ring
                      color="#002E6D"
                      size="22"
                      css={{
                        width: 22,
                        height: 22,
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  )}
                  {gigData.mode === 'claim' && hasClaimed && 'Claimed'}
                  {gigData.mode === 'claim' && !hasClaimed && 'Claim'}
                  {gigData.mode === 'apply' && hasClaimed && 'Applied'}
                  {gigData.mode === 'apply' && !hasClaimed && 'Apply'}
                </button>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div css={{ textAlign: 'center' }}>
              {claimStatus()}
              <div>
                {gigsClaimed.length === 1 ||
                (gigData.sameWorker && hasFailedClaims)
                  ? null
                  : gigsClaimed.map((gig, i) => {
                      return (
                        <div key={i}>
                          <p css={{ color: !gig.success && 'red' }}>
                            {`${gig.day} ${gig.start} - ${gig.end} - ${
                              gig.success
                                ? gigData.mode === 'claim'
                                  ? 'claimed'
                                  : 'applied'
                                : 'Unsuccessful'
                            }`}
                          </p>
                        </div>
                      );
                    })}
              </div>
              <div>
                <button
                  type="button"
                  onClick={closeButton}
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                >
                  Browse Gigs
                </button>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <div css={{ textAlign: 'center', maxWidth: 500, margin: '0 auto' }}>
              <div
                css={{
                  display: 'flex',
                  margin: '20px auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  [mq(bp.large)]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <Warning
                  css={{
                    width: 60,
                    height: 60,
                    [mq(bp.large)]: { width: 50, height: 50 },
                  }}
                />
                <h2
                  css={{
                    fontSize: 24,
                    [mq(bp.large)]: {
                      margin: '0 0 0 10px',
                      fontSize: 30,
                    },
                  }}
                >
                  You have checked in
                </h2>
              </div>
              <p>
                This still needs to be confirmed with the business. Please
                ensure that your point of contact confirms your check-in
              </p>
              <button
                type="button"
                onClick={() => setClaimStep(1)}
                css={(theme) =>
                  button(
                    theme.buttons.type_light,
                    theme.buttons.bg1,
                    theme.buttons.hover1
                  )
                }
              >
                Back to details
              </button>
            </div>
          </div>
        );
        {
          /* case 5:
        return (
          <div>
            <div css={{ textAlign: 'center', maxWidth: 500, margin: '0 auto' }}>
              <div
                css={{
                  display: 'flex',
                  margin: '20px auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  [mq(bp.large)]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <GreenCheck
                  css={{
                    width: 60,
                    height: 60,
                    [mq(bp.large)]: { width: 50, height: 50 },
                  }}
                />
                <h2
                  css={{
                    fontSize: 24,
                    margin: '0 0 0 10px',
                    [mq(bp.large)]: {
                      fontSize: 30,
                    },
                  }}
                >
                  Your Check-in is confirmed
                </h2>
              </div>
              <p>You’re all checked-in and ready to get to work!</p>
              <div>
                <button
                  type="button"
                  onClick={() => setClaimStep(6)}
                  css={(theme) => [
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    ),
                    { marginBottom: 15, [mq(bp.large)]: { marginRight: 8 } },
                  ]}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        ); */
        }
      case 6:
        return (
          <div>
            <div css={{ textAlign: 'center', maxWidth: 500, margin: '0 auto' }}>
              <div
                css={{
                  display: 'flex',
                  margin: '20px auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  [mq(bp.large)]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <h2
                  css={{
                    fontSize: 24,
                    margin: '0 0 0 10px',
                    [mq(bp.large)]: {
                      fontSize: 30,
                    },
                  }}
                >
                  Let us know when you’re done
                </h2>
              </div>
              <p>
                When you’re done please “Mark Gig as Complete” and report to the
                business’ point of contact to confirm this before leaving.
              </p>
              <div>
                <button
                  type="button"
                  onClick={() => setClaimStep(1)}
                  css={(theme) => [
                    button(
                      theme.buttons.type_light,
                      theme.buttons.bg1,
                      theme.buttons.hover1
                    ),
                    { marginBottom: 15, [mq(bp.large)]: { marginRight: 8 } },
                  ]}
                >
                  Back to Details
                </button>
                <button
                  type="button"
                  onClick={handleCheckOut}
                  css={(theme) => [
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    ),
                    { [mq(bp.large)]: { marginLeft: 8 } },
                  ]}
                >
                  Mark Gig as Complete
                </button>
              </div>
            </div>
          </div>
        );
      case 7:
        return (
          <div>
            <div css={{ textAlign: 'center', maxWidth: 500, margin: '0 auto' }}>
              <div
                css={{
                  display: 'flex',
                  margin: '20px auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  [mq(bp.large)]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <Warning
                  css={{
                    width: 60,
                    height: 60,
                    [mq(bp.large)]: { width: 50, height: 50 },
                  }}
                />
                <h2
                  css={{
                    fontSize: 24,
                    [mq(bp.large)]: {
                      margin: '0 0 0 10px',
                      fontSize: 30,
                    },
                  }}
                >
                  You have marked this gig as complete
                </h2>
              </div>
              <p>
                This still needs to be confirmed with the business. Please
                ensure that your point of contact confirms your completion of
                the gig.
              </p>
              <button
                type="button"
                onClick={() => setClaimStep(1)}
                css={(theme) =>
                  button(
                    theme.buttons.type_light,
                    theme.buttons.bg1,
                    theme.buttons.hover1
                  )
                }
              >
                Back to details
              </button>
            </div>
          </div>
        );
      case 8:
        return (
          <div>
            <div css={{ textAlign: 'center', maxWidth: 500, margin: '0 auto' }}>
              <div
                css={{
                  display: 'flex',
                  margin: '20px auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  [mq(bp.large)]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <GreenCheck
                  css={{
                    width: 60,
                    height: 60,
                    [mq(bp.large)]: { width: 50, height: 50 },
                  }}
                />
                <h2
                  css={{
                    fontSize: 24,
                    margin: '0 0 0 10px',
                    [mq(bp.large)]: {
                      fontSize: 30,
                    },
                  }}
                >
                  Awesome! Your gig is complete
                </h2>
              </div>
              <p>{`Great job ${userData.firstName}! You've completed your shift. Let's find you another gig to start earning more!`}</p>
              <div>
                <button
                  type="button"
                  css={(theme) => [
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    ),
                    { marginRight: 20 },
                  ]}
                  onClick={viewDetails}
                >
                  View Details
                </button>
                {/* <a
                  href="/gigs"
                  css={(theme) =>
                    button(
                      theme.buttons.type_light,
                      theme.buttons.bg1,
                      theme.buttons.hover1
                    )
                  }
                >
                  Browse Gigs
                </a> */}
              </div>
            </div>
          </div>
        );
      case 9:
        return (
          <div>
            <div css={{ textAlign: 'center', margin: '0 auto' }}>
              <div
                css={{
                  display: 'flex',
                  margin: '20px auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  [mq(bp.large)]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <Warning
                  css={{
                    width: 60,
                    height: 60,
                    [mq(bp.large)]: { width: 50, height: 50 },
                  }}
                />
                <h2
                  css={{
                    fontSize: 24,
                    margin: '0 0 0 10px',
                    [mq(bp.large)]: {
                      fontSize: 30,
                    },
                  }}
                >
                  Are you sure you want to withdraw from the gig?
                </h2>
              </div>
              <div css={{ maxWidth: 500, margin: '0 auto' }}>
                <p>
                  Please note that withdrawals made within 36 hours before a gig
                  is to begin, will lead to a two week temporary account
                  suspension.
                </p>
                <div
                  css={[
                    buttonContainer(),
                    { justifyContent: 'center!important', marginTop: 15 },
                  ]}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setClaimStep(1);
                    }}
                    css={{
                      border: 'none',
                      color: '#002E6D',
                      fontSize: 16,
                      marginRight: 16,
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    css={(theme) =>
                      button(
                        theme.buttons.type_light,
                        theme.buttons.bg1,
                        theme.buttons.hover1
                      )
                    }
                    onClick={withdrawGig}
                  >
                    Confirm Withdrawal
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 10:
        return (
          <div>
            <div css={{ textAlign: 'center' }}>
              <div
                css={{
                  display: 'flex',
                  margin: '20px auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  [mq(bp.large)]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <GreenCheck
                  css={{
                    width: 60,
                    height: 60,
                    [mq(bp.large)]: { width: 50, height: 50 },
                  }}
                />
                <h2
                  css={{
                    fontSize: 24,
                    margin: '0 0 0 10px',
                    [mq(bp.large)]: {
                      fontSize: 30,
                    },
                  }}
                >
                  You have successfully withdrawn from the gig
                </h2>
              </div>
              <div css={{ maxWidth: 500, margin: '0 auto' }}>
                <p>
                  Thanks for withdrawing your application in time to prevent
                  incurring penalties. Let’s find you another gig.
                </p>
                {/* <a
                  href="/gigs"
                  css={(theme) =>
                    button(
                      theme.buttons.type_dark,
                      theme.buttons.bg2,
                      theme.buttons.hover2
                    )
                  }
                >
                  Browse Gigs
                </a> */}
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div>
      <h2>{gigData.day}</h2>
      <div>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '64px auto',
            boxShadow: '1px 2px 9px rgba(0, 0, 0, 0.5)',
            zIndex: '1',
            borderRadius: 3,
            overflow: 'hidden',
            [mq(bp.large)]: {
              minHeight: 150,
              gridTemplateColumns: '160px auto',
            },
          }}
        >
          <div
            css={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              margin: '0 auto',
              overflow: 'hidden',
              position: 'relative',
              background: '#fff',
            })}
          >
            <img
              src={gigData.profileImage}
              alt=""
              css={{
                position: 'absolute',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: 3,
                height: 50,
                [mq(bp.large)]: {
                  height: 120,
                },
              }}
            />
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#fff',
              padding: 5,
              minHeight: 75,
              [mq(bp.large)]: {
                padding: 16,
              },
              '&>div:not(:last-child)': {
                [mq(bp.large)]: {
                  marginRight: 20,
                },
              },
            }}
            onClick={onExpand}
          >
            <div
              css={{
                flex: 1,
                position: 'relative',
                [mq(bp.large)]: { dipsplay: 'block' },
              }}
            >
              {(gigData.mode === 'claim' && hasClaimed && page === 'active') ||
              (gigData.mode === 'claim' &&
                hasClaimed &&
                page === 'requests') ? (
                <span css={{ color: 'red' }}>Claimed by you</span>
              ) : null}
              {gigData.mode === 'apply' && hasClaimed && page === 'active' ? (
                <span css={{ color: 'red' }}>Applied</span>
              ) : null}
              <h2
                css={{
                  marginTop: 0,
                  marginBottom: 2,
                  fontSize: 20,
                  width: '44vw',
                  overflowWrap: 'break-word',
                  overflow: 'hidden !important',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  lineHeight: 1.1,
                  fontFamily: 'Lato',
                  [mq(bp.large)]: {
                    maxWidth: 280,
                    width: '100%',
                    marginBottom: '20px',
                    fontSize: 24,
                    fontWeight: 'bold',
                  },
                }}
              >
                {gigData.position}
              </h2>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  [mq(bp.large)]: {
                    flexDirection: 'row',
                  },
                  p: {
                    margin: 0,
                    fontSize: 14,
                    color: '#000',
                    display: 'none',
                    [mq(bp.large)]: {
                      display: 'block',
                    },
                  },
                  h5: {
                    fontFamily: 'Lato',
                    [mq(bp.large)]: {
                      fontWeight: 600,
                    },
                  },
                  div: {
                    [mq(bp.large)]: {
                      marginRight: 40,
                    },
                  },
                }}
              >
                <div>
                  <p>Company</p>
                  <h5
                    css={{
                      marginTop: 0,
                      marginBottom: 2,
                      fontSize: 14,
                      width: '50vw',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden !important',
                      textOverflow: 'ellipsis',
                      [mq(bp.large)]: {
                        maxWidth: '200px',
                        width: 'auto',
                        fontSize: 18,
                      },
                    }}
                  >
                    {gigData.businessName}
                  </h5>
                </div>
                <div>
                  <p>Time</p>
                  <h5
                    css={{
                      fontSize: 14,
                      margin: 0,
                      color: '#8A8A8A',
                      [mq(bp.large)]: {
                        color: '#272727',
                        fontSize: 18,
                      },
                    }}
                  >
                    {`${gigData.start} - ${gigData.end} `}
                  </h5>
                  {gigData.breakTime ? (
                    <span css={{ fontSize: 12 }}>
                      {gigData.breakTime}min{' '}
                      {gigData.paidBreak ? 'paid' : 'unpaid'} break
                    </span>
                  ) : (
                    <span css={{ fontSize: 12 }}>No Breaks</span>
                  )}
                </div>
                <div
                  css={{
                    display: 'none',
                    [mq(bp.large)]: {
                      display: 'block',
                    },
                  }}
                >
                  <p>Pay</p>
                  <h5
                    css={{
                      fontSize: 16,
                      margin: 0,
                      [mq(bp.large)]: {
                        fontSize: 18,
                      },
                    }}
                  >{`${'$'}${gigData.totalForThisDay} (${'$'}${
                    gigData.payPerHour
                  }/hr)`}</h5>
                </div>
              </div>
              <h5
                css={{
                  position: 'absolute',
                  right: 0,
                  top: 7,
                  margin: '0',
                  fontSize: 20,
                  textAlign: 'center',
                  lineHeight: 0.8,
                  [mq(bp.large)]: {
                    display: 'none',
                  },
                }}
              >
                {`${'$'}${gigData.totalForThisDay}`} <br />
                <span css={{ color: '#8A8A8A', fontSize: 12 }}>{`(${'$'}${
                  gigData.payPerHour
                }/hr)`}</span>
              </h5>
            </div>
            {/* {saved && (page === 'active' || page === 'saved') ? (
              <button
                css={{
                  border: 'none',
                  width: 'fit-content',
                }}
                onClick={unsaveGig}
              >
                <Saved
                  css={{
                    height: 'auto',
                    margin: 16,
                    cursor: 'pointer',
                    width: 20,
                    [mq(bp.large)]: {
                      width: 24,
                    },
                  }}
                />
              </button>
            ) : null}
            {!saved && (page === 'active' || page === 'saved') ? (
              <button
                css={{
                  border: 'none',
                  width: 'fit-content',
                }}
                onClick={saveGig}
              >
                <Save
                  css={{
                    height: 'auto',
                    margin: 16,
                    cursor: 'pointer',
                    width: 20,
                    [mq(bp.large)]: {
                      width: 24,
                    },
                  }}
                />
              </button>
            ) : null} */}
            <div
              css={{
                [mq(bp.large)]: {
                  marginRight: 20,
                },
              }}
            >
              <button
                type="button"
                css={(theme) => [
                  button(
                    theme.buttons.type_light,
                    theme.buttons.bg1,
                    theme.buttons.hover1
                  ),
                  {
                    width: '100%',
                    maxWidth: 200,
                    fontSize: 14,
                    display: 'none',
                    [mq(bp.large)]: {
                      display: 'block',
                    },
                  },
                ]}
                onClick={onExpand}
              >
                {expanded ? 'Hide' : 'View'} Details
              </button>
            </div>
            <div
              css={{
                width: 24,
                display: 'none',
                [mq(bp.large)]: {
                  display: 'block',
                },
              }}
              onClick={onExpand}
            >
              <Arrow
                css={{
                  transform: expanded ? 'scale(1, -1)' : null,
                  transition: '.25s ease',
                }}
              />
            </div>
          </div>
          {gigData.gigReferenceNumber && (
            <div css={{ textAlign: 'right' }}>
              <span
                css={{
                  fontSize: 10,
                  color: 'grey',
                  fontWeight: 'bold',
                  '&:hover': { color: '#000' },
                }}
              >
                Gig Ref #: {gigData.gigReferenceNumber}
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        css={{
          padding: '20px 20px 40px',
          boxShadow:
            '1px 2px 9px rgba(0, 0, 0, 0.5), 0 9px 9px -9px rgba(0, 0, 0, 0.5) inset',
          backgroundColor: '#fff',
          display: expanded ? 'block' : 'none',
          [mq(bp.small)]: {
            padding: '20px 40px 40px',
          },
        }}
      >
        {expandedComponents()}
      </div>
    </div>
  );
};

export default withFirebase(GigItem);
