/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { useFormContext, Controller } from 'react-hook-form';

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
const getSuggestions = (value, list) => {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(escapedValue, 'i');

  return list.filter((item) => regex.test(item.label));
};

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => (
  <p
    css={{
      borderBottom: '1px solid #979797',
      margin: 0,
      padding: '8px 0',
    }}
  >
    {suggestion.label}
  </p>
);

const AutoFillSearch = ({
  name,
  placeholder,
  label,
  list,
  disabled,
  rules,
  defaultValue,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [item, setItem] = useState({
    value: '',
    code: '',
    label: '',
    equivalent: [],
  });
  const {
    control,
    setValue,
    errors,
    clearErrors,
    setError,
    isSubmitted,
    reset,
  } = useFormContext();
  useEffect(() => {
    if (isSubmitted && !Object.keys(errors).length) {
      return setItem({ name: '', code: '' });
    }
  }, [isSubmitted, reset, errors]);
  const onCertChange = (event, newValue) => {
    let newCert = {};
    if (!newValue) {
      setError(name, 'required', '');
    }
    if (newValue.label && newValue.code) {
      clearErrors(name);
      newCert = {
        label: newValue.label,
        value: newValue.value,
        code: newValue.code,
        equivalent: newValue.equivalent,
      };
    }
    if (newValue && !newValue.label) {
      clearErrors(name);
      newCert = {
        label: newValue,
        value: '',
        code: '',
        equivalent: [],
      };
    }

    setValue(name, newCert);
    setItem(newCert);
  };
  const inputProps = {
    placeholder,
    value: item.label || '',
    onChange: (e, { newValue }) => onCertChange(e, newValue),
    disabled: disabled || false,
  };

  const onSuggestionsFetch = ({ value }) => {
    setSuggestions(getSuggestions(value, list));
  };

  const onSuggestionsClear = () => {
    setSuggestions([]);
  };
  const renderSectionTitle = () => {
    return null;
  };
  const getSectionSuggestions = () => {
    return null;
  };
  return (
    <div
      css={{
        width: '100%',
      }}
    >
      {label ? <label>{label}</label> : null}
      <Controller
        as={Autosuggest}
        name={name}
        control={control}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetch}
        onSuggestionsClearRequested={onSuggestionsClear}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        multiSection={false}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        rules={rules}
        theme={{
          container: {
            marginBottom: 0,
            position: 'relative',
            cursor: 'pointer',
          },
          input: {
            width: '100%',
            padding: 13,
            display: 'block',
            borderRadius: '4px',
            backgroundColor: 'rgba(58, 177, 202, 0.1)',
            fontSize: 14,
            border: '1px solid transparent',
            borderColor: errors[name] ? 'red' : 'transparent',
          },
          suggestionsContainer: {
            position: 'absolute',
            width: '100%',
            background: '#FFFFFF',
            zIndex: 99,
          },
          suggestionsList: {
            listStyle: 'none',
            padding: '4px 0',
            marginTop: 8,
            boxShadow:
              '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)',
            borderRadius: '4px',
            maxHeight: 300,
            overflowY: 'auto',
            fontSize: 14,
          },
          suggestion: {
            padding: '0 12px',
            fontSize: 14,
          },

          suggestionHighlighted: {
            background: '#DEEBFF',
          },
        }}
      />
      {/* <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetch}
        onSuggestionsClearRequested={onSuggestionsClear}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        multiSection={false}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        theme={{
          container: { marginBottom: 0, position: 'relative' },
          input: {
            width: '100%',
            padding: 12,
            display: 'block',
            border: 'none',
            borderRadius:  '4px',
            backgroundColor: 'rgba(58, 177, 202, 0.1)',
            fontSize: 16,
          },
          suggestionsContainer: {
            position: 'absolute',
            width: '100%',
            background: '#FFFFFF',
            zIndex: 99,
          },
          suggestionsList: {
            listStyle: 'none',
            padding: '4px 0',
            marginTop: 8,
            boxShadow:
              '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)',
            borderRadius:  '4px',
            maxHeight: 300,
            overflowY: 'auto',
            fontSize: 14,
          },
          suggestion: {
            fontSize: 16,
            padding: '0 12px',
          },

          suggestionHighlighted: {
            background: '#DEEBFF',
          },
        }}
      /> */}
    </div>
  );
};

export default AutoFillSearch;
