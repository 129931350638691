/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import { withFirebase } from 'library/Firebase';
import { useParams } from 'react-router-dom';
import moment from 'moment';
// import { Ring } from 'react-spinners-css';
import { bp, mq } from 'constants/mediaQueries';
import { settingTitle, settingContent } from 'theme/styleBlocks';

const CompletedGigs = ({ completedGigs, firebase }) => {
  const [gigs, setGigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const businessSnapshot = firebase.businessSnapshot(id);
    businessSnapshot.get().then(() => {
      firebase
        .getGigs(completedGigs, 'active')
        .then(async (gigs) => {
          const sortedGigs = await gigs.sort((a, b) => {
            return (
              moment(`${b.start} ${b.day}`, 'h:mm A MMM D, YYYY').toDate() -
              moment(`${a.start} ${a.day}`, 'h:mm A MMM D, YYYY').toDate()
            );
          });

          return sortedGigs;
        })
        .then((data) => {
          setGigs(data);
          setLoading(false);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedGigs]);

  return (
    <div
      css={() => ({
        padding: 30,
        //   margin: '0px auto 65px',
        //   [mq(bp.small)]: {
        //     width: '90vw',
        //   },
        //   [mq(bp.large)]: {
        //     // maxWidth: theme.layout.max_width + 10,
        //     // padding: '60px 20px 60px 60px',
        //     margin: 0,
        //     width: '100%',
        //   },
      })}
    >
      {loading ? (
        <div css={{ textAlign: 'center' }}>
          loading
          {/* <Ring color="#002E6D" size="50" css={{ width: 50 }} /> */}
        </div>
      ) : (
        <div
          css={{
            gridTemplateColumns: '2fr 2fr',
            [mq(bp.large)]: {
              display: 'grid',
              gridTemplateColumns: '1fr 2fr',
            },
          }}
        >
          <div css={{ justifySelf: 'start' }}>
            <h2 css={settingTitle}>Completed Gigs</h2>
          </div>
          {gigs.length !== 0 ? (
            <div css={settingContent}>
              <div
                css={{
                  [mq(bp.large)]: {
                    border: '1px solid #979797',
                    borderRadius: '4px',
                    padding: 20,
                    backgroundColor: '#fff',
                    '&>div:not(:first-of-type):not(:last-of-type)': {
                      borderBottom: '1px solid #EFEFEF',
                      paddingBottom: 16,
                    },
                    '&>div:last-of-type': {
                      marginBottom: 0,
                    },
                  },
                }}
              >
                <div
                  css={{
                    borderBottom: '1px solid #979797',
                    display: 'none',
                    gridTemplateColumns: '3fr 1.5fr 2fr 2fr',
                    gridColumnGap: 20,
                    marginBottom: 20,
                    p: {
                      margin: '0 0 10px 0',
                      fontSize: 14,
                      fontWeight: 600,
                    },
                    [mq(bp.large)]: {
                      display: 'grid',
                      '&>p:not(:first-of-type)': {
                        textAlign: 'right',
                      },
                    },
                  }}
                >
                  <p>When</p>
                  <p>Company</p>
                  <p>Position</p>
                  <p>Total Cost</p>
                  {/* <p>Actions</p> */}
                </div>
                {gigs.map((gig, i) => (
                  <div
                    key={i}
                    css={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(7, 1fr)',
                      marginBottom: 10,
                      backgroundColor: '#fff',
                      border: '1px solid #ECECEC',
                      position: 'relative',
                      borderRadius: '4px',
                      padding: 15,
                      gridAutoFlow: 'column',
                      [mq(bp.large)]: {
                        marginBottom: 20,
                        gridColumnGap: 20,
                        border: 'none',
                        padding: 0,
                        // gridTemplateColumns: '1fr 1.25fr 2fr 2fr 2fr 2.25fr 2.5fr',
                        gridTemplateColumns: '3fr 1.5fr 2fr 2fr',
                        '&>div:not(:first-of-type)': {
                          textAlign: 'right',
                        },
                      },
                    }}
                  >
                    <div
                      css={{
                        order: 1,
                        gridColumn: '1/7',
                        fontWeight: 400,
                        marginBottom: 10,
                        [mq(bp.large)]: {
                          marginBottom: 0,
                          gridColumn: 'unset',
                        },
                      }}
                    >
                      <p css={{ margin: 0 }}>
                        {gig.day}
                        <span css={{ marginLeft: 5 }}>
                          {gig.start} - {gig.end}
                        </span>
                      </p>
                    </div>
                    <div
                      css={{
                        order: 1,
                        gridColumn: '1/7',
                        fontWeight: 400,
                        marginBottom: 10,
                        [mq(bp.large)]: {
                          marginBottom: 0,
                          gridColumn: 'unset',
                        },
                      }}
                    >
                      <p css={{ margin: 0 }}>{gig.businessName}</p>
                    </div>
                    <div
                      css={{
                        order: 2,
                        gridColumn: '1/4',
                        marginBottom: 10,
                        [mq(bp.large)]: {
                          marginBottom: 0,
                          gridColumn: 'unset',
                          order: 3,
                        },
                      }}
                    >
                      <p
                        css={{
                          margin: 0,
                          fontSize: 20,
                          fontWeight: 800,
                          width: '55vw',
                          color: '#272727',
                          [mq(bp.large)]: {
                            color: 'unset',
                            fontSize: 16,
                            fontWeight: 400,
                            width: 140,
                            marginLeft: 'auto',
                          },
                        }}
                      >
                        {gig.position}
                      </p>
                    </div>
                    <div
                      css={{
                        order: 4,
                        gridColumn: '3/5',
                        display: 'flex',
                        alignItems: 'center',
                        [mq(bp.large)]: {
                          gridColumn: 'unset',
                          display: 'block',
                        },
                      }}
                    >
                      <p css={{ margin: '0 5px 0 0', fontWeight: 600 }}>
                        ${gig.totalForThisDay.toFixed(2)}{' '}
                      </p>
                      <p
                        css={{
                          margin: 0,
                          fontSize: 12,
                          [mq(bp.large)]: {
                            fontSize: 14,
                          },
                        }}
                      >
                        (${gig.payPerHour}/hr)
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p>No completed gigs</p>
          )}
        </div>
      )}
    </div>
  );
};

export default withFirebase(CompletedGigs);
