/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import { withFirebase } from 'library/Firebase';
import { bp, mq } from 'constants/mediaQueries';
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import qs from 'query-string';
import { formInput, button } from 'theme/styleBlocks';
import GigCard from './GigCard';
// import { bp, mq } from 'constants/mediaQueries';
const CreateGig = ({ firebase }) => {
  const [searchBy, setSearchBy] = useState('name');
  const [search, setSearch] = useState('');
  const [type, setType] = useState('businesses');
  const [id, setId] = useState('');
  const [users, setUsers] = useState([]);
  // const [edit, setEdit] = useState(false);
  const [searching, setSearching] = useState(false);
  const [noneFound, setNoneFound] = useState(false);
  const location = useLocation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const parsed = qs.parse(location.search);

  useEffect(() => {
    if (
      parsed.search &&
      (parsed.searchBy === 'name' ||
        parsed.searchBy === 'id' ||
        parsed.searchBy === 'email')
    ) {
      firebase.searchUser(type, parsed.searchBy, parsed.search).then((data) => {
        setUsers(data);
        setSearching(false);
        if (data.length <= 0) {
          setNoneFound(true);
        }
      });
    }
  }, [firebase, parsed.search, parsed.searchBy, type]);
  useEffect(() => {
    if (
      parsed.searchBy === 'name' ||
      parsed.searchBy === 'id' ||
      parsed.searchBy === 'email'
    ) {
      setSearchBy(parsed.searchBy);
    }
    setSearch(parsed.search || '');
  }, [parsed.search, parsed.searchBy, type]);
  const searchUser = () => {
    setSearching(true);
    setNoneFound(false);
    history.push(`${path}?searchBy=${searchBy}&search=${search}`);
    // setSearchQuery([type, searchBy, search]);
    // return firebase.searchUser(type, searchBy, search).then((data) => {
    //   setUsers(data);
    //   setSearching(false);
    //   if (data.length <= 0) {
    //     setNoneFound(true);
    //   }
    // });
  };
  const selectUser = (userId) => {
    setId(userId);
    history.push(`${path}/${userId}`);
  };
  // const onEdit = () => {
  //   setEdit(true);
  //   if (edit === true) {
  //     setEdit(false);
  //   }
  // };
  return (
    <Switch>
      <Route exact path={path}>
        <div
          css={{
            margin: '0 auto',
            padding: '0 20px',
            marginTop: 50,
            [mq(bp.large)]: {
              padding: 0,
              marginTop: 90,
              maxWidth: 1020,
            },
          }}
        >
          <h3>Search By</h3>
          <div
            css={{
              display: 'flex',
              marginBottom: 20,
              '&>div': { marginRight: 20 },
            }}
          >
            <div>
              <label htmlFor="searchByName">Name (First Name)</label>
              <input
                type="radio"
                name="searchBy"
                id="searchByName"
                value="name"
                checked={searchBy === 'name'}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="searchByID">ID</label>
              <input
                type="radio"
                name="searchBy"
                id="searchByID"
                value="id"
                checked={searchBy === 'id'}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="searchByEmail">Email</label>
              <input
                type="radio"
                name="searchBy"
                id="searchByEmail"
                value="email"
                checked={searchBy === 'email'}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div>
          </div>
          <div
            css={{
              marginTop: 50,
              [mq(bp.large)]: {
                display: 'grid',
                gridTemplateColumns: '2fr 1fr',
                columnGap: '40px',
                alignItems: 'center',
              },
            }}
          >
            <div css={[formInput, { marginBottom: '0' }]}>
              <input
                type="text"
                name="search"
                id="search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
            <button
              type="button"
              onClick={searchUser}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              Search
            </button>
          </div>
          {searching ? <p>Searching...</p> : null}
          {!searching && noneFound ? <p>No Users Found</p> : null}
          {!searching && !noneFound && users.length > 0 ? (
            <div>
              {users.map((user) => (
                <div
                  css={{
                    marginTop: 50,
                    [mq(bp.large)]: {
                      display: 'grid',
                      gridTemplateColumns: '2fr 1fr',
                      columnGap: '40px',
                      alignItems: 'end',
                    },
                  }}
                >
                  <div>
                    <p>
                      Name: {user.firstName} {user.lastName}
                    </p>
                    <p>Email: {user.email}</p>
                    <p>ID: {user.uid}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => selectUser(user.uid)}
                    css={(theme) =>
                      button(
                        theme.buttons.type_dark,
                        theme.buttons.bg2,
                        theme.buttons.hover2
                      )
                    }
                  >
                    Create a Gig
                  </button>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </Route>
      <Route path={`${path}/:id`} render={() => <GigCard id={id} />} />
    </Switch>
  );
};

export default withFirebase(CreateGig);
