/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState } from "react";
import { withFirebase } from "library/Firebase";
import moment from "moment";

const WorkerItem = ({ worker, gigID, firebase, method, payPerHour }) => {
  const [loading, setLoading] = useState(false);
  const settleWorker = (e) => {
    setLoading(true);
    let settled;
    if (e.target.value === "true") settled = true;
    if (e.target.value === "false") settled = false;

    firebase
      .settleWorker(settled, gigID, worker.uid)
      .then(() => setLoading(false));
  };
  return (
    <div
      css={{
        display: "flex",
        backgroundColor: worker.hasBeenPaid ? "#90ff80" : "#ff8080",
        p: { marginRight: 20 },
      }}
    >
      <p>
        Name: {worker.firstName} {worker.lastName}
      </p>
      <p>Email: {worker.email}</p>
      {method === "hours" ? (
        <React.Fragment>
          <p css={{ fontWeight: "bold" }}>Hours Worked: {worker.hoursLogged}</p>
          <p css={{ fontWeight: "bold" }}>
            Payout: $
            {(Math.round(payPerHour * worker.hoursLogged * 100) / 100).toFixed(
              2
            )}
          </p>
        </React.Fragment>
      ) : (
        <p css={{ fontWeight: "bold" }}>Payout: ${worker.amountToBePaid}</p>
      )}
      <p>
        Paid:{" "}
        <select
          name="workerPaid"
          id="workerPaid"
          onChange={settleWorker}
          value={worker.hasBeenPaid || false}
          disabled={loading}
        >
          <option value>Yes</option>
          <option value={false}>No</option>
        </select>
      </p>
    </div>
  );
};

const PayoutItem = ({ gigData, firebase, archived }) => {
  const [loading, setLoading] = useState(false);
  const archiveGig = () => {
    firebase.archiveGig(gigData.gigID, gigData.gigSummaryGroup);
  };
  const unarchiveGig = () => {
    firebase.unarchiveGig(gigData.gigID, gigData.gigSummaryGroup);
  };

  const calculateAmount = (bookedWorkers) => {
    if (gigData.checkoutMethod === "hours") {
      return bookedWorkers.reduce((acc, worker) => {
        const calcFees =
          Math.round(gigData.payPerHour * worker.hoursLogged * 0.2 * 100) / 100;
        const calcTax =
          Math.round(
            (gigData.payPerHour * worker.hoursLogged + calcFees) * 0.05 * 100
          ) / 100;
        return (
          acc +
            Math.round(
              (gigData.payPerHour * worker.hoursLogged + calcFees + calcTax) *
                100
            ) /
              100 || 0
        );
      }, 0);
    }
    return bookedWorkers.reduce((acc, worker) => {
      const calcFees = Math.round(worker.amountToBePaid * 0.2 * 100) / 100;
      const calcTax =
        Math.round((worker.amountToBePaid + calcFees) * 0.05 * 100) / 100;

      return (
        acc +
          Math.round((worker.amountToBePaid + calcFees + calcTax) * 100) /
            100 || 0
      );
    }, 0);
  };

  const settleBusiness = (e) => {
    setLoading(true);
    let settled;
    if (e.target.value === "true") settled = true;
    if (e.target.value === "false") settled = false;

    firebase
      .settleBusiness(settled, gigData.gigID)
      .then(() => setLoading(false));
  };

  return gigData ? (
    <div
      css={{
        marginBottom: 20,
        border: "1px solid",
        borderColor: gigData.hasBusinessPaid ? "green" : "red",
        backgroundColor: gigData.hasBusinessPaid ? "#c7ffbf" : "#ffbfbf",
      }}
    >
      <p>Position: {gigData.position}</p>
      <p>{gigData.businessName}</p>
      <p>
        Completed Date:{" "}
        {moment.unix(gigData.endTimeStamp.seconds).format("YYYY MMMM DD")}
      </p>
      <p>ID: {gigData.gigID}</p>
      <p>
        Time: {gigData.start} - {gigData.end} {gigData.nextDay && "(Next Day)"}
      </p>
      <p>Wage: ${gigData.payPerHour}/h</p>
      <p css={{ fontWeight: "bold" }}>
        Checkout Method: {gigData.checkoutMethod}
      </p>
      {gigData.sameWorker && (
        <p css={{ fontWeight: "bold", color: "red" }}>MDR GIG</p>
      )}
      {gigData.lastGigOfTheWeek || gigData.lastGigInMDR ? (
        <React.Fragment>
          <p>Last gig of the week</p>
          <p>Gig IDs in set:</p>
          {gigData.gigSummaryGroup.length > 0 &&
            gigData.gigSummaryGroup.map((gigID) => <p>{gigID}</p>)}
        </React.Fragment>
      ) : null}
      <hr />
      <div>
        <p css={{ fontWeight: "bold" }}>Worker breakdown</p>
        {gigData.bookedWorkers.map((worker) => (
          <WorkerItem
            worker={worker}
            gigID={gigData.gigID}
            firebase={firebase}
            method={gigData.checkoutMethod}
            payPerHour={gigData.payPerHour}
          />
        ))}
      </div>
      <hr />
      <div>
        <p css={{ fontWeight: "bold" }}>
          Type: {gigData.invoicingOption ? "Invoice" : "Credit Card"}
        </p>
        {gigData.invoicingOption ? (
          <div>
            <p>Amount to Invoice: ${calculateAmount(gigData.bookedWorkers)}</p>
            <p>
              Settled:{" "}
              <select
                name="settled"
                id="settled"
                onChange={settleBusiness}
                value={gigData.hasBusinessPaid || false}
                disabled={loading}
              >
                <option value>Yes</option>
                <option value={false}>No</option>
              </select>
            </p>
          </div>
        ) : null}
      </div>
      <div>
        <button
          type="button"
          onClick={archived ? unarchiveGig : archiveGig}
          disabled={
            !gigData.haveWorkersBeenPaid ||
            !gigData.hasBusinessPaid ||
            (!archived && !gigData.haveWorkersBeenPaid)
          }
        >
          {archived ? "Unarchive" : "Move to Archive"}
        </button>
      </div>
    </div>
  ) : null;
};

export default withFirebase(PayoutItem);
