/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { withFirebase } from 'library/Firebase';
import { useParams } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { BodyScrollOptions } from 'body-scroll-lock';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import {
  editButton,
  formInput,
  button,
  goBackButton,
  settingTitle,
  settingContent,
} from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';

const EditPhoneNumber = ({ phoneNumber, firebase }) => {
  const [edit, setEdit] = useState(false);
  const [channel, setChannel] = useState('');
  const [verify, setVerify] = useState(false);
  const [textLoading, setTextLoading] = useState(false);
  const [callLoading, setCallLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formattedPhoneNum, setFormattedPhoneNum] = useState('');
  const modalEl = useRef(null);
  const {
    handleSubmit,
    register,
    getValues,
    // formState: { isSubmitted },
    // reset,
    errors,
    control,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    reValidateMode: 'onSubmit',
  });
  const { id, type } = useParams();
  useEffect(() => {
    const phoneNum = parsePhoneNumberFromString(`+${phoneNumber}`);
    const nationalNum = phoneNum.formatNational();
    setFormattedPhoneNum(nationalNum);
  }, []);
  useEffect(() => {
    if (edit) {
      setValue('phoneNumber', phoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);
  const onEdit = () => {
    setEdit(true);
    if (edit === true) {
      setEdit(false);
    }
  };
  const onCancel = () => {
    setEdit(false);
  };
  const onSave = () => {
    const phoneNum = getValues('phoneNumber');
    setLoading(true);
    firebase.updatePhoneNumber(phoneNum, type, id).then(() => {
      setEdit(false);
      setVerify(false);
      enableBodyScroll(modalEl.current);
    });
  };
  const verifyCode = (code) => {
    const phoneNum = getValues('phoneNumber');
    firebase.twilioCheckVerification(`+${phoneNum}`, code).then((result) => {
      if (result.phoneVerified) {
        return true;
      }
      if (!result.phoneVerified) {
        return result.message;
      }
      setTextLoading(false);
      setCallLoading(false);
    });

    setVerify(false);
  };
  const handleTwilioCreate = (channelType) => {
    clearErrors();
    if (channelType === 'call') {
      setCallLoading(true);
    } else {
      setTextLoading(true);
    }
    const phoneNum = getValues('phoneNumber');
    setChannel(channelType);
    firebase
      .twilioCreateVerification(channelType, `+${phoneNum}`)
      .then(() => {
        const options: BodyScrollOptions = {
          reserveScrollBarGap: true,
        };
        setVerify(true);
        disableBodyScroll(modalEl.current, options);
        setCallLoading(false);
        setTextLoading(false);
      })
      .catch((error) => {
        setError('phoneNumber', '', error.message);
        setCallLoading(false);
        setTextLoading(false);
      });
  };

  return (
    <div
      css={{
        backgroundColor: edit && '#F8F8F8',
        padding: 30,
        position: 'relative',
      }}
    >
      <div
        css={{
          gridTemplateColumns: '2fr 2fr',
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
          },
        }}
      >
        <div
          ref={modalEl}
          css={{
            position: 'fixed',
            top: 0,
            left: 0,
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0,0,0,0.5)',
            height: '100vh',
            width: '100vw',
            zIndex: '99',
            display: verify ? 'flex' : 'none',
          }}
        >
          <div
            css={{
              background: '#fff',
              border: '1px solid ',
              padding: 20,
            }}
          >
            <div css={formInput}>
              <label htmlFor="code">Verification Code</label>
              <input
                type="text"
                name="code"
                id="code"
                ref={register({
                  required: true,
                  validate: (code) => verifyCode(code),
                })}
              />
              <FormFieldErrorMessage error={errors.code} />
            </div>
            <button
              type="button"
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
              onClick={handleSubmit(onSave)}
            >
              {loading ? 'Verifing' : 'Verify'}
            </button>
          </div>
        </div>
        <div css={{ justifySelf: 'start' }}>
          <h2 css={settingTitle}>Phone Number</h2>
        </div>
        <div>
          {edit ? (
            <div>
              <div css={{ marginTop: 50 }}>
                <p
                  css={{
                    fontWeight: 500,
                    fontSize: 14,
                    marginTop: 0,
                    color: '#6A6A69',
                    [mq(bp.small)]: {
                      fontSize: 18,
                    },
                  }}
                >
                  Please enter your phone number so that we can send you a
                  one-time verification code in order to confirm it’s really
                  you.
                </p>
                <div
                  css={{
                    marginBottom: 20,
                    '.form-control': {
                      width: '100%',
                      backgroundColor: 'rgba(58, 177, 202, 0.1)',
                      border: '1px solid transparent',
                      borderColor: errors.phoneNumber && 'red !important',
                      padding: '12px 0 12px 50px',
                      display: 'block',
                      borderRadius: '4px',
                      fontSize: 14,
                      outline: 'none',
                      height: 'auto',
                      '&:focus': {
                        border: '1px solid #002E6D',
                      },
                    },
                    '.flag-dropdown': {
                      border: 'none',
                      backgroundColor: 'unset',
                      '&:hover .selected-flag': {
                        background: 'rgba(58, 177, 202, 0.1)',
                      },
                      '&.open .selected-flag': {
                        background: 'rgba(58, 177, 202, 0.1)',
                      },
                    },
                    '.selected-flag': {
                      background: 'none',
                      border: 'none',
                      padding: '0 0 0 11px',
                      width: '46px',
                      '&:focus': {
                        background: 'rgba(58, 177, 202, 0.1)',
                      },
                      '&:hover': {
                        background: 'rgba(58, 177, 202, 0.1)',
                      },
                    },
                  }}
                >
                  <Controller
                    as={PhoneInput}
                    name="phoneNumber"
                    placeholder="Enter phone number"
                    defaultValue=""
                    inputmode="numeric"
                    country="ca"
                    disableDropdown
                    control={control}
                    inputClass="phoneInput"
                    rules={{ required: true }}
                    disabled={verify}
                    onlyCountries={['ca', 'us', 'jp']}
                  />
                  <FormFieldErrorMessage error={errors.phoneNumber} />
                </div>
              </div>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  [mq(bp.large)]: {
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridColumnGap: 15,
                  },
                  button: {
                    marginBottom: 10,
                  },
                }}
              >
                <button
                  type="button"
                  onClick={onCancel}
                  disabled={verify}
                  css={[
                    goBackButton,
                    {
                      justifyContent: 'flex-end',
                      [mq(bp.large)]: {
                        marginRight: 15,
                      },
                    },
                  ]}
                >
                  Cancel
                </button>
                <div
                  css={{
                    position: 'relative',
                    width: '100%',
                    [mq(bp.large)]: {
                      width: 'auto',
                    },
                  }}
                >
                  <button
                    type="button"
                    onClick={() => handleTwilioCreate('call')}
                    disabled={verify}
                    css={(theme) =>
                      button(
                        theme.buttons.type_dark,
                        theme.buttons.bg2,
                        theme.buttons.hover2
                      )
                    }
                  >
                    {callLoading ? 'loading' : 'Call Me'}
                  </button>
                </div>
                <div
                  css={{
                    position: 'relative',
                    width: '100%',
                    [mq(bp.large)]: {
                      width: 'auto',
                    },
                  }}
                >
                  <button
                    type="button"
                    onClick={() => handleTwilioCreate('sms')}
                    disabled={verify}
                    css={(theme) =>
                      button(
                        theme.buttons.type_dark,
                        theme.buttons.bg2,
                        theme.buttons.hover2
                      )
                    }
                  >
                    {textLoading ? 'loading' : 'Text Me'}
                  </button>
                </div>
              </div>
              <p
                css={{
                  color: '#5E5E5E',
                  fontStyle: 'italic',
                  fontSize: 14,
                }}
              >
                *Message and data rates may apply
              </p>
            </div>
          ) : (
            <div css={settingContent}>
              <p>{formattedPhoneNum}</p>
            </div>
          )}
        </div>
      </div>
      <div css={{ position: 'absolute', top: 30, right: 30 }}>
        <button type="button" css={editButton} onClick={onEdit}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default withFirebase(EditPhoneNumber);
