/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { withFirebase } from 'library/Firebase';
import { useParams } from 'react-router-dom';
import { bp, mq } from 'constants/mediaQueries';
import { settingTitle, settingContent } from 'theme/styleBlocks';
import EditEmail from './EditEmail';
import EditName from './EditName';
import EditPhoneNumber from './EditPhoneNumber';
import EditExp from './EditExp';
import EditCert from './EditCert';
import EditImg from './EditImg';
import EditLocation from './EditLocation';
import EditIndustry from './EditIndustry';
import EditSuspended from './EditSuspended';
import ClaimedGigs from './ClaimedGigs';
import AppliedGigs from './AppliedGigs';
import CompletedGigs from './CompletedGigs';
import EditBusinessName from './EditBusinessName';
import EditLogo from './EditLogo';
import ActiveGigs from './ActiveGigs';
import EditBanking from './EditBanking';
import GovIDCheck from './GovIDCheck';

const UserCard = ({ firebase }) => {
  const [userData, setUserData] = useState(null);
  const { id, type } = useParams();
  useEffect(() => {
    firebase.getUserData(id, type).onSnapshot((snapshot) => {
      const data = snapshot.data();
      setUserData(data);
    });
  }, [firebase, id, type]);
  return (
    <React.Fragment>
      {type === 'workers' ? (
        <div
          css={{
            margin: '0 auto',
            padding: '0 20px',
            marginTop: 50,
            [mq(bp.large)]: {
              padding: 0,
              marginTop: 90,
              maxWidth: 1020,
            },
          }}
        >
          <div
            css={{
              padding: 30,
              position: 'relative',
            }}
          >
            <div
              css={{
                gridTemplateColumns: '2fr 2fr',
                [mq(bp.large)]: {
                  display: 'grid',
                  gridTemplateColumns: '1fr 2fr',
                },
              }}
            >
              <div css={{ justifySelf: 'start' }}>
                <h2 css={settingTitle}>ID</h2>
              </div>
              <div css={settingContent}>
                <p>{id}</p>
              </div>
            </div>
          </div>
          {userData ? (
            <div>
              <div>
                <EditName
                  firstName={userData.firstName}
                  lastName={userData.lastName}
                />
              </div>
              <div>
                <EditImg
                  profileImage={userData.profileImage}
                  original={userData.originalProfileImage}
                />
              </div>
              <div>
                <EditEmail email={userData.email} />
              </div>
              <div>
                <EditPhoneNumber phoneNumber={userData.phoneNumber} />
              </div>
              <div>
                <EditExp
                  experiences={userData.exp}
                  prevNoExp={userData.noExp}
                />
              </div>
              <div>
                <EditCert certificates={userData.certList} />
              </div>
              <div>
                <EditLocation
                  location={userData.location}
                  preferences={userData.preferences}
                />
              </div>
              <div>
                <EditIndustry
                  industryPreference={userData.preferences.industries}
                />
              </div>
              <div>
                <EditSuspended
                  suspended={userData.suspended}
                  suspendedUntil={userData.suspendedUntil}
                />
              </div>
              <div>
                <ClaimedGigs
                  claimedGigs={userData.claimedGigs}
                  userData={userData}
                />
              </div>
              <div>
                <AppliedGigs
                  appliedGigs={userData.appliedGigs}
                  userData={userData}
                />
              </div>
              <div>
                <CompletedGigs completedGigs={userData.completedGigs} />
              </div>
              {userData.setup.govID &&
                userData.setup.govIDStatus !== 2 &&
                !userData.setupComplete && (
                  <div>
                    <GovIDCheck
                      id={userData.uid}
                      profileImage={userData.profileImage}
                    />
                  </div>
                )}
            </div>
          ) : null}
        </div>
      ) : (
        <div
          css={{
            margin: '0 auto',
            padding: '0 20px',
            marginTop: 50,
            [mq(bp.large)]: {
              padding: 0,
              marginTop: 90,
              maxWidth: 1020,
            },
          }}
        >
          <div
            css={{
              padding: 30,
              position: 'relative',
            }}
          >
            <div
              css={{
                gridTemplateColumns: '2fr 2fr',
                [mq(bp.large)]: {
                  display: 'grid',
                  gridTemplateColumns: '1fr 2fr',
                },
              }}
            >
              <div css={{ justifySelf: 'start' }}>
                <h2 css={settingTitle}>ID</h2>
              </div>
              <div css={settingContent}>
                <p>{id}</p>
              </div>
            </div>
          </div>
          {userData ? (
            <div>
              <div>
                <EditLogo profileImage={userData.profileImage} />
              </div>
              <div>
                <EditBusinessName businessName={userData.businessName} />
              </div>
              <div>
                <EditName
                  firstName={userData.firstName}
                  lastName={userData.lastName}
                />
              </div>
              <div>
                <EditEmail email={userData.email} />
              </div>
              <div>
                <EditPhoneNumber phoneNumber={userData.phoneNumber} />
              </div>
              <div>
                <EditBanking stripeCustomerId={userData.stripeCustomerId} />
              </div>
              <div>
                <ActiveGigs activeGigs={userData.activeGigs} />
              </div>
              <div>
                <CompletedGigs completedGigs={userData.completedGigs} />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default withFirebase(UserCard);
