/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { withFirebase } from 'library/Firebase';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  button,
  buttonContainer,
  goBackButton,
  customRadio,
  gigBox,
  formInput,
  addButton,
  popupContainer,
} from 'theme/styleBlocks';
import { Ring } from 'react-spinners-css';
import { bp, mq } from 'constants/mediaQueries';
import { useForm } from 'react-hook-form';
import { ReactComponent as Visa } from 'assets/icons/visa.svg';
import { ReactComponent as MasterCard } from 'assets/icons/mastercard.svg';
import { ReactComponent as DinersClub } from 'assets/icons/dinersClub.svg';
import { ReactComponent as Discover } from 'assets/icons/discover.svg';
import { ReactComponent as Jcb } from 'assets/icons/jcb.svg';
import { ReactComponent as AmericaExpress } from 'assets/icons/americanExpress.svg';
import { ReactComponent as UnionPay } from 'assets/icons/unionPay.svg';
import { ReactComponent as CreditCard } from 'assets/icons/creditCardIcon.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useHistory, Redirect } from 'react-router-dom';
import moment from 'moment';

const Payment = (props) => {
  const history = useHistory();
  const [cards, setCards] = useState([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  // const [makeDefault, setMakeDefault] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [cardToUse, setCardToUse] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [fees, setFees] = useState(0);
  const [total, setTotal] = useState(0);
  const [creating, setCreating] = useState(false);
  const [modal, setModal] = useState(false);
  const [cardIndexToRemove, setCardIndexToRemove] = useState(null);
  const [removePending, setRemovePending] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (props.step !== 7) history.push('/create-gig');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadCards = () => {
    setLoading(true);
    props.firebase
      .getSavedCards(props.userData.stripeCustomerId)
      .then((cardsData) => {
        setCards(cardsData);
        setLoading(false);
      });
  };

  useEffect(() => {
    const calcHours = props.dates.reduce(
      (acc, { hours, paidBreak, breakTime }) => {
        if (!paidBreak) {
          const breakHours = moment.duration(breakTime * 60000).asHours();
          return Math.round((acc + hours - breakHours) * 100) / 100;
        }
        return acc + hours;
      },
      0
    );
    const calcSubtotal = calcHours * props.vacancies * props.payPerHour;
    const calcFees = Math.round(calcSubtotal * 0.2 * 100) / 100;
    const calcTax =
      Math.round(
        (calcSubtotal + calcFees) * (props.taxPercentage / 100) * 100
      ) / 100;
    setTotalHours(calcHours);
    setSubTotal(Math.round(calcSubtotal * 100) / 100);
    setTax(calcTax);
    setFees(calcFees);
    setTotal(Math.round((calcSubtotal + calcTax + calcFees) * 100) / 100);
    loadCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const cardIcon = (brand) => {
    switch (brand) {
      case 'visa':
        return <Visa />;
      case 'unionpay':
        return <UnionPay />;
      case 'mastercard':
        return <MasterCard />;
      case 'jcb':
        return <Jcb />;
      case 'diners club':
        return <DinersClub />;
      case 'discover':
        return <Discover />;
      case 'american express':
        return <AmericaExpress />;
      case 'unknown':
        return <CreditCard />;
      default:
        return <CreditCard />;
    }
  };
  const cardOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    disabled: saving,
  };

  const handleSaveCard = (data) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setSaving(true);
    setMessage('');
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    props.firebase
      .getClientSecret(props.userData.stripeCustomerId)
      .then((client_secret) => {
        return stripe.confirmCardSetup(client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: data.nameOnCard,
            },
            metadata: {
              nickname: data.cardNickname,
            },
          },
        });
      })
      .then((result) => {
        if (result.error) {
          setSaving(false);
          setMessage(result.error.message);
        } else {
          props.firebase
            .saveCard(
              result.setupIntent.payment_method,
              props.userData.stripeCustomerId
              // makeDefault
            )
            .then(() => {
              loadCards();
              setSaving(false);
              setAddCard(false);
            });
        }
      });
  };

  const createGig = () => {
    setCreating(true);
    return props.firebase
      .createGig(
        cardToUse,
        props.state,
        props.userData.businessName,
        props.userData.invoicingOption,
        props.userData.checkoutMethod,
        props.id
        // props.profileImage
      )
      .then((result) => {
        if (result.success) {
          localStorage.removeItem('createGig');
          props.setInitialState();
          props.setGigIDs(result.gigIDs);
          props.nextStep(8);
        }
        setCreating(false);
      });
  };
  const openRemoveCardModal = (index) => {
    setModal(true);
    setCardIndexToRemove(index);
  };
  const removeCard = (card) => {
    setRemovePending(true);
    props.firebase.removePaymentMethod(card).then(() => {
      loadCards();
      setModal(false);
      setCardIndexToRemove(null);
      setRemovePending(false);
    });
  };
  return (
    <div
      css={(theme) => ({
        padding: '20px 15px',
        margin: '0px auto 65px',
        [mq(bp.small)]: {
          width: '90vw',
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: '0px 20px 60px 60px',
          margin: 0,
          width: '100%',
        },
      })}
    >
      <h1 css={{ marginTop: 0, marginBottom: 16 }}>Cost Summary</h1>
      {/* {props.invoicingOption ? null : (
        <p
          css={{
            width: '100%',
            maxWidth: 790,
            [mq(bp.large)]: {
              maxWidth: props.invoicingOption && 450,
              width: props.invoicingOption ? '100%' : '66%',
            },
          }}
        >
          Authorization of a security hold on a selected card is required to
          create the gig and will only be placed on the card 6 days prior to the
          start of the gig.{' '}
        </p>
      )} */}
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          [mq(bp.large)]: {
            flexDirection: 'row',
          },
        }}
      >
        {props.userData.invoicingOption ? null : (
          <div
            css={[
              gigBox,
              {
                width: '100%',
                marginBottom: 30,
                [mq(bp.large)]: {
                  width: '66%',
                },
              },
            ]}
          >
            <h2>Your Credit Cards</h2>
            {loading ? (
              <div
                css={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  p: {
                    fontSize: '14px!important',
                  },
                }}
              >
                <Ring
                  color="#002E6D"
                  size={30}
                  css={{
                    width: 30,
                    height: 30,
                    marginRight: 10,
                    marginBottom: 10,
                  }}
                />
              </div>
            ) : cards.length ? (
              <div css={{ marginBottom: 20 }}>
                <div
                  css={{
                    display: 'none',
                    gridTemplateColumns: '2fr 1fr 0.6fr 0.8fr',
                    gridColumnGap: 10,
                    [mq(bp.large)]: {
                      display: 'grid',
                    },
                  }}
                >
                  <div
                    css={{
                      [mq(bp.large)]: {
                        gridColumn: '2/3',
                      },
                    }}
                  >
                    <p css={{ margin: 0, fontSize: 14 }}>Name on Card</p>
                  </div>
                  <div css={{ gridColumn: '3/4' }}>
                    <p css={{ margin: 0, fontSize: 14 }}>Expiry Date</p>
                  </div>
                </div>
                <hr />
                {cards.map((card, i) => (
                  <div key={i}>
                    <div
                      css={[
                        customRadio(),
                        {
                          display: 'grid',
                          gridTemplateColumns: '1fr',
                          marginBottom: 15,
                          marginLeft: 20,
                          [mq(bp.large)]: {
                            gridTemplateColumns: '2fr 1fr 0.6fr 0.8fr',
                            gridColumnGap: 10,
                            marginLeft: 0,
                          },
                          p: {
                            fontSize: 16,
                          },
                        },
                      ]}
                    >
                      <div
                        css={{
                          [mq(bp.large)]: {
                            gridColumn: '1/2',
                          },
                        }}
                      >
                        <label
                          css={{
                            fontSize: 16,
                            marginBottom: 4,
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            p: {
                              fontWeight: 600,
                            },
                          }}
                        >
                          <input
                            type="radio"
                            name="card"
                            onChange={() => setCardToUse(card.id)}
                            checked={card.id === cardToUse}
                          />
                          <span css={{ top: '4px!important' }} />
                          <div
                            css={{
                              display: 'inline-block',
                              marginRight: 5,
                              svg: {
                                width: 35,
                                height: 20,
                                verticalAlign: 'middle',
                              },
                            }}
                          >
                            {cardIcon(card.card.brand)}
                          </div>
                          <p
                            css={{
                              marginRight: 5,
                              marginTop: 0,
                              marginBottom: 0,
                              width: 90,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {card.metadata.nickname}
                          </p>
                          <p css={{ color: '#8A8A8A', margin: 0 }}>
                            ending in {card.card.last4}
                          </p>
                        </label>
                      </div>
                      <div
                        css={{
                          paddingLeft: 24,
                          [mq(bp.large)]: { paddingLeft: 0, gridColumn: '2/3' },
                        }}
                      >
                        <p
                          css={{
                            margin: 0,
                            width: 150,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden !important',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {card.billing_details.name}
                        </p>
                      </div>
                      <div
                        css={{
                          paddingLeft: 24,
                          [mq(bp.large)]: { paddingLeft: 0, gridColumn: '3/4' },
                        }}
                      >
                        <p css={{ margin: 0 }}>
                          {card.card.exp_month}/{card.card.exp_year}
                        </p>
                      </div>
                      <button
                        onClick={() => openRemoveCardModal(i)}
                        css={{ border: 'none', color: '#002E6D' }}
                      >
                        Remove Card
                      </button>
                    </div>
                  </div>
                ))}
                {/* Confirm modal */}
                {cardIndexToRemove !== null && modal ? (
                  <div
                    css={{
                      display: 'flex',
                      position: 'fixed',
                      width: '100vw',
                      height: '100vh',
                      background: 'rgba(0,0,0,.5)',
                      top: 60,
                      left: 0,
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 99,
                    }}
                  >
                    <div
                      css={(theme) => [popupContainer(), { height: 'auto' }]}
                    >
                      <div
                        css={{
                          background: '#fff',
                          borderRadius: 4,
                        }}
                      >
                        <div
                          css={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: 30,
                          }}
                        >
                          <h3
                            css={{ margin: 0, fontWeight: 700, fontSize: 20 }}
                          >
                            Remove Payment Method
                          </h3>
                          <button
                            type="button"
                            onClick={() => setModal(false)}
                            disabled={removePending}
                            css={{
                              border: 'none',
                              lineHeight: 1,
                              fontWeight: 500,
                            }}
                          >
                            <Close
                              css={{
                                width: 15,
                                height: 15,
                                path: { fill: '#393939' },
                              }}
                            />
                          </button>
                        </div>
                        <div
                          css={{
                            fontSize: 16,
                            marginBottom: 10,
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            p: {
                              fontWeight: 600,
                            },
                          }}
                        >
                          <div
                            css={{
                              display: 'inline-block',
                              marginRight: 5,
                              svg: {
                                width: 35,
                                height: 20,
                                verticalAlign: 'middle',
                              },
                            }}
                          >
                            {cardIcon(cards[cardIndexToRemove].card.brand)}
                          </div>
                          <p
                            css={{
                              marginRight: 5,
                              marginTop: 0,
                              marginBottom: 0,
                              width: 90,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden !important',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {cards[cardIndexToRemove].metadata.nickname}
                          </p>
                          <p css={{ color: '#8A8A8A', margin: 0 }}>
                            ending in {cards[cardIndexToRemove].card.last4}
                          </p>
                        </div>
                        <p css={{ marginBottom: 30 }}>
                          If you do not want this payment method to be displayed
                          in your list of payment options, please select
                          "Confirm Remove."{' '}
                        </p>
                        <div css={buttonContainer}>
                          <button
                            type="button"
                            onClick={() => setModal(false)}
                            css={goBackButton}
                            disabled={removePending}
                          >
                            Back
                          </button>
                          <button
                            type="submit"
                            onClick={() => removeCard(cards[cardIndexToRemove])}
                            disabled={removePending}
                            css={(theme) =>
                              button(
                                theme.buttons.type_dark,
                                theme.buttons.bg2,
                                theme.buttons.hover2
                              )
                            }
                          >
                            {removePending ? 'Removing' : 'Confirm Remove'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div>
              <button
                type="button"
                onClick={() => setAddCard(true)}
                css={addButton}
              >
                + Add a new card
              </button>
            </div>
            {addCard ? (
              <div>
                <div css={{ marginBottom: 20 }}>
                  <p>Enter your card information.</p>
                  <div
                    css={{
                      display: 'grid',
                      [mq(bp.large)]: {
                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                        gridColumnGap: 20,
                        alignItems: 'end',
                      },
                    }}
                  >
                    <div
                      css={[
                        formInput,
                        { [mq(bp.large)]: { gridColumn: '1/3' } },
                      ]}
                    >
                      <label htmlFor="nameOnCard">Name on card</label>
                      <input
                        type="text"
                        name="nameOnCard"
                        id="nameOnCard"
                        ref={register}
                      />
                    </div>
                    <div
                      css={[
                        formInput,
                        { [mq(bp.large)]: { gridColumn: '3/4' } },
                      ]}
                    >
                      <label htmlFor="cardNickname">Card Nickname</label>
                      <input
                        type="text"
                        name="cardNickname"
                        id="cardNickname"
                        ref={register}
                      />
                    </div>
                    <div
                      css={[
                        formInput,
                        { [mq(bp.large)]: { gridColumn: '1/4' } },
                      ]}
                    >
                      <label>Card Number</label>
                      <CardElement
                        options={cardOptions}
                        css={{
                          padding: 16,
                          backgroundColor: 'rgba(58,177,202,0.1)',
                          borderRadius: 4,
                        }}
                      />
                    </div>
                    <div css={{ [mq(bp.large)]: { gridColumn: '4/4' } }}>
                      <div css={{ position: 'relative' }}>
                        <button
                          type="button"
                          onClick={handleSubmit(handleSaveCard)}
                          css={(theme) => [
                            button(
                              theme.buttons.type_light,
                              theme.buttons.bg1,
                              theme.buttons.hover1
                            ),
                            {
                              padding: '16px 10px',
                              marginBottom: 15,
                            },
                          ]}
                        >
                          {saving ? (
                            <Ring
                              color="#fff"
                              size="22"
                              css={{
                                width: 22,
                                height: 22,
                                display: 'flex',
                                margin: 'auto',
                              }}
                            />
                          ) : (
                            'Add New Card'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  {message ? <p css={{ color: '#D72F2F' }}>{message}</p> : null}

                  {/**
            <div>
              <label htmlFor="makeDefault">Make default card</label>
              <input
                type="checkbox"
                name="makeDefault"
                id="makeDefault"
                onChange={() => setMakeDefault(!makeDefault)}
                checked={makeDefault}
              />
            </div>
             */}
                </div>
              </div>
            ) : null}
            <p>
              Note: You will only be charged for gigs completed by worker(s) at
              the end of the gig upon confirming gig completion for each worker.
            </p>
          </div>
        )}
        <div
          css={[
            gigBox,
            {
              width: '100%',
              [mq(bp.large)]: {
                maxWidth: props.userData.invoicingOption && 450,
                width: props.userData.invoicingOption ? '100%' : '32%',
              },
            },
          ]}
        >
          <h2>Cost Breakdown</h2>
          <div>
            <div>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  gridColumnGap: 80,
                  marginBottom: 20,
                }}
              >
                <div>
                  <p># of Workers</p>
                </div>
                <div>
                  <p>{props.vacancies}</p>
                </div>
                <div>
                  <p>Hourly Pay</p>
                </div>
                <div>
                  <p>${props.payPerHour}/h</p>
                </div>
                <div>
                  <p>Total Paid Hours per worker</p>
                </div>
                <div css={{ width: '100%', borderBottom: '1px solid #000' }}>
                  <p>{totalHours < 0 ? 0 : totalHours} hours</p>
                </div>
                <div>
                  <p>Subtotal</p>
                </div>
                <div>
                  <p>${subTotal.toFixed(2)}</p>
                </div>
                <hr css={{ gridColumn: '1/3', width: '100%' }} />
                <div>
                  <p>Fee</p>
                </div>
                <div>
                  <p>${fees.toFixed(2)}</p>
                </div>
                <div>
                  <p>Tax (GST/HST)</p>
                </div>
                <div css={{ width: '100%', borderBottom: '1px solid #000' }}>
                  <p>${tax.toFixed(2)}</p>
                </div>
                <div>
                  <p>Estimated Amount</p>
                </div>
                <div>
                  <p>${total.toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
          <div css={buttonContainer}>
            <button
              type="button"
              css={goBackButton}
              onClick={() => props.prevStep(6)}
            >
              Back
            </button>
            <button
              type="button"
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
              onClick={createGig}
              disabled={
                (!props.userData.invoicingOption && !cardToUse) || creating
              }
            >
              {creating ? (
                <Ring
                  color="#002E6D"
                  size="22"
                  css={{
                    width: 22,
                    height: 22,
                    display: 'flex',
                    margin: 'auto',
                  }}
                />
              ) : (
                'Confirm Gig Creation'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(Payment);
