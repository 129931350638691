/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { withFirebase } from 'library/Firebase';
import IdCheckCard from './IdCheckCard';

const CheckID = ({ firebase }) => {
  const [workersThatNeedCheck, setWorkersThatNeedCheck] = useState([]);
  useEffect(() => {
    const unsub = firebase
      .findWorkersThatNeedIDCheck()
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setWorkersThatNeedCheck(data);
      });
    return () => {
      unsub();
    };
  }, []);
  //   const findWorkersThatNeedIDCheck = () => {
  //     firebase
  //       .findWorkersThatNeedIDCheck()
  //       .then((data) => setWorkersThatNeedCheck(data));
  //   };
  return (
    <div>
      {workersThatNeedCheck.length > 0 &&
        workersThatNeedCheck.map((worker) => (
          <IdCheckCard userData={worker} key={worker.uid} />
        ))}
    </div>
  );
};
export default withFirebase(CheckID);
