import React, { useState } from 'react';
import { withFirebase } from 'library/Firebase';

const Login = ({ firebase }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = () => {
    firebase.login(email, password);
  };

  return (
    <div>
      <p>login</p>
      <div>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          name="email"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="button" onClick={login}>
        Login
      </button>
    </div>
  );
};

export default withFirebase(Login);
