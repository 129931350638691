/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { BodyScrollOptions } from 'body-scroll-lock';
import {
  editButton,
  formInput,
  button,
  goBackButton,
  buttonContainer,
  popupContainer,
  settingTitle,
} from 'theme/styleBlocks';
import Select from 'react-select';
import { industries } from 'constants/lists';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { withFirebase } from 'library/Firebase/';
import { bp, mq } from 'constants/mediaQueries';
// import { Ring } from 'react-spinners-css';

const EditIndustry = ({ industryPreference, firebase }) => {
  const [edit, setEdit] = useState(false);
  const [industryList, setIndustryList] = useState([]);
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const { id, type } = useParams();
  const modalEl = useRef(null);

  useEffect(() => {
    setIndustryList(industryPreference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = () => {
    setEdit(true);
    const options: BodyScrollOptions = {
      reserveScrollBarGap: true,
    };
    disableBodyScroll(modalEl.current, options);
  };
  const onClose = () => {
    setEdit(false);
    enableBodyScroll(modalEl.current);
  };
  const onIndustrySelect = (value) => {
    const dupeCheck = industryList.some(
      (industry) => industry.code === value.code
    );
    if (dupeCheck) return;
    const industryCopy = [...industryList];
    industryCopy.push(value);
    setChanged(true);
    return setIndustryList(industryCopy);
  };
  const removeIndustry = (code) => {
    const filteredList = industryList.filter((industry) => {
      return industry.code !== code;
    });
    setIndustryList(filteredList);
    setChanged(true);
  };
  const onSave = (data) => {
    setSaving(true);
    const preferences = {
      industries: industryList,
    };

    firebase.saveIndustries(preferences, type, id).then(() => {
      setSaving(false);
      setEdit(false);
      enableBodyScroll(modalEl.current);
    });
  };

  return (
    <div
      css={{
        backgroundColor: edit && '#F8F8F8',
        padding: 30,
        position: 'relative',
      }}
    >
      <div
        ref={modalEl}
        css={{
          display: edit ? 'flex' : 'none',
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          background: 'rgba(0,0,0,0.5)',
          top: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99,
          overflowY: 'initial',
        }}
      >
        <div css={popupContainer}>
          <div css={{ marginBottom: 30 }}>
            <div css={formInput}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 30,
                }}
              >
                <p
                // css={{ margin: 0, fontWeight: 700, fontSize: 24 }}
                >
                  Industry Preferences
                </p>
                <button
                  type="button"
                  onClick={onClose}
                  css={{
                    border: 'none',
                    lineHeight: 1,
                    fontWeight: 500,
                  }}
                >
                  <Close
                    css={{ width: 15, height: 15, path: { fill: '#393939' } }}
                  />
                </button>
              </div>
              <label htmlFor="industry">Industry</label>
              <Select
                styles={{
                  container: (provided) => ({
                    ...provided,
                    gridColumn: '1/3',
                    '&:focus': {
                      border: '1px solid transparent',
                      outline: 'none',
                    },
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: 14,
                    cursor: 'pointer',
                  }),
                  control: (provided) => ({
                    ...provided,
                    cursor: 'pointer',
                    background: 'rgba(58, 177, 202, 0.1)',
                    border: '1px solid transparent',
                    boxShadow: 'none',
                    padding: 6,
                    '&:hover': {
                      border: '1px solid #002E6D',
                    },
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: 14,
                  }),
                }}
                options={industries}
                placeholder="Industry"
                onChange={(option) => onIndustrySelect(option)}
                isDisabled={false}
                value=""
              />
            </div>
            {industryList.length ? (
              <div>
                {industryList.map((industry, i) => (
                  <div
                    key={i}
                    css={(theme) => ({
                      display: 'inline-flex',
                      padding: '8px 16px',
                      background: theme.colors.primary,
                      borderRadius: 100,
                      alignItems: 'center',
                      margin: '8px 8px 8px 0',
                      color: theme.colors.light,
                      p: {
                        color: theme.colors.light,
                      },
                    })}
                  >
                    <p css={{ margin: 0 }}>{industry.label}</p>
                    <button
                      type="button"
                      onClick={() => removeIndustry(industry.code)}
                      css={{
                        marginLeft: 8,
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontWeight: 'bold',
                        padding: 0,
                        display: 'flex',
                        cursor: 'pointer',
                        svg: {
                          width: 15,
                          height: 15,
                        },
                      }}
                    >
                      <Close />
                    </button>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div css={buttonContainer}>
            <button type="button" onClick={onClose} css={goBackButton}>
              Back
            </button>
            <button
              type="button"
              onClick={onSave}
              disabled={!changed}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              {saving
                ? 'Saving'
                : // <Ring
                  //   color="#002E6D"
                  //   size="22"
                  //   css={{
                  //     width: 22,
                  //     height: 22,
                  //     display: 'flex',
                  //     margin: 'auto',
                  //   }}
                  // />
                  'Save'}
            </button>
          </div>
        </div>
      </div>
      <div css={{ position: 'absolute', top: 30, right: 30 }}>
        <button type="button" css={editButton} onClick={onEdit}>
          Edit
        </button>
      </div>
      <div
        css={{
          gridTemplateColumns: '2fr 2fr',
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
          },
        }}
      >
        <div css={{ justifySelf: 'start' }}>
          <h2 css={settingTitle}>Industry Preference</h2>
        </div>
        {industryPreference.length ? (
          <div>
            {industryPreference.map((item, i) => (
              <div
                key={i}
                css={(theme) => ({
                  display: 'inline-flex',
                  padding: '8px 16px',
                  background: theme.colors.primary,
                  borderRadius: 100,
                  alignItems: 'center',
                  margin: '8px 8px 8px 0',
                  color: theme.colors.light,
                  p: {
                    color: theme.colors.light,
                  },
                })}
              >
                <p css={{ margin: 0 }}>{item.label}</p>
              </div>
            ))}
          </div>
        ) : (
          <p
            css={{
              color: '#002E6D',
              fontWeight: 500,
            }}
          >
            None
          </p>
        )}
      </div>
    </div>
  );
};

export default withFirebase(EditIndustry);
