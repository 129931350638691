/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { withFirebase } from 'library/Firebase';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { bp, mq } from 'constants/mediaQueries';
import {
  editButton,
  formInput,
  button,
  goBackButton,
  buttonContainer,
  settingTitle,
  settingContent,
  savedContainer,
  completeText,
  completedIcon,
} from 'theme/styleBlocks';
import { ReactComponent as GreenCheck } from 'assets/icons/successChip.svg';

const EditName = ({ firstName, lastName, firebase }) => {
  const [edit, setEdit] = useState(false);
  const [saved, setSaved] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { isSubmitted },
    reset,
    errors,
  } = useForm({
    reValidateMode: 'onSubmit',
  });
  const { id, type } = useParams();
  useEffect(() => {
    if (isSubmitted && !Object.keys(errors).length) reset();
  }, [isSubmitted, reset, errors]);

  const onEdit = () => {
    setEdit(true);
    setSaved(false);
    if (edit === true) {
      setEdit(false);
    }
  };
  const onCancel = () => {
    setEdit(false);
  };
  const onSave = (data) => {
    firebase.updateName(data.firstName, data.lastName, type, id).then(() => {
      setTimeout(() => [setEdit(false)], 1500);
      setSaved(true);
    });
  };

  return (
    <div
      css={{
        backgroundColor: edit && '#F8F8F8',
        padding: 30,
        position: 'relative',
      }}
    >
      <div
        css={{
          gridTemplateColumns: '2fr 2fr',
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
          },
        }}
      >
        <div css={{ justifySelf: 'start' }}>
          <h2 css={settingTitle}>Name</h2>
        </div>
        <div>
          {edit ? (
            <div>
              {saved ? (
                <div css={savedContainer}>
                  <GreenCheck css={completedIcon} />
                  <p css={completeText}>Awesome! Name has been updated.</p>
                </div>
              ) : (
                <div css={{ marginTop: 50 }}>
                  <div
                    css={{
                      [mq(bp.large)]: {
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr ',
                        gridColumnGap: 20,
                      },
                    }}
                  >
                    <div css={formInput}>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Enter first name"
                        ref={register({
                          required: true,
                          pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
                          message: 'Invalid Name',
                        })}
                        css={{ borderColor: errors.email && 'red !important' }}
                      />
                      <ErrorMessage errors={errors} name="firstName" />
                    </div>
                    <div css={formInput}>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Enter last name"
                        ref={register({
                          required: true,
                          pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
                          message: 'Invalid Name',
                        })}
                        css={{ borderColor: errors.email && 'red !important' }}
                      />
                      <ErrorMessage errors={errors} name="lastName" />
                    </div>
                  </div>
                  <div css={buttonContainer}>
                    <button type="button" onClick={onCancel} css={goBackButton}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleSubmit(onSave)}
                      css={(theme) =>
                        button(
                          theme.buttons.type_dark,
                          theme.buttons.bg2,
                          theme.buttons.hover2
                        )
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div css={settingContent}>
              <p>
                {firstName} {lastName}
              </p>
            </div>
          )}
        </div>
      </div>
      <div css={{ position: 'absolute', top: 30, right: 30 }}>
        <button type="button" css={editButton} onClick={onEdit}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default withFirebase(EditName);
