/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { withFirebase } from 'library/Firebase';
import { bp, mq } from 'constants/mediaQueries';
import { formInput, button } from 'theme/styleBlocks';

const AddAdmin = ({ firebase }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const createUser = () => {
    setMessage('');
    setLoading(true);
    firebase.addAdmin(email).then((result) => {
      if (result.success) {
        setMessage('Success');
      }
      if (!result.success) {
        setMessage(result.message);
      }
      setLoading(false);
    });
  };

  return (
    <div
      css={{
        margin: '0 auto',
        padding: '0 20px',
        marginTop: 50,
        [mq(bp.large)]: {
          padding: 0,
          marginTop: 90,
          maxWidth: 1020,
        },
      }}
    >
      <h3>Add another admin</h3>
      <div
        css={{
          marginTop: 50,
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            columnGap: '40px',
            alignItems: 'center',
          },
        }}
      >
        <div css={formInput}>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
        </div>
        <button
          type="button"
          onClick={createUser}
          disabled={loading}
          css={(theme) =>
            button(
              theme.buttons.type_dark,
              theme.buttons.bg2,
              theme.buttons.hover2
            )
          }
        >
          Add User
        </button>
        {message && <p css={{ color: '#D72F2F' }}>{message}</p>}
      </div>
    </div>
  );
};

export default withFirebase(AddAdmin);
