/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useRef, useEffect } from 'react';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useParams } from 'react-router-dom';
import type { BodyScrollOptions } from 'body-scroll-lock';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import {
  editButton,
  formInput,
  button,
  goBackButton,
  buttonContainer,
  popupContainer,
  customCheck,
  formLabel,
  addButton,
  blueBox,
  settingTitle,
} from 'theme/styleBlocks';
import { certifications, licences } from 'constants/lists';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import AutoFillSearch from 'components/AutoFillSearch';
import Select from 'react-select';
import moment from 'moment';
import { withFirebase } from 'library/Firebase/';
import { bp, mq } from 'constants/mediaQueries';
// import { Ring } from 'react-spinners-css';

const certList = certifications.concat(licences);

const monthsList = [
  { label: 'No Month', abbreviation: '', value: 0 },
  { label: 'January', abbreviation: 'Jan', value: 1 },
  { label: 'February', abbreviation: 'Feb', value: 2 },
  { label: 'March', abbreviation: 'Mar', value: 3 },
  { label: 'April', abbreviation: 'Apr', value: 4 },
  { label: 'May', abbreviation: 'May', value: 5 },
  { label: 'June', abbreviation: 'Jun', value: 6 },
  { label: 'July', abbreviation: 'Jul', value: 7 },
  { label: 'August', abbreviation: 'Aug', value: 8 },
  { label: 'September', abbreviation: 'Sep', value: 9 },
  { label: 'October', abbreviation: 'Oct', value: 10 },
  { label: 'November', abbreviation: 'Nov', value: 11 },
  { label: 'December', abbreviation: 'Dec', value: 12 },
];

const EditCert = ({ certificates, firebase }) => {
  const [edit, setEdit] = useState(false);
  const [list, setList] = useState([]);
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const modalEl = useRef(null);
  const {
    getValues,
    register,
    setError,
    errors,
    clearErrors,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState,
  } = useForm({
    defaultValues: {
      cert: '',
      month: '',
      year: '',
      noExpiry: false,
    },
  });
  const { id, type } = useParams();
  const watchNoExpiry = watch('noExpiry');
  const noCert = watch('noCert');
  useEffect(() => {
    if (formState.isSubmitted && !Object.keys(errors).length) {
      return reset();
    }
  }, [formState.isSubmitted, reset, errors]);
  useEffect(() => {
    setValue('cert', { name: '', code: '' });
    setValue('noCert', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setList([...certificates]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setChanged(false);
    if (list.length !== certificates.length) {
      return setChanged(true);
    }
    if (noCert && certificates.length && list !== certificates) {
      return setChanged(true);
    }
    if (noCert) {
      return setChanged(true);
    }
    if (!noCert && !certificates.length) {
      return setChanged(false);
    }
  }, [noCert, certificates.length]);

  const onEdit = () => {
    setEdit(true);
    const options: BodyScrollOptions = {
      reserveScrollBarGap: true,
    };
    disableBodyScroll(modalEl.current, options);
  };
  const onClose = () => {
    setEdit(false);
    enableBodyScroll(modalEl.current);
  };
  const onYearChange = (value) => {
    const prevValue = getValues('year');
    const regex = RegExp('^[0-9]*$');
    const test = regex.test(value);

    if (!test && value) return prevValue || '';
    if (value.length < 5) return value;
  };
  const yearValidation = (value) => {
    const noExpiry = getValues('noExpiry');
    const expiryMonth = getValues('month');
    const yearNow = moment().format('YYYY') * 1;
    const monthNow = moment().format('M') * 1;
    const expiryYear = value * 1;
    let error;
    if (noExpiry) {
      clearErrors(['year', 'month']);
      error = true;
    }
    if (!noExpiry) {
      if (!value) return false;
      if (expiryYear > yearNow) {
        clearErrors(['year', 'month']);
        error = true;
      }
      if (expiryYear < yearNow) {
        setError([
          { name: 'month', type: 'expired', message: 'Expired' },
          { name: 'year', type: 'expired', message: 'Expired' },
        ]);
        error = 'Expired';
      }
      if (expiryYear === yearNow) {
        if (!expiryMonth.value || expiryMonth.value === 0) {
          clearErrors(['year', 'month']);
          error = true;
        }
        if (expiryMonth.value) {
          if (expiryMonth.value >= monthNow) {
            clearErrors(['year', 'month']);
            error = true;
          }
          if (expiryMonth.value < monthNow) {
            setError([
              { name: 'month', type: 'expired', message: 'Expired' },
              { name: 'year', type: 'expired', message: 'Expired' },
            ]);
            error = 'Expired';
          }
        }
      }
    }
    return error;
  };
  const noExpiryValidation = (value) => {
    const expiryMonth = getValues('month');
    const expiryYear = getValues('year');
    const yearNow = moment().format('YYYY');
    const monthNow = moment().format('M');
    if (value) return clearErrors(['year', 'month']);
    if (!value) {
      if (!expiryYear) setError('year', 'required', '');
      if (!expiryMonth) setError('month', 'validate', '');
      if (expiryYear) {
        if (expiryYear > yearNow) return clearErrors(['year', 'month']);
        if (expiryYear < yearNow) {
          return setError([
            { name: 'month', type: 'expired', message: 'Expired' },
            { name: 'year', type: 'expired', message: 'Expired' },
          ]);
        }
        if (expiryYear === yearNow) {
          if (!expiryMonth.value || expiryMonth.value === 0) {
            clearErrors(['year', 'month']);
          }
          if (expiryMonth.value) {
            if (expiryMonth.value >= monthNow) {
              clearErrors(['year', 'month']);
            }
            if (expiryMonth.value < monthNow) {
              setError([
                { name: 'month', type: 'expired', message: 'Expired' },
                { name: 'year', type: 'expired', message: 'Expired' },
              ]);
            }
          }
        }
      }
    }
  };
  const monthValidation = (value) => {
    const noExpiry = getValues('noExpiry');
    const expiryYear = getValues('year');
    const yearNow = moment().format('YYYY') * 1;
    const monthNow = moment().format('M') * 1;
    let error;
    if (noExpiry) {
      clearErrors(['year', 'month']);
      error = true;
    }
    if (!noExpiry) {
      if (!value) return false;
      if (!expiryYear) return setError('year', 'validate', '');
      if (expiryYear > yearNow) {
        clearErrors(['year', 'month']);
        error = true;
      }
      if (expiryYear < yearNow) {
        setError([
          { name: 'month', type: 'expired', message: 'Expired' },
          { name: 'year', type: 'expired', message: 'Expired' },
        ]);
        error = 'Expired';
      }
      if (expiryYear === yearNow) {
        if (!value.value || value.value === 0) {
          clearErrors(['year', 'month']);
          error = true;
        }
        if (value.value) {
          if (value.value >= monthNow) {
            clearErrors(['year', 'month']);
            error = true;
          }
          if (value.value < monthNow) {
            setError([
              { name: 'month', type: 'expired', message: 'Expired' },
              { name: 'year', type: 'expired', message: 'Expired' },
            ]);
            error = 'Expired';
          }
        }
      }
    }
    return error;
  };
  const expiredMessage = () => {
    const { month, year } = errors;
    if ((month && month.message) || (year && year.message)) {
      return true;
    }
  };
  const addToList = (data) => {
    const certItem = {
      label: data.cert.label,
      value: data.cert.value,
      code: data.cert.code,
      equivalent: data.cert.equivalent,
      month: data.month.value,
      year: data.year,
    };
    if (data.noExpiry) {
      certItem.month = '';
      certItem.year = '';
    }
    if (!list.length) {
      setList([certItem]);
    }
    if (list.length) {
      const addCertList = [...list, certItem];
      const cleanCertList = addCertList.filter((item, index, array) => {
        return array.findIndex((item2) => item.code === item2.code) === index;
      });
      setList(cleanCertList);
    }
    reset();
    setChanged(true);
  };
  const disableSave = () => {
    if (changed) {
      return false;
    }
    if (!changed) {
      return true;
    }
  };
  const onSave = () => {
    setSaving(true);
    let certifications = [];
    if (list.length) {
      certifications = [...list];
    }
    if (noCert) {
      certifications.length = 0;
    }
    firebase.saveCert(certifications, id, type).then(() => {
      setSaving(false);
      setEdit(false);
      enableBodyScroll(modalEl.current);
    });
  };
  const remove = (i) => {
    const removedList = [...list];
    removedList.splice(i, 1);
    setList(removedList);
    setChanged(true);
  };
  return (
    <div
      css={{
        backgroundColor: edit && '#F8F8F8',
        padding: 30,
        position: 'relative',
      }}
    >
      <div
        ref={modalEl}
        css={{
          display: edit ? 'flex' : 'none',
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          background: 'rgba(0,0,0,0.5)',
          top: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99,
          overflowY: 'initial!important',
        }}
      >
        <div css={popupContainer}>
          <div css={{ marginBottom: 30 }}>
            <div css={formInput}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 30,
                }}
              >
                <button
                  type="button"
                  onClick={onClose}
                  css={{
                    border: 'none',
                    lineHeight: 1,
                    fontWeight: 500,
                  }}
                >
                  <Close css={{ height: 16 }} />
                </button>
              </div>
              {/* <p css={{ margin: '0 0 20px 0' }}>
                Please choose a Certification/License from the list provided. If
                it is not listed, please{' '}
                <a
                  href="/help/request-cert"
                  target="_blank"
                  css={{ textDecoration: 'underline' }}
                >
                  submit a request
                </a>{' '}
                to add it.
              </p> */}
              <FormProvider
                control={control}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
                setError={setError}
                isSubmitted={formState.isSubmitted}
                reset={reset}
              >
                <AutoFillSearch
                  disabled={noCert}
                  placeholder="Search certifications & licenses..."
                  label="Certification/License"
                  list={certList}
                  name="cert"
                  rules={{ validate: (value) => !!value.code }}
                />{' '}
              </FormProvider>
            </div>
            <div css={{ marginBottom: 8 }}>
              <label htmlFor="expiry" css={formLabel}>
                Expiry Date
              </label>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridColumnGap: 20,
                }}
              >
                <Controller
                  as={Select}
                  name="month"
                  control={control}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      background: 'rgba(58, 177, 202, 0.1)',
                      border: '1px solid transparent',
                      borderColor: errors.month && 'red !important',
                      padding: '6px',
                      boxShadow: 'none',
                      cursor: 'pointer',
                      '&:hover': {
                        border: '1px solid #002E6D',
                      },
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      cursor: 'pointer',
                    }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    valueContainer: (provided) => ({
                      ...provided,
                      fontSize: 14,
                      padding: '0px  8px',
                    }),
                    input: (provided) => ({
                      ...provided,
                      paddingBottom: 0,
                      paddingTop: 0,
                      margin: 0,
                    }),
                  }}
                  options={monthsList}
                  placeholder="Month"
                  defaultValue=""
                  isSearchable={false}
                  rules={{
                    validate: (value) => monthValidation(value, 'month'),
                  }}
                  isDisabled={watchNoExpiry || noCert}
                />
                <Controller
                  as={<input type="text" inputMode="numeric" />}
                  control={control}
                  name="year"
                  css={{
                    width: '100%',
                    padding: 13,
                    display: 'block',
                    border: '1px solid transparent',
                    borderRadius: '5px',
                    backgroundColor: 'rgba(58, 177, 202, 0.1)',
                    fontSize: 14,
                    outline: 'none',
                    borderColor: errors.year && 'red !important',

                    '&:focus': {
                      border: '1px solid #002E6D',
                    },
                  }}
                  maxLength={4}
                  placeholder="Year"
                  onChange={(e) => onYearChange(e.target.value)}
                  defaultValue=""
                  disabled={watchNoExpiry || noCert}
                  rules={{
                    validate: (value) => yearValidation(value, 'year'),
                  }}
                />
              </div>

              {expiredMessage() && (
                <FormFieldErrorMessage error={{ message: 'Expired' }} />
              )}
            </div>
            <div css={[customCheck(), { marginBottom: 24 }]}>
              <label htmlFor="noExpiry">
                <input
                  type="checkbox"
                  name="noExpiry"
                  id="noExpiry"
                  ref={register({
                    validate: (value) => noExpiryValidation(value),
                  })}
                />
                <div>
                  <span />
                </div>
                No Expiry
              </label>
            </div>
            <div css={{ marginBottom: 40, marginTop: 8 }}>
              <button
                type="button"
                onClick={handleSubmit(addToList)}
                css={addButton}
              >
                + Add this certificate/license
              </button>
            </div>
            {list && !noCert ? (
              <div css={{ marginBottom: 40 }}>
                {list.map((item, i) => (
                  <div key={i} css={blueBox}>
                    <p>
                      <strong css={{ textDecoration: 'underline' }}>
                        {item.label}
                      </strong>{' '}
                      (exp.{' '}
                      {item.month && item.year ? (
                        <span>
                          {item.month > 0
                            ? `${
                                monthsList.find(
                                  (month) => month.value === item.month
                                ).abbreviation
                              } `
                            : null}
                          {item.year})
                        </span>
                      ) : (
                        <span>N/A)</span>
                      )}
                    </p>
                    <button
                      type="button"
                      onClick={() => remove(i)}
                      css={{
                        border: 'none',
                        background: 'none',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        fontSize: 18,
                        padding: 0,
                        display: 'flex',
                      }}
                    >
                      <Close css={{ height: 16 }} />
                    </button>
                  </div>
                ))}
              </div>
            ) : null}
            <div
              css={{
                marginBottom: 24,
              }}
            >
              <label htmlFor="noCert" css={customCheck}>
                <input
                  type="checkbox"
                  name="noCert"
                  id="noCert"
                  ref={register}
                />
                <div>
                  <span />
                </div>
                I do not have any certificates/licenses to add
              </label>
            </div>
          </div>
          <div css={buttonContainer}>
            <button type="button" onClick={onClose} css={goBackButton}>
              Back
            </button>
            <button
              type="button"
              onClick={onSave}
              disabled={disableSave()}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg2,
                  theme.buttons.hover2
                )
              }
            >
              {saving ? 'Saving' : 'Save'}
            </button>
          </div>
        </div>
      </div>
      <div css={{ position: 'absolute', top: 30, right: 30 }}>
        <button type="button" css={editButton} onClick={onEdit}>
          Edit
        </button>
      </div>
      <div
        css={{
          gridTemplateColumns: '2fr 2fr',
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
          },
        }}
      >
        <div css={{ justifySelf: 'start' }}>
          <h2 css={settingTitle}>Certificates/Licenses</h2>
        </div>
        {certificates.length ? (
          <div>
            {certificates.map((item, i) => (
              <div
                key={i}
                css={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'end',
                  marginBottom: 8,
                  p: {
                    color: '#002E6D',
                    fontWeight: 500,
                  },
                  span: {
                    color: '#002E6D',
                    fontWeight: 500,
                  },
                }}
              >
                <p css={{ margin: 0 }}>{item.label}</p>
                <span css={{ marginLeft: 8, textAlign: 'right' }}>
                  ({item.year ? `${item.month}/${item.year}` : 'N/A'})
                </span>
              </div>
            ))}
          </div>
        ) : (
          <p
            css={{
              color: '#002E6D',
              fontWeight: 500,
            }}
          >
            None
          </p>
        )}
      </div>
    </div>
  );
};

export default withFirebase(EditCert);
