/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState, useContext, useRef } from 'react';
import moment from 'moment';
import { AuthUserContext } from 'library/Session';
import { ReactComponent as Arrow } from 'assets/icons/topbarArrow.svg';
import {
  button,
  buttonContainer,
  goBackButton,
  customCheck,
} from 'theme/styleBlocks';
import ParkingIndicator from 'components/ParkingIndicator';
import TransitIndicator from 'components/TransitIndicator';
import { bp, mq } from 'constants/mediaQueries';
import { useHistory } from 'react-router-dom';

const Preview = (props) => {
  const mapRef = useRef(null);
  const { authUser } = useContext(AuthUserContext);
  const [orderedDates, setOrderedDates] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [firstDate, setFirstDate] = useState({});
  const history = useHistory();
  useEffect(() => {
    if (props.step !== 6) history.push('/create-gig');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const datesOrdered = props.dates.sort(
      (a, b) => moment(a.day) - moment(b.day)
    );
    setFirstDate(datesOrdered[0]);
    setOrderedDates(datesOrdered.slice(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.scriptLoaded && mapRef.current && expanded) {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 49.231312, lng: -122.911052 },
        zoom: 10,
        disableDefaultUI: true,
      });

      const marker = new window.google.maps.Marker({
        map,
        anchorPoint: new window.google.maps.Point(0, -29),
      });

      if (props.location.locationID) {
        const latlng = new window.google.maps.LatLng(
          props.location.lat,
          props.location.lng
        );
        map.setCenter(latlng);
        map.setZoom(14);
        marker.setPosition(latlng);
        marker.setVisible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scriptLoaded, mapRef.current]);

  const onExpand = () => {
    setExpanded(!expanded);
  };
  const displayEditorData = (content) => {
    return { __html: props[content] };
  };
  const formattedDate = moment(firstDate.day).format('MMMM D, YYYY');
  const pay = (dateData) => {
    if (!dateData.paidBreak) {
      return (dateData.hours - dateData.breakTime / 60) * props.payPerHour;
    }
    return dateData.hours * props.payPerHour;
  };
  return (
    <div
      css={(theme) => ({
        padding: '20px 15px',
        margin: '0px auto 65px',
        [mq(bp.small)]: {
          width: '90vw',
        },
        [mq(bp.large)]: {
          maxWidth: theme.layout.max_width + 10,
          padding: '0px 20px 60px 60px',
          margin: 0,
          width: '100%',
        },
      })}
    >
      <h2>Post Preview</h2>
      <p>This is what the workers will see</p>
      <div css={{ marginBottom: 20 }}>
        <div>
          <h2 css={{ fontSize: 18, color: '#393939' }}>{formattedDate}</h2>
          <div>
            <div>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: '64px auto',
                  boxShadow: '1px 2px 9px rgba(0, 0, 0, 0.5)',
                  zIndex: '1',
                  borderRadius: 3,
                  overflow: 'hidden',
                  [mq(bp.large)]: {
                    minHeight: 150,
                    gridTemplateColumns: '160px auto',
                  },
                }}
              >
                <div
                  css={(theme) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    margin: '0 auto',
                    overflow: 'hidden',
                    position: 'relative',
                    background: '#fff',
                  })}
                >
                  <img
                    src={authUser.profileImage}
                    alt=""
                    css={{
                      position: 'absolute',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      borderRadius: 3,
                      height: 50,
                      [mq(bp.small)]: {
                        height: 120,
                      },
                    }}
                  />
                </div>
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    padding: 5,
                    minHeight: 75,
                    [mq(bp.large)]: {
                      padding: 16,
                    },
                    '&>div:not(:last-child)': {
                      [mq(bp.large)]: {
                        marginRight: 20,
                      },
                    },
                  }}
                  onClick={onExpand}
                >
                  <div
                    css={{
                      flex: 1,
                      position: 'relative',
                      [mq(bp.large)]: { dipsplay: 'block' },
                    }}
                  >
                    <h2
                      css={{
                        marginTop: 0,
                        marginBottom: 0,
                        fontSize: 20,
                        textOverflow: 'ellipsis',
                        lineHeight: 1.1,
                        fontFamily: 'Lato',
                        [mq(bp.large)]: {
                          width: '100%',
                          marginBottom: '20px',
                          fontSize: 24,
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      {props.position}
                    </h2>
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        [mq(bp.large)]: {
                          flexDirection: 'row',
                        },
                        p: {
                          margin: 0,
                          fontSize: 14,
                          color: '#000',
                          display: 'none',
                          [mq(bp.large)]: {
                            display: 'block',
                          },
                        },
                        h5: {
                          fontFamily: 'Lato',
                          [mq(bp.large)]: {
                            fontWeight: 600,
                          },
                        },
                        div: {
                          [mq(bp.large)]: {
                            marginRight: 40,
                          },
                        },
                      }}
                    >
                      <div>
                        <p>Company</p>
                        <h5
                          css={{
                            marginTop: 0,
                            marginBottom: 0,
                            fontSize: 14,
                            width: '50vw',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden !important',
                            textOverflow: 'ellipsis',
                            [mq(bp.large)]: {
                              maxWidth: '200px',
                              width: 'auto',
                              fontSize: 18,
                            },
                          }}
                        >
                          {authUser.businessName}
                        </h5>
                      </div>
                      <div>
                        <p>Time</p>
                        <h5
                          css={{
                            fontSize: 14,
                            margin: 0,
                            color: '#8A8A8A',
                            [mq(bp.large)]: {
                              color: '#272727',
                              fontSize: 18,
                            },
                          }}
                        >
                          {`${firstDate.start} - ${firstDate.end} ${
                            firstDate.nextDay ? '(Next Day)' : ''
                          }`}
                        </h5>
                        <span css={{ fontSize: 12 }}>
                          {firstDate.breakTime}min{' '}
                          {firstDate.paidBreak ? 'paid' : 'unpaid'} break
                        </span>
                      </div>
                      <div
                        css={{
                          display: 'none',
                          [mq(bp.large)]: {
                            display: 'block',
                          },
                        }}
                      >
                        <p>Pay</p>
                        <h5
                          css={{
                            fontSize: 16,
                            margin: 0,
                            [mq(bp.large)]: {
                              fontSize: 18,
                            },
                          }}
                        >{`${'$'}${pay(firstDate).toFixed(2)} (${'$'}${
                          props.payPerHour
                        }/hr)`}</h5>
                      </div>
                    </div>
                    <h5
                      css={{
                        position: 'absolute',
                        right: 0,
                        top: 7,
                        margin: '0',
                        fontSize: 20,
                        textAlign: 'center',
                        lineHeight: 0.8,
                        [mq(bp.large)]: {
                          display: 'none',
                        },
                      }}
                    >
                      {`${'$'}${pay(firstDate).toFixed(2)}`} <br />
                      <span css={{ color: '#8A8A8A', fontSize: 12 }}>{`(${'$'}${
                        props.payPerHour
                      }/hr)`}</span>
                    </h5>
                  </div>
                  <div css={{ marginRight: 20 }}>
                    <button
                      type="button"
                      css={(theme) => [
                        button(
                          theme.buttons.type_light,
                          theme.buttons.bg1,
                          theme.buttons.hover1
                        ),
                        {
                          width: '100%',
                          maxWidth: 200,
                          fontSize: 14,
                          display: 'none',
                          [mq(bp.large)]: {
                            display: 'block',
                          },
                        },
                      ]}
                      onClick={onExpand}
                    >
                      {expanded ? 'Hide' : 'View'} Details
                    </button>
                  </div>
                  <div
                    css={{
                      width: 24,
                      display: 'none',
                      [mq(bp.large)]: {
                        display: 'block',
                      },
                    }}
                    onClick={onExpand}
                  >
                    <Arrow
                      css={{
                        transform: expanded ? 'scale(1, -1)' : null,
                        transition: '.25s ease',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              css={{
                padding: '20px 20px 40px',
                boxShadow:
                  '1px 2px 9px rgba(0, 0, 0, 0.5), 0 9px 9px -9px rgba(0, 0, 0, 0.5) inset',
                display: expanded ? 'block' : 'none',
                background: '#fff',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  [mq(bp.large)]: {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                }}
              >
                <div
                  css={{
                    [mq(bp.large)]: { width: '55%' },
                    h2: {
                      fontSize: 20,
                      [mq(bp.large)]: {
                        fontSize: 24,
                      },
                    },
                  }}
                >
                  <div>
                    <h2>Description</h2>
                    <div
                      dangerouslySetInnerHTML={displayEditorData('description')}
                      css={{
                        width: '71vw',
                        margin: '0 auto',
                        overflowWrap: 'break-word',
                        [mq(bp.large)]: {
                          width: '100%',
                        },
                        a: {
                          color: 'cornflowerblue',
                        },
                        'ul,ol': {
                          paddingLeft: 24,
                          margin: 0,
                        },
                        p: { margin: 0 },
                      }}
                    />
                  </div>
                  <div
                    css={{
                      marginBottom: 15,
                      h3: {
                        fontSize: 16,
                        margin: '0 0 5px 0',
                      },
                    }}
                  >
                    <h2>Instructions</h2>
                    <div
                      css={{
                        marginBottom: 30,
                      }}
                    >
                      <h3>Attire</h3>
                      <div
                        dangerouslySetInnerHTML={displayEditorData('attire')}
                        css={{
                          width: '71vw',
                          margin: '0 auto',
                          overflowWrap: 'break-word',
                          [mq(bp.large)]: {
                            width: '100%',
                          },
                          a: {
                            color: 'cornflowerblue',
                          },
                          'ul,ol': {
                            paddingLeft: 24,
                            margin: 0,
                          },
                          p: { margin: 0 },
                        }}
                      />
                    </div>
                    {props.bring ? (
                      <div>
                        <h3>Things to Bring</h3>
                        <p>{props.bring}</p>
                      </div>
                    ) : null}
                    {props.additional ? (
                      <div>
                        <h3>Additional Information</h3>
                        <div
                          dangerouslySetInnerHTML={displayEditorData(
                            'additional'
                          )}
                          css={{
                            width: '71vw',
                            margin: '0 auto',
                            overflowWrap: 'break-word',
                            [mq(bp.large)]: {
                              width: '100%',
                            },
                            a: {
                              color: 'cornflowerblue',
                            },
                            'ul,ol': {
                              paddingLeft: 24,
                              margin: 0,
                            },
                            p: { margin: 0 },
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  {props.certs.length ? (
                    <div
                      css={{
                        marginBottom: 20,
                        [mq(bp.large)]: {
                          marginBottom: 0,
                        },
                      }}
                    >
                      <h2>Certifications & Licenses</h2>
                      {props.certs.map((cert, i) => (
                        <p key={i}>
                          - {cert.label}
                          {cert.required ? (
                            <span css={{ color: 'red' }}> (Required)</span>
                          ) : (
                            <span css={{ color: 'red' }}> (Preferred)</span>
                          )}
                        </p>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div css={{ [mq(bp.large)]: { width: 350 } }}>
                  <div
                    css={{
                      marginBottom: 20,
                      textAlign: 'left',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      id="map"
                      css={{
                        height: 300,
                        width: '100%',
                        maxWidth: 350,
                        margin: 0,
                      }}
                      ref={mapRef}
                    />
                    <div
                      css={{
                        marginBottom: 8,
                      }}
                    >
                      <p css={{ marginBottom: 0 }}>
                        {props.location.address2
                          ? `${props.location.address2} - ${props.location.address1}`
                          : `${props.location.address1}`}
                      </p>
                    </div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.google.com/maps/dir/${props.location.formattedAddress}/@${props.location.lat},${props.location.lng},15z`}
                      css={{ color: '#002E6D', fontWeight: 500 }}
                    >
                      Get Directions to here
                    </a>
                  </div>
                  <div
                    css={{
                      marginBottom: 40,
                    }}
                  >
                    {props.parking[0] && <ParkingIndicator num={1} />}
                    {props.parking[1] && <ParkingIndicator num={2} />}
                    {props.parking[2] && <ParkingIndicator num={3} />}
                    <TransitIndicator num={props.transit} />
                  </div>
                  {orderedDates.length > 0 ? (
                    <div>
                      {props.sameWorker ? (
                        <div>
                          <p>
                            This is a multi-day gig requiring work on all the
                            following days:
                          </p>
                        </div>
                      ) : null}
                      {!props.sameWorker && orderedDates.length > 0 ? (
                        <div>
                          <p>
                            This gig is available on multiple days which you may
                            apply to if you are available.
                          </p>
                        </div>
                      ) : null}
                      <div>
                        {props.sameWorker ? (
                          <React.Fragment>
                            <div css={customCheck}>
                              <label
                                css={{
                                  margin: 'auto',
                                  [mq(bp.large)]: {
                                    width: 325,
                                  },
                                  input: {
                                    marginRight: 8,
                                  },
                                  p: {
                                    marginTop: 0,
                                    marginBottom: 0,
                                  },
                                  'p:not(:last-child)': {
                                    marginRight: 10,
                                  },
                                }}
                              >
                                <div css={{ visibility: 'hidden' }}>
                                  <input type="checkbox" disabled />
                                  <span />
                                </div>
                                <p>{firstDate.day}</p>
                                <p>
                                  {firstDate.start} - {firstDate.end}{' '}
                                  {firstDate.nextDay ? '(Next Day)' : ''}
                                </p>
                                <p>${pay(firstDate).toFixed(2)}</p>
                              </label>
                            </div>
                            <hr />
                          </React.Fragment>
                        ) : (
                          <div css={customCheck}>
                            <label
                              css={{
                                margin: 'auto',
                                [mq(bp.large)]: {
                                  width: 325,
                                },
                                input: {
                                  marginRight: 8,
                                },
                                p: {
                                  marginTop: 0,
                                  marginBottom: 0,
                                },
                                'p:not(:last-child)': {
                                  marginRight: 10,
                                },
                              }}
                            >
                              <div>
                                <input type="checkbox" disabled />
                                <span />
                              </div>
                              <p>{firstDate.day}</p>
                              <p>
                                {firstDate.start} - {firstDate.end}{' '}
                                {firstDate.nextDay ? '(Next Day)' : ''}
                              </p>
                              <p>
                                $
                                {(
                                  Math.trunc(pay(firstDate) * 100) / 100
                                ).toFixed(2)}
                              </p>
                            </label>
                          </div>
                        )}
                        {orderedDates.map((date, i) => {
                          return props.sameWorker ? (
                            <div css={customCheck}>
                              <label
                                css={{
                                  margin: 'auto',
                                  [mq(bp.large)]: {
                                    width: 325,
                                  },
                                  input: {
                                    marginRight: 8,
                                  },
                                  p: {
                                    marginTop: 0,
                                    marginBottom: 0,
                                  },
                                  'p:not(:last-child)': {
                                    marginRight: 10,
                                  },
                                }}
                              >
                                <div>
                                  <input type="checkbox" disabled />
                                  <span />
                                </div>
                                <p>{date.day}</p>
                                <p>
                                  {date.start} - {date.end}{' '}
                                  {date.nextDay ? '(Next Day)' : ''}
                                </p>
                                <p>
                                  $
                                  {(Math.trunc(pay(date) * 100) / 100).toFixed(
                                    2
                                  )}
                                </p>
                              </label>
                            </div>
                          ) : (
                            <div css={customCheck}>
                              <label
                                css={{
                                  margin: 'auto',
                                  [mq(bp.large)]: {
                                    width: 325,
                                  },
                                  input: {
                                    marginRight: 8,
                                  },
                                  p: {
                                    marginTop: 0,
                                    marginBottom: 0,
                                  },
                                  'p:not(:last-child)': {
                                    marginRight: 10,
                                  },
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    name={`date${i}`}
                                    id={`date${i}`}
                                    disabled={props.sameWorker}
                                  />
                                  <span />
                                </div>
                                <p>{date.day}</p>
                                <p>
                                  {date.start} - {date.end}{' '}
                                  {date.nextDay ? '(Next Day)' : ''}
                                </p>
                                <p>
                                  $
                                  {(Math.trunc(pay(date) * 100) / 100).toFixed(
                                    2
                                  )}
                                </p>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div css={buttonContainer}>
        <button
          type="button"
          onClick={() => props.prevStep(5)}
          css={goBackButton}
        >
          Back
        </button>
        <button
          type="submit"
          onClick={() => props.nextStep(7)}
          css={(theme) =>
            button(
              theme.buttons.type_dark,
              theme.buttons.bg2,
              theme.buttons.hover2
            )
          }
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Preview;
