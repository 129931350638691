/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { withFirebase } from 'library/Firebase';
import moment from 'moment';
import {
  editButton,
  button,
  goBackButton,
  settingTitle,
  settingContent,
  buttonContainer,
} from 'theme/styleBlocks';
import { bp, mq } from 'constants/mediaQueries';

const EditSuspended = ({ suspended, suspendedUntil, firebase }) => {
  const [editSuspend, setEditSuspend] = useState(false);
  const [suspend, setSuspend] = useState(null);
  const [suspendedDate, setSuspendedDate] = useState(null);
  const { id, type } = useParams();
  const {
    // handleSubmit,
    // register,
    // getValues,
    // formState: { isSubmitted },
    // reset,
    // errors,
    // control,
    // setValue,
    // clearError,
    // setError,
  } = useForm({
    reValidateMode: 'onSubmit',
  });
  useEffect(() => {
    if (suspended) setSuspend(suspended);

    if (suspendedUntil)
      setSuspendedDate(
        moment.unix(suspendedUntil.seconds).format('YYYY-MM-DD')
      );
  }, [firebase, id, type]);

  const changeSuspend = (e) => {
    let suspendValue;
    if (e.target.value === 'true') suspendValue = true;
    if (e.target.value === 'false') suspendValue = false;
    setSuspend(suspendValue);
  };
  const cancelEditSuspend = () => {
    setSuspend(suspended);
    setEditSuspend(false);
  };
  const saveSuspend = () => {
    firebase.editSuspension(suspend, suspendedDate, type, id).then(() => {
      setEditSuspend(false);
    });
  };
  const disableSuspend = () => {
    if (!suspend) {
      return false;
    }
    if (suspendedDate) {
      if (suspend && moment(suspendedDate).toDate() > new Date()) {
        return false;
      }
    }
    return true;
  };

  return (
    <div
      css={{
        padding: 30,
        position: 'relative',
      }}
    >
      <div css={{ position: 'absolute', top: 30, right: 30 }}>
        <button
          type="button"
          css={editButton}
          onClick={() => setEditSuspend(true)}
        >
          Edit
        </button>
      </div>
      <div
        css={{
          gridTemplateColumns: '2fr 2fr',
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
          },
        }}
      >
        <div css={{ justifySelf: 'start' }}>
          <h2 css={settingTitle}>Suspended</h2>
        </div>
        {editSuspend ? (
          <div>
            <select
              name="suspend"
              id="suspend"
              onChange={changeSuspend}
              value={suspend || false}
            >
              <option value>Yes</option>
              <option value={false}>No</option>
            </select>
            {suspend ? (
              <input
                type="date"
                name="date"
                id="date"
                value={suspendedDate || ''}
                onChange={(e) => setSuspendedDate(e.target.value)}
                min={moment().add(1, 'day').format('YYYY-MM-DD')}
              />
            ) : null}
            <div css={buttonContainer}>
              <button
                type="button"
                onClick={cancelEditSuspend}
                css={goBackButton}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={saveSuspend}
                disabled={disableSuspend()}
                css={(theme) =>
                  button(
                    theme.buttons.type_dark,
                    theme.buttons.bg2,
                    theme.buttons.hover2
                  )
                }
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <div css={settingContent}>
            <p> {suspended ? 'Yes' : 'No'}</p>
            {suspended ? (
              <p>
                Suspended Until:{' '}
                {moment.unix(suspendedUntil.seconds).format('YYYY MMMM DD')}
              </p>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default withFirebase(EditSuspended);
