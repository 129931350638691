// /** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { withFirebase } from 'library/Firebase';

const GovIdCheck = ({ id, firebase, profileImage }) => {
  const [image, setImage] = useState('');
  useEffect(() => {
    firebase.getGovID({ id }).then((data) => setImage(data));
  }, []);
  return (
    <div>
      <img
        src={image}
        css={{
          height: 400,
          width: 400,
          border: '1px solid',
          objectFit: 'contain',
        }}
      />
      <img
        src={profileImage}
        css={{
          height: 400,
          width: 400,
          border: '1px solid',
          objectFit: 'contain',
        }}
      />
      <div>
        <button onClick={() => firebase.approveID(id)}>Approve Gov ID</button>
      </div>
    </div>
  );
};

export default withFirebase(GovIdCheck);
