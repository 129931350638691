/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { withFirebase } from 'library/Firebase';

const IdCheckCard = ({ userData, firebase }) => {
  const [expand, setExpand] = useState(false);
  const [govID, setGovID] = useState('');
  const check = () => {
    if (govID) return setExpand(true);
    firebase.getGovID({ id: userData.uid }).then((data) => {
      setGovID(data);
      setExpand(true);
    });
  };
  return (
    <div
      css={{
        marginBottom: 8,
        border: '1px solid',
        padding: 20,
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <p css={{ marginRight: 8 }}>
          {userData.firstName} {userData.lastName}
        </p>
        {expand ? (
          <button type="button" onClick={() => setExpand(false)}>
            close
          </button>
        ) : (
          <button type="button" onClick={check}>
            check
          </button>
        )}
      </div>
      {expand && (
        <div>
          <img
            src={userData.profileImage}
            alt=""
            css={{
              height: 400,
              width: 400,
              border: '1px solid',
              objectFit: 'contain',
            }}
          />
          <img
            src={govID}
            alt=""
            css={{
              height: 400,
              width: 400,
              border: '1px solid',
              objectFit: 'contain',
            }}
          />
          <div>
            <button
              type="button"
              onClick={() => firebase.approveID(userData.uid)}
            >
              Approve Gov ID
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withFirebase(IdCheckCard);
