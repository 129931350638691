/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { withFirebase } from 'library/Firebase';
import { bp, mq } from 'constants/mediaQueries';

const Mods = ({ firebase }) => {
  const [option, setOption] = useState('all');
  const [name, setName] = useState('');
  const [type, setType] = useState('string');
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const addParam = () => {
    setLoading(true);
    setMessage('');
    firebase.addParam(option, name, type, value).then((result) => {
      setLoading(false);
      setMessage(result.message);
      if (result.success) {
        setOption('all');
        setName('');
        setType('string');
        setValue('');
      }
    });
  };

  return (
    <div
      css={{
        margin: '0 auto',
        padding: '0 20px',
        marginTop: 50,
        [mq(bp.large)]: {
          padding: 0,
          marginTop: 90,
          maxWidth: 1020,
        },
      }}
    >
      <p css={{ color: 'red' }}>DONT TOUCH IF YOU DONT KNOW WHAT THIS IS :)</p>
      <div css={{ border: '1px solid red' }}>
        <select
          name="type"
          id="type"
          onChange={(e) => setOption(e.target.value)}
          value={option}
        >
          <option value="all">All</option>
          <option value="workers">Workers</option>
          <option value="businesses">Businesses</option>
          <option value="gigs">Gigs</option>
        </select>
        <div>
          <label htmlFor="param">Parameter Name</label>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div>
          <label htmlFor="type">Parameter Type</label>
          <select
            name="type"
            id="type"
            onChange={(e) => setType(e.target.value)}
            value={type}
          >
            <option value="string">String</option>
            <option value="number">Number</option>
            <option value="boolean">Boolean</option>
            <option value="array">Array</option>
          </select>
        </div>
        {type !== 'array' && (
          <div>
            <label htmlFor="value">Set Value of Paramete (optional)</label>
            <input
              type="text"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </div>
        )}
        <button type="button" onClick={addParam} disabled={loading || !name}>
          Submit
        </button>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default withFirebase(Mods);
