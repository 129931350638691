/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import useScript from 'hooks/useScript';
import { useParams } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { BodyScrollOptions } from 'body-scroll-lock';
import {
  editButton,
  formInput,
  button,
  goBackButton,
  buttonContainer,
  popupContainer,
  settingTitle,
} from 'theme/styleBlocks';
import { useForm, Controller } from 'react-hook-form';
import { withFirebase } from 'library/Firebase';
import { bp, mq } from 'constants/mediaQueries';
import { googleApi } from 'constants/config';
// import { Ring } from 'react-spinners-css';

const EditLocation = ({ location, preferences, firebase }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [markerCompleted, setMarkerCompleted] = useState(false);
  const [autoCompleted, setAutoCompleted] = useState(false);
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const radiusRef = useRef(null);
  const modalEl = useRef(null);
  const locationRef = useRef(null);
  const { id, type } = useParams();
  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${googleApi()}&libraries=places`
  );
  const [locationData, setLocationData] = useState({
    name: '',
    lat: '',
    lng: '',
    locationID: '',
    locality: '',
  });
  const {
    register,
    errors,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitted },
    watch,
    setValue,
  } = useForm();
  const range = watch('range') * 1 || 0;

  useEffect(() => {
    if (isSubmitted && !Object.keys(errors).length) reset();
  }, [isSubmitted, reset, errors]);
  useEffect(() => {
    setLocationData(location);
    const rangeValue = preferences.range ? preferences.range : '0';
    setValue('address', preferences.address);
    setValue('range', rangeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // console.log(1, preferences.range * 1, 2, range);
    if (preferences.range * 1 !== range) return setChanged(true);
    if (location !== locationData) return setChanged(true);
    setChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, locationData]);
  useEffect(() => {
    setScriptLoaded(loaded);
  }, [loaded]);

  useEffect(() => {
    let autoCompleteListener;
    let mapListener;
    if (scriptLoaded) {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 49.231312, lng: -122.911052 },
        zoom: 10,
        disableDefaultUI: true,
      });
      const autoComplete = new window.google.maps.places.Autocomplete(
        locationRef.current,
        { types: ['geocode'] }
      );
      autoComplete.bindTo('bounds', map);

      autoComplete.setFields([
        'formatted_address',
        'geometry',
        'name',
        'place_id',
        'address_components',
      ]);

      const marker = new window.google.maps.Marker({
        map,
        anchorPoint: new window.google.maps.Point(0, -29),
      });

      const circle = new window.google.maps.Circle({
        map,
        radius: range,
        fillColor: 'blue',
        fillOpacity: 0.25,
        strokeWeight: 1,
        strokeOpacity: 0.25,
        clickable: false,
      });
      circle.bindTo('center', marker, 'position');

      if (locationData.lat && locationData.lng) {
        const latlng = new window.google.maps.LatLng(
          locationData.lat,
          locationData.lng
        );
        map.setCenter(latlng);
        map.setZoom(10);
        marker.setMap(map);
        marker.setPosition(latlng);
        marker.setVisible(true);
        circle.setCenter(latlng);
        circle.setRadius(range);
        circle.setVisible(true);
        radiusRef.current = circle;
      }

      mapListener = window.google.maps.event.addListener(
        map,
        'click',
        (data) => {
          marker.setMap(map);
          marker.setVisible(false);
          circle.setVisible(false);
          radiusRef.current = null;
          marker.setPosition(data.latLng);
          marker.setVisible(true);
          circle.setCenter(data.latLng);
          circle.setRadius(range);
          circle.setVisible(true);
          radiusRef.current = circle;

          setValue('address', `${data.latLng.lat()}, ${data.latLng.lng()}`);
          setLocationData({
            lat: data.latLng.lat(),
            lng: data.latLng.lng(),
            name: `${data.latLng.lat()}, ${data.latLng.lng()}`,
          });
          setMarkerCompleted(true);
        }
      );
      autoCompleteListener = window.google.maps.event.addListener(
        autoComplete,
        'place_changed',
        () => {
          setAutoCompleted(true);
          marker.setVisible(false);
          circle.setVisible(false);
          radiusRef.current = null;
          const {
            formatted_address,
            name,
            geometry,
            place_id,
            // address_components,
          } = autoComplete.getPlace();
          if (!geometry) return;
          if (geometry.viewport) {
            map.setCenter(geometry.location);
            map.setZoom(10);
          }
          marker.setPosition(geometry.location);
          marker.setVisible(true);
          circle.setCenter(geometry.location);
          circle.setRadius(range);
          circle.setVisible(true);
          setChanged(true);
          radiusRef.current = circle;

          const lat = geometry.location.lat();
          const lng = geometry.location.lng();

          // const locality = address_components.filter(
          //   (comps) => comps.types[0] === 'locality'
          // );
          onAutocomplete(
            {
              name,
              lat,
              lng,
              locationID: place_id,
              // locality: locality[0].long_name,
            },
            formatted_address
          );
        }
      );
      return () => {
        mapListener.remove();
        autoCompleteListener.remove();
        autoComplete.unbindAll();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded]);
  useEffect(() => {
    if (radiusRef.current) {
      radiusRef.current.setRadius(range);
    }

    setAutoCompleted(false);
    setMarkerCompleted(false);
  }, [range, autoCompleted, markerCompleted]);

  const onAutocomplete = (location, address) => {
    setValue('address', address);
    setLocationData((prev) => ({ ...prev, ...location }));
  };

  const onEdit = () => {
    setEdit(true);
    const options: BodyScrollOptions = {
      reserveScrollBarGap: true,
    };
    disableBodyScroll(modalEl.current, options);
  };

  const onClose = () => {
    setEdit(false);
    enableBodyScroll(modalEl.current);
  };

  const rangeDisplay = () => {
    if (range === 0) {
      return 'No Preference';
    }
    return `${range >= 1000 ? (range / 1000).toFixed(2) : range} ${
      range >= 1000 ? 'km' : 'm'
    }`;
  };
  const onSave = (data) => {
    setSaving(true);
    const preferences = {
      address: data.address,
      range: data.range,
    };

    firebase.saveLocation(preferences, locationData, id, type).then(() => {
      setSaving(false);
      setEdit(false);
      enableBodyScroll(modalEl.current);
    });
  };
  return (
    <div
      css={{
        backgroundColor: edit && '#F8F8F8',
        padding: 30,
        position: 'relative',
      }}
    >
      <div
        ref={modalEl}
        css={{
          display: edit ? 'flex' : 'none',
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          background: 'rgba(0,0,0,0.5)',
          top: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99,
          overflowY: 'initial!important',
        }}
      >
        <div css={popupContainer}>
          <div css={{ marginBottom: 30 }}>
            <div css={formInput}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 30,
                }}
              >
                <p css={{ margin: 0, fontWeight: 700, fontSize: 24 }}>
                  Location Preferences
                </p>
                <button
                  type="button"
                  onClick={onClose}
                  css={{
                    border: 'none',
                    lineHeight: 1,
                    fontWeight: 500,
                  }}
                >
                  <Close
                    css={{ width: 15, height: 15, path: { fill: '#393939' } }}
                  />
                </button>
              </div>
              <label htmlFor="address">Set Home Address</label>
              <Controller
                as={<input ref={locationRef} />}
                control={control}
                type="text"
                name="address"
                id="location"
                defaultValue=""
              />
            </div>
            <div css={{ marginBottom: 20 }}>
              <div
                id="map"
                css={{
                  height: '35vh',
                  [mq(bp.large)]: {
                    height: '400px',
                  },
                }}
              />
            </div>
            <div>
              <label
                htmlFor="radius"
                css={{
                  display: 'block',
                  marginBottom: 20,
                  fontWeight: '500',
                  fontSize: 14,
                  [mq(bp.small)]: {
                    fontSize: 20,
                  },
                }}
              >
                Set Radius ({rangeDisplay()})
              </label>
              <div css={{ width: '100%' }}>
                <input
                  type="range"
                  name="range"
                  min="0"
                  max="50000"
                  step="250"
                  disabled={!locationData.lat}
                  ref={register}
                  css={{
                    WebkitAppearance: 'none',
                    width: '100%',
                    height: 5,
                    background: '#979797',
                    outline: 'none',
                    opacity: '0.7',
                    transition: 'opacity .2s',
                    margin: '16px 0',
                    borderRadius: '10px',
                    '&:hover': {
                      opacity: '1',
                    },
                    '&::-webkit-slider-thumb': {
                      appearance: 'none',
                      width: 24,
                      height: 24,
                      borderRadius: '50%',
                      background: '#002E6D',
                      cursor: 'pointer',
                    },
                    '&::-moz-range-thumb': {
                      width: 24,
                      height: 24,
                      borderRadius: '50%',
                      background: '#002E6D',
                      cursor: 'pointer',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div css={buttonContainer}>
            <button type="button" onClick={onClose} css={goBackButton}>
              Back
            </button>
            <button
              type="button"
              onClick={handleSubmit(onSave)}
              disabled={!changed}
              css={(theme) =>
                button(
                  theme.buttons.type_dark,
                  theme.buttons.bg,
                  theme.buttons.hover
                )
              }
            >
              {saving
                ? 'Saving'
                : // <Ring
                  //   color="#002E6D"
                  //   size="22"
                  //   css={{
                  //     width: 22,
                  //     height: 22,
                  //     display: 'flex',
                  //     margin: 'auto',
                  //   }}
                  // />
                  'Save'}
            </button>
          </div>
        </div>
      </div>
      <div css={{ position: 'absolute', top: 30, right: 30 }}>
        <button type="button" css={editButton} onClick={onEdit}>
          Edit
        </button>
      </div>
      <div
        css={{
          gridTemplateColumns: '2fr 2fr',
          [mq(bp.large)]: {
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
          },
        }}
      >
        <div css={{ justifySelf: 'start' }}>
          <h2 css={settingTitle}>Location Preference</h2>
        </div>
        {location.lat && location.lng ? (
          <div id="map" css={{ height: 300, marginRight: 80 }} />
        ) : (
          <p
            css={{
              color: '#002E6D',
              fontWeight: 500,
            }}
          >
            Not Set
          </p>
        )}
      </div>
    </div>
  );
};

export default withFirebase(EditLocation);
